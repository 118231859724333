import React from "react";
import { useWeb3React } from "@web3-react/core";
import { Contract, ethers, Signer } from "ethers";
import { MouseEvent, ReactElement, useEffect, useState } from "react";
import WhitelistArtifact from "../../../artifacts/contracts/Whitelist.sol/Whitelist.json";
import { Provider } from "../utils/provider";
import axios from "../../../api/config";
import { Button } from "@material-ui/core";

export const WHITELIST = "whitelist";

export default function Whitelister(props: any): ReactElement {
  const context = useWeb3React<Provider>();
  const { library, active } = context;
  const [signer, setSigner] = useState<Signer>();
  // const { whiteListContractAddress } = props;
  const { whiteListContractAddress } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [whiteListed, setWhiteListed] = useState(false);

  useEffect((): void => {
    if (!library) {
      setSigner(undefined);
      return;
    }

    setSigner(library.getSigner());
  }, [library]);

  function isWhiteListed(address: any) {
    async function whiteListed(): Promise<void> {
      try {
        const Whitelist = new ethers.ContractFactory(
          WhitelistArtifact.abi,
          WhitelistArtifact.bytecode,
          signer
        );
        if (!whiteListContractAddress) {
          return;
        }
        const contract = Whitelist.attach(whiteListContractAddress);
        const res = await contract.isWhitelisted(address);
        if (res) {
          alert("User is whitelisted " + address);
        }
        setWhiteListed(res);
      } catch (error: any) {
        console.error(error.message);
      }
    }
    whiteListed();
  }

  function handleClick() {
    isWhiteListed("0x9E5fC609e7f624F1B90fDf53f8968Ee575f5A2f3");
    isWhiteListed(props.tanganyAddress);
  }

  function handleWhitelistUser(event: MouseEvent<HTMLButtonElement>) {
    if (!props.tanganyAddress) {
      alert("No tangany address available");
      return false;
    }
    event.preventDefault();
    async function whitelistUser(): Promise<void> {
      try {
        setIsLoading(true);
        const Whitelist = new ethers.ContractFactory(
          WhitelistArtifact.abi,
          WhitelistArtifact.bytecode,
          signer
        );
        if (!whiteListContractAddress) {
          return;
        }
        const contract = Whitelist.attach(whiteListContractAddress);
        const res = await contract.addToWhitelist(props.tanganyAddress);
        if (res) {
          // const { data: seller } = await axios.post(
          //   `/seller/api/setStatusWhitelisted?id=${props.sellerId}`
          // );
          // if (seller) {
          alert("User has been whitelisted successfully");
          //   if (props.onWhiteList) {
          //     props.onWhiteList();
          //   }
          // }
        }
      } catch (error: any) {
        window.alert(
          "Error!" +
            (error && error.data
              ? `\n\n${error.data.message}`
              : `\n\n${error.message}`)
        );
      } finally {
        setIsLoading(false);
      }
    }
    whitelistUser();
  }

  if (!whiteListContractAddress || !active || props.status !== "ONBOARDED") {
    return <></>;
  }

  return (
    <Button
      disabled={isLoading}
      color="primary"
      variant="outlined"
      onClick={handleWhitelistUser}
    >
      {isLoading ? "Please wait..." : "Whitelist"}
    </Button>
  );
}
