import {
  GET_ALL_PROJECT_ATTRIBUTES,
  PROJECT_ATTRIBUTE_TEXT_CHANGE_HANDLER,
  GET_ALL_DATA_TYPES, PROJECT_ATTRIBUTE_SELECT_CHANGE_HANDLER
} from "./actions";

export const projectAttributeReducer = (state = [], action) => {
  switch(action.type) {
    case GET_ALL_DATA_TYPES:
      return {
        ...state,
        data: {
          ...state.data,
          dataTypes: action.payload
        }
      };
    case GET_ALL_PROJECT_ATTRIBUTES:
        return {
          ...state,
          data: {
            ...state.data,
            projectAttributes: action.payload
          }
        };
    case PROJECT_ATTRIBUTE_TEXT_CHANGE_HANDLER:
      return {
        ...state,
        forms: {
          ...state.forms,
          projectAttributeForm: {
            ...state.forms.projectAttributeForm,
            fields: {
              ...state.forms.projectAttributeForm.fields,
              [action.key]: {
                ...state.forms.projectAttributeForm.fields[action.key],
                value:action.payload
              }
            }
          }
        }
      };
    case PROJECT_ATTRIBUTE_SELECT_CHANGE_HANDLER: {
      return {
        ...state,
        forms: {
          ...state.forms,
          projectAttributeForm: {
            ...state.forms.projectAttributeForm,
            fields: {
              ...state.forms.projectAttributeForm.fields,
              [action.key]: {
                ...state.forms.projectAttributeForm.fields[action.key],
                value: (action.payload === null) ? "" : [action.payload]
              }
            }
          }
        }
      }
    }
    default:
      return state;
  }
};

export default projectAttributeReducer;
