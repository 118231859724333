import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";
import { ValidatorForm } from "react-material-ui-form-validator";
import ErrorMessage from "../../common/ErrorMessage/component";
class CreateBuyer extends Component {

    componentDidMount = () => {
        this.props.checkIfUrlIsAllowed();
    }

    SubmitHandler = () => {
        this.props.submitSellerProfile();
    }
    render() {

        const {
            classes,
            textFieldChangeHandler,
            fields,
            addSellerResponseMsg,
            sellerAddStatus,
            isAllowed
        } = this.props;
        return (
                <div className={classes.mainrapper}>
                <div className={classes.fieldswrapper}>
                    <p>Add a New Buyer</p>

                    <ValidatorForm
                        onSubmit={this.SubmitHandler}
                        autoComplete="off"
                    >
                       <Grid container>
                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.SellerUsernameCreate.label}</span>
                                <CustomTextValidator
                                    id={fields.SellerUsernameCreate.name}
                                    fullWidth={true}
                                    validations={true}
                                    type="text"
                                    placeholder={fields.SellerUsernameCreate.placeholder}
                                    name={fields.SellerUsernameCreate.name}
                                    value={fields.SellerUsernameCreate.value}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            fields.SellerUsernameCreate.name,
                                            value
                                        );
                                    }}
                                    validators={["required"]}
                                    errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.SellerEmailCreate.label}</span>
                                <CustomTextValidator
                                    id={fields.SellerEmailCreate.name}
                                    fullWidth={true}
                                    validations={true}
                                    type="email"
                                    placeholder={fields.SellerEmailCreate.placeholder}
                                    name={fields.SellerEmailCreate.name}
                                    value={fields.SellerEmailCreate.value}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            fields.SellerEmailCreate.name,
                                            value
                                        );
                                    }}
                                    validators={["required"]}
                                    errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{fields.SellerPasswordCreate.label}</span>
                                   <CustomTextValidator
                                       id={fields.SellerPasswordCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="password"
                                       placeholder={fields.SellerPasswordCreate.placeholder}
                                       name={fields.SellerPasswordCreate.name}
                                       value={fields.SellerPasswordCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               fields.SellerPasswordCreate.name,
                                               value
                                           );
                                       }}
                                       validators={["required"]}
                                       errorMessages={["Required"]}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{fields.SellerCompanyCreate.label}</span>
                                   <CustomTextValidator
                                       id={fields.SellerCompanyCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={fields.SellerCompanyCreate.placeholder}
                                       name={fields.SellerCompanyCreate.name}
                                       value={fields.SellerCompanyCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               fields.SellerCompanyCreate.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{fields.SellerCountryCreate.label}</span>
                                   <CustomTextValidator
                                       id={fields.SellerCountryCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={fields.SellerCountryCreate.placeholder}
                                       name={fields.SellerCountryCreate.name}
                                       value={fields.SellerCountryCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               fields.SellerCountryCreate.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{fields.SellerCityCreate.label}</span>
                                   <CustomTextValidator
                                       id={fields.SellerCityCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={fields.SellerCityCreate.placeholder}
                                       name={fields.SellerCityCreate.name}
                                       value={fields.SellerCityCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               fields.SellerCityCreate.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{fields.SellerAddress1Create.label}</span>
                                   <CustomTextValidator
                                       id={fields.SellerAddress1Create.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={fields.SellerAddress1Create.placeholder}
                                       name={fields.SellerAddress1Create.name}
                                       value={fields.SellerAddress1Create.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               fields.SellerAddress1Create.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{fields.SellerAddress2Create.label}</span>
                                   <CustomTextValidator
                                       id={fields.SellerAddress2Create.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={fields.SellerAddress2Create.placeholder}
                                       name={fields.SellerAddress2Create.name}
                                       value={fields.SellerAddress2Create.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               fields.SellerAddress2Create.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{fields.SellerAddress2Create.label}</span>
                                   <CustomTextValidator
                                       id={fields.SellerPhoneCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={fields.SellerPhoneCreate.placeholder}
                                       name={fields.SellerPhoneCreate.name}
                                       value={fields.SellerPhoneCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               fields.SellerPhoneCreate.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{fields.SellerPhoneCreate.label}</span>
                                   <CustomTextValidator
                                       id={fields.SellerPhoneCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={fields.SellerPhoneCreate.placeholder}
                                       name={fields.SellerPhoneCreate.name}
                                       value={fields.SellerPhoneCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               fields.SellerPhoneCreate.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{fields.SellerPinCodeCreate.label}</span>
                                   <CustomTextValidator
                                       id={fields.SellerPinCodeCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="number"
                                       placeholder={fields.SellerPinCodeCreate.placeholder}
                                       name={fields.SellerPinCodeCreate.name}
                                       value={fields.SellerPinCodeCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               fields.SellerPinCodeCreate.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{fields.SellerVatNoCreate.label}</span>
                                   <CustomTextValidator
                                       id={fields.SellerVatNoCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={fields.SellerVatNoCreate.placeholder}
                                       name={fields.SellerVatNoCreate.name}
                                       value={fields.SellerVatNoCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               fields.SellerVatNoCreate.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                            <Grid item xs={12}>
                                {addSellerResponseMsg?
                                <Button variant="contained" color="primary" className="submitbtn" type="submit"  disabled>Add</Button>
                                :
                                <Button variant="contained" color="primary" className="submitbtn" type="submit"  >Add</Button>
                                 }

                            </Grid>
                        </Grid>
                        {addSellerResponseMsg ? (
                            <p className={classes.emailsentseller}>{sellerAddStatus}</p>
                        )
                        :
                        null
                         }
                    </ValidatorForm>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(CreateBuyer);
