export const ACCOUNT_USER = {
    data: {
        allAccountTypeProfiles: []
    },
    editData: {
        editDataId: '',
        editDataFirstName: '',
        editDataLastName: '',
        editDataEmail: '',
        editDataStatus: ''
    },
    forms: {
        AccountUserForm: {
            fields: {
                FirstName: {
                    label: "First Name",
                    name: "FirstName",
                    placeholder: "First Name",
                    value: ""
                },
                LastName: {
                    label: "Last Name",
                    name: "LastName",
                    placeholder: "Last Name",
                    value: ""
                },
                Email: {
                    label: "Email",
                    name: "Email",
                    placeholder: "Email",
                    value: ""
                },
                AccountName: {
                    label: "Account User Name",
                    name: "AccountName",
                    placeholder: "Account User Name",
                    value: ""
                },
                Password: {
                    label: "Password",
                    name: "Password",
                    placeholder: "Password",
                    value: ""
                },
                ConfirmPassword: {
                    label: "ConfirmP assword",
                    name: "ConfirmPassword",
                    placeholder: "Confirm Password",
                    value: ""
                },
                AccountTypeid: {
                    label: "Account Type",
                    name: "AccountTypeid",
                    placeholder: "Account Type",
                    value: [],
                    options: []
                },
                Language: {
                    label: "Language",
                    name: "Language",
                    placeholder: "Language",
                    value: [],
                    options: [
                        {value: 0, label: "English"},
                        {value: 1, label: "German"},
                        {value: 2, label: "French"}
                    ]
                },
                Status: {
                    label: "Status (Is Active)",
                    name: "Status",
                    placeholder: "Status (Is Active)",
                    value: [],
                    options: [
                        {value: 1, label: "true"},
                        {value: 0, label: "false"}
                    ]
                },
                RedirectUrl: {
                    label: "Redirect URL",
                    name: "RedirectUrl",
                    placeholder: "Redirect URL",
                    value: ""
                }
            }
        },
        biddingIncrementForm: {
            fields: {
                ProfileId: {
                    label: "Bidding Profile Id",
                    name: "ProfileId",
                    placeholder: "Bidding Profile Id",
                    value: [],
                    options: []
                },
                EndBiddingPrice: {
                    label: "End Bidding Price",
                    name: "EndBiddingPrice",
                    placeholder: "End Bidding Price",
                    value: ""
                },
                FirstIncrement: {
                    label: "First Bidding Increment",
                    name: "FirstIncrement",
                    placeholder: "First Bidding Increment",
                    value: ""
                },
                SecondIncrement: {
                    label: "Second Bidding Increment",
                    name: "SecondIncrement",
                    placeholder: "Second Bidding Increment",
                    value: ""
                },
                StartBiddingPrice: {
                    label: "Start Bidding Price",
                    name: "StartBiddingPrice",
                    placeholder: "Start Bidding Price",
                    value: ""
                },
            }
        },
    },
    UISettings: {
        userAccountError: ""
    }
}
export default ACCOUNT_USER;