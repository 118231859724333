import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";
import { ValidatorForm } from "react-material-ui-form-validator";
import ReactSelectValidator from "../../../../common/ReduxBased/CustomReactSelectValidator/ReactSelectValidator";

class EditAccount extends Component {
      state={
          editFirstName:'',
          editLastName:'',
          editEmail:'',
          editStatus:''
      }

    SubmitHandler = () => {
        const {editFields } = this.props;
        const { editFirstName,editLastName,editEmail, editStatus} = this.state;
        let data ={
            firstName :editFirstName,
            lastName :editLastName,
            email :editEmail,
            isActive :editStatus
        }

        this.props.UpdateAccountTypeProfile(editFields.editDataId, data);
    }

    componentDidMount = () => {
        const { editFields } = this.props;
        this.setState({
            editFirstName: editFields.editDataFirstName ? editFields.editDataFirstName : '',
            editLastName: editFields.editDataLastName ? editFields.editDataLastName : '',
            editEmail: editFields.editDataEmail ? editFields.editDataEmail : '',
            editStatus: editFields.editDataStatus ? editFields.editDataStatus : '',
        })
    }

    textChangeHandler = (name, value) => {
        this.setState({
            [name]: value ? value.value : ""
        })
    }

    textChangeHandlerStatus = (name, value) => {
        this.setState({
            [name]: value ? value.label : ""
        })
    }

    render() {
        const {
            classes,
            fields,
            selectFieldChangeHandler
        } = this.props;

        const {
            editFirstName,
            editLastName,
            editEmail,
            editStatus
        } = this.state;
        
        return (
            <>
                <div className={classes.editmainrapper}>
                <div className={classes.editfieldswrapper}>
                    <p>Edit User Account</p>

                    <ValidatorForm
                        onSubmit={this.SubmitHandler}
                        autoComplete="off"
                    >
                       <Grid container>
                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>First Name</span>
                                <CustomTextValidator
                                    id={editFirstName}
                                    validations={true}
                                    fullWidth={true}
                                    type="text"
                                    name={editFirstName}
                                    value={editFirstName}
                                    onChange={value => {
                                        this.textChangeHandler('editFirstName', value)
                                    }}
                                    // validators={["required"]}
                                    // errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>Last Name</span>
                                <CustomTextValidator
                                    id={editLastName}
                                    validations={true}
                                    fullWidth={true}
                                    type="text"
                                    name={editLastName}
                                    value={editLastName}
                                    onChange={value => {
                                        this.textChangeHandler('editLastName', value)
                                    }}
                                    // validators={["required"]}
                                    // errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>Email</span>
                                <CustomTextValidator
                                    id={editEmail}
                                    validations={true}
                                    fullWidth={true}
                                    type="text"
                                    name={editEmail}
                                    value={editEmail}
                                    onChange={value => {
                                        this.textChangeHandler('editEmail', value)
                                    }}
                                    // validators={["required"]}
                                    // errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>Status</span>
                                <ReactSelectValidator
                                    id={editStatus}
                                    name={editStatus}
                                    validations={true}
                                    placeholder={editStatus}
                                    fullWidth={true}
                                    onChange={value => {
                                        selectFieldChangeHandler(
                                            this.textChangeHandlerStatus('editStatus', value)
                                        );
                                    }}
                                    // validators={["required"]}
                                    // errorMessages={["Required"]}
                                    options={fields.Status.options}
                                    className="selectfield"
                                />
                                </div>
                            </Grid>
                          
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" className="submitbtn" type="submit">Update</Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </div>
            </div>
            </>
        )
    }
}

export default withStyles(styles)(EditAccount);
