import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";

class ViewAllPermissions extends Component {

    state = {
        show: false,
        showDelete: false,
        currentId: "",
        anchorEl: null
    }

    componentDidMount = () => {
        this.props.fetchDataIfNeeded();
    }
    render() {

        const {
            classes,
            allRoles
        } = this.props;
        
        const columns = ["ID", "Action Name", "Action URL", "App Name", "ACL / Permission"];
        return (
            <>

                <div className={classes.mainrapper}>
                    <div className={classes.fieldswrapper}>
                        <p>View all Permissions</p>
                        <MUIDataTable 
                            title={"Roles"} 
                            data={allRoles}
                            columns={columns} 
                        />
                    </div>
                </div>

            </>

            
        )
    }


    
}
export default withStyles(styles)(ViewAllPermissions);