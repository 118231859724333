import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import CustomTextValidator from "../../../../../common/ReduxBased/CustomTextValidator/component";
import { ValidatorForm } from "react-material-ui-form-validator";
import ErrorMessage from "../../../common/ErrorMessage/component";
import ls from 'local-storage';
import ReactSelectValidator from "../../../../../common/ReduxBased/CustomReactSelectValidator/ReactSelectValidator";

class CreateAttribute extends Component {

    componentDidMount = () => {
        this.props.checkIfUrlIsAllowed();
        this.props.getAccountTypeIDs();
    }

    SubmitHandler = () => {
        console.log()
        this.props.submitAttribute();
    }
    render() {
        const {
            classes,
            textFieldChangeHandler,
            selectFieldChangeHandler,
            fields,
            isAllowed
        } = this.props;

        return (
            <>
                <div className={classes.mainrapper}>
                <div className={classes.fieldswrapper}>
                    <p>Create a New Account Type</p>
                    <ValidatorForm
                        onSubmit={this.SubmitHandler}
                        autoComplete="off"
                    >
                       <Grid container>

                       <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.name.label}</span>
                                <CustomTextValidator
                                    id={fields.name.name}
                                    name={fields.name.name}
                                    value={fields.name.value}
                                    placeholder={fields.name.placeholder}
                                    validations={true}
                                    fullWidth={true}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            fields.name.name,
                                            value
                                        );
                                    }}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.type.label}</span>
                                <CustomTextValidator
                                    id={fields.type.name}
                                    fullWidth={true}
                                    validations={true}
                                    type="text"
                                    placeholder={fields.type.placeholder}
                                    name={fields.type.name}
                                    value={fields.type.value}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            fields.type.name,
                                            value
                                        );
                                    }}
                                    // validators={["required"]}
                                    // errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>
                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.required.label}</span>
                                   <ReactSelectValidator
                                       id={fields.required.name}
                                       name={fields.required.name}
                                       value={fields.required.value}
                                       placeholder={fields.required.placeholder}
                                       validations={true}
                                       fullWidth={true}
                                       onChange={value => {
                                           selectFieldChangeHandler(
                                               fields.required.name,
                                               value
                                           );
                                       }}
                                       options={fields.required.options}
                                       className="selectfield"
                                   />
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" className="submitbtn" type="submit">Add</Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </div>
            </div>
            </>
        )
    }
}

export default withStyles(styles)(CreateAttribute);
