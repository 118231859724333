import React, { Component } from 'react';
import {BrowserRouter as Router, Switch} from "react-router-dom";
import Login  from "./Login/container";
import ConfirmPending from "./common/ConfirmPending/component";
import UserDashboard from "./OmniConnect/Dashboard/container";

import { styles } from "./style";
import "./style.css";
import { MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { Theme } from "./../resources/theme";
import CssBaseline from "@material-ui/core/CssBaseline";

import PrivateRoute from "./common/PrivateRouteContainer";
import PublicRoute from "./common/PublicRouteContainer";
import LoadingSpinner from "./common/ReduxBased/LoadingSpinner/container";
import ViewSeller from "./OmniConnect/Dashboard/SellerManagement/ViewSeller/container"
import CreateSeller from "./OmniConnect/Dashboard/SellerManagement/CreateSeller/container"
import CreateBuyer from "./OmniConnect/Dashboard/BuyerManagement/CreateBuyer/container";


class App extends Component {

  state = {
    isLogin: true,
    selectedTheme: Theme,
  }
  render() {

    return (
      <>
          <Router>
            <MuiThemeProvider theme={Theme}>
              <MuiThemeProvider theme={this.state.selectedTheme}>
                <CssBaseline />

                <Switch>
                    <PublicRoute exact path={`/`} component={Login}/>
                    <PublicRoute path={`/login`} component={Login}/>
                    <PublicRoute path={`/user/view-seller`} component={ViewSeller} />
                    <PrivateRoute path={`/user`} component={UserDashboard}/>
                    <PublicRoute path={`/register/seller`} component={CreateSeller} />
                    <PublicRoute path={`/register/buyer`} component={CreateBuyer} />
                    <PublicRoute path={`/confirmation_pending`} component={ConfirmPending}/>
                </Switch>
                <LoadingSpinner />
                </MuiThemeProvider>
              </MuiThemeProvider>
          </Router>
      </>
    );
  }
}

export default withStyles(styles)(App);
