import { connect } from "react-redux";
import ViewAllRoles from "./component";
import instance, {BASE_URL} from "../../../../../../api/config";
import {
    saveRolesData
} from "../../actions";

import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../../common/ReduxBased/LoadingSpinner/actions";

const mapStateToProps = state => ({
    allRoles: state.PERMISSIONS.data.allRoles,
})

const mapDispatchToProps = (dispatch, props) => ({
    fetchDataIfNeeded: () => {
        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Loading Roles", "small"));

        instance.get(BASE_URL+'/user/core-account-types/getAccountTypes')
        .then(res => {
            dispatch(saveRolesData(res.data.data))
            dispatch(handleLoadingSpinnerClose());
        })
        .catch(error => {
            dispatch(handleLoadingSpinnerClose());
        })
    },
})


const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...ownProps,
    ...dispatchProps
})
export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ViewAllRoles);
