import React, { useState, useEffect } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import instance, {
  BASE_URL_PROJECT,
  BASE_URL_USER,
  BASE_URL_SELLER,
} from "../../../../../../api/config";
import { Button, Badge } from "@material-ui/core";
import CarbonArtifact from "../../../../../../artifacts/contracts/CarbonContract.sol/CarbonContract.json";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";

const VintageDetails = (props) => {
  const context = useWeb3React();
  const { library, active } = context;
  const [initialized, setInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    allDetails: [],
    show: false,
    showDelete: false,
    currentId: "",
    anchorEl: null,
    editData: "",
    projectData: null,
    mappedData: [],
    seller: null,
    user: null,
    canMint: false,
  });
  const [selectedIds, setSelectedIds] = useState([]);
  const [signer, setSigner] = useState(null);
  const { carbonContractAddress } = props;

  useEffect(() => {
    if (!library) {
      setSigner(undefined);
      return;
    }
    setSigner(library.getSigner());
  }, [library]);

  const mintVintageInState = (vintageId, hash) => {
    setState((prev) => {
      const updatedMappedData = [...prev.mappedData];
      const index = updatedMappedData.findIndex((f) => f[0] === vintageId);
      updatedMappedData[index][7] = hash;
      return { ...prev, mappedData: [...updatedMappedData] };
    });
  };

  useEffect(() => {
    instance
      .get(
        BASE_URL_PROJECT +
          `/project/onboarding/findProjectById?projectId=${props.projectId}`
      )
      .then((res) => {
        setState({
          projectData: res.data,
        });
      })
      .catch((err) => {});
  }, []);
  useEffect(() => {
    const fetchSellerUser = async (sellerId) => {
      try {
        const { data: res } = await instance.get(
          BASE_URL_SELLER + `/api/sellerById?id=${sellerId}`
        );
        if (res) {
          setState((prev) => ({ ...prev, seller: res }));
        }
      } catch (ex) {
        console.error(ex.message);
      }
    };
    const fetchSeller = async (sellerId) => {
      try {
        const { data: res } = await instance.get(
          BASE_URL_USER + `/user-management/getUserById`,
          {
            headers: {
              id: sellerId,
            },
          }
        );
        if (res) {
          setState((prev) => ({ ...prev, seller: res }));
          fetchSellerUser(res.buyerSellerInvestorId);
        }
      } catch (ex) {
        console.error(ex.message);
      }
    };
    if (state.projectData) {
      fetchSeller(state.projectData.sellerId);
    }
  }, [state.projectData]);

  useEffect(() => {
    if (state.projectData && state.seller) {
      const projectMappedData = [];
      state.projectData.vintageFiles.forEach((item, index) => {
        projectMappedData.push([
          item.vintageId,
          state.seller.surName,
          item.vintage,
          state.seller.tanganyAddress,
          state.projectData.creditSaleDetail[index].quantity,
          state.projectData.creditSaleDetail[index].pricePerCredit,
          item.ipfsFileHash,
          state.projectData.creditSaleDetail[index].transactionTxId,
        ]);
      });
      setState((prev) => ({ ...prev, mappedData: projectMappedData }));
    }
  }, [state.projectData, state.seller]);

  const handleMint = async (data) => {
    // if (!initialized) {
    //   return false;
    // }
    if (!state.seller.tanganyAddress) {
      alert("Project seller has no tangany address");
      return false;
    }
    async function mint() {
      try {
        setIsLoading(true);
        const Carbon = new ethers.ContractFactory(
          CarbonArtifact.abi,
          CarbonArtifact.bytecode,
          signer
        );
        const contract = Carbon.attach(carbonContractAddress);
        const res = await contract.mint(
          state.seller.tanganyAddress,
          data[0],
          parseTokenUIToBlockchain(data[4]),
          "0x00"
        );
        if (res) {
          const projectId = state.projectData.id;
          const vintage = data[2];
          const response = await instance.put(
            `${BASE_URL_PROJECT}/project/onboarding/update-vintage-mint-transaction?projectId=${projectId}&transactionTxId=${res.hash}&vintageId=${vintage}`
          );
          if (response.status === 200) {
            mintVintageInState(data[0], res.hash);
            alert("Minted successfully");
          } else {
            throw "An error occurred in minting process, Please try again";
          }
        }
      } catch (error) {
        console.error(error);
        window.alert(
          "Error!" +
            (error && error.data
              ? `\n\n${error.data.message}`
              : `\n\n${error.message}`)
        );
      } finally {
        setIsLoading(false);
      }
    }
    await mint();
  };

  const { classes } = props;
  const open = Boolean(state.anchorEl);
  const columns = [
    "Id",
    "User Name",
    "Vintage",
    "Tangany Wellet Address",
    "No. of Tokens",
    "Price",
    "URI",
    {
      name: "Action",
      label: "Action",
      options: {
        customBodyRender: (props, data) => {
          return (
            <div>
              {data.rowData[7] ? (
                <span className={classes.badge}>Minted</span>
              ) : (
                <>
                  {active && carbonContractAddress && (
                    <Button
                      disabled={isLoading}
                      variant="contained"
                      color="primary"
                      onClick={() => handleMint(data.rowData)}
                    >
                      {isLoading ? "Please wait..." : "Mint"}
                    </Button>
                  )}
                </>
              )}
            </div>
          );
        },
      },
    },
  ];

  let selectedDataRows = [];
  const customToolbarSelect = (selectedRows, displayData) => {
    let items = [];
    selectedRows.data.map((value, index) => {
      let selectedValue = displayData.filter(
        (_, dataIndex) => dataIndex === value.dataIndex
      );
      if (!state.mappedData[value.dataIndex][7]) {
        items.push(selectedValue[0].data);
      }
    });
    selectedDataRows = items;
  };
  const options = {
    customToolbarSelect: customToolbarSelect,
  };

  const parseTokenUIToBlockchain = (tokens = 0) => {
    return tokens * 100;
  };
  const parseTokenBlockchainToUI = (tokens = 0) => {
    return tokens / 100;
  };
  const handleMintAll = async () => {
    const dataIds = [];
    const dataAmounts = [];
    const dataVintages = [];
    selectedDataRows.forEach((obj, index) => {
      dataIds.push(obj[0]);
      dataAmounts.push(parseTokenUIToBlockchain(obj[4]));
      dataVintages.push(obj[2]);
    });
    if (!state.seller.tanganyAddress) {
      alert("Project seller has no tangany address");
      return false;
    }
    if (!dataIds.length) {
      alert("Nothing to mint");
      return false;
    }
    async function mintAll() {
      try {
        setIsLoading(true);
        const Carbon = new ethers.ContractFactory(
          CarbonArtifact.abi,
          CarbonArtifact.bytecode,
          signer
        );
        const contract = Carbon.attach(carbonContractAddress);
        const res = await contract.mintBatch(
          state.seller.tanganyAddress,
          dataIds,
          dataAmounts,
          "0x00"
        );
        if (res) {
          const projectId = state.projectData.id;
          Promise.all(
            dataVintages.map(async (m, index) => {
              await instance.put(
                `${BASE_URL_PROJECT}/project/onboarding/update-vintage-mint-transaction?projectId=${projectId}&transactionTxId=${res.hash}&vintageId=${m}`
              );
            })
          ).then(() => {
            dataIds.map((id) => {
              mintVintageInState(id, res.hash);
            });
            alert("Minted successfully");
          });
          // const projectId = state.projectData.id;
          // const vintage = data[2];
          // const response = await instance.put(
          //   `${BASE_URL_PROJECT}/project/onboarding/update-vintage-mint-transaction?projectId=${projectId}&transactionTxId=${res.hash}&vintageId=${vintage}`
          // );
          // if (response.status === 200) {
          //   mintVintageInState(data[0], res.hash);
        } else {
          throw "An error occurred in minting process, Please try again";
        }
      } catch (error) {
        console.error(error);
        window.alert(
          "Error!" +
            (error && error.data
              ? `\n\n${error.data.message}`
              : `\n\n${error.message}`)
        );
      } finally {
        setIsLoading(false);
      }
    }
    await mintAll();
  };

  return (
    <>
      <div className={classes.mainrapper}>
        <div className={classes.fieldswrapper}>
          <p>Mint Project</p>
          <MUIDataTable
            title={state.projectData?.projectName}
            data={state.mappedData}
            columns={columns}
            options={options}
          />
          <div style={{ float: "right", margin: "8px" }}>
            {active && carbonContractAddress && (
              <Button
                disabled={isLoading}
                style={{ background: "#1D5485", color: "white" }}
                onClick={handleMintAll}
              >
                {isLoading ? "Please wait..." : "Mint All"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(VintageDetails);
