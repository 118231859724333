import { connect } from "react-redux";
import EditProjectType from "./component";
import {
    projectTypeEditTextFieldChangeHandler,
} from "../actions";
import instance, {BASE_URL} from "../../../../../api/config";
import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../common/ReduxBased/LoadingSpinner/actions";

const mapStateToProps = state => ({
    projectTypeFields: state.PROJECT_TYPE.forms.projectTypeFormEdit.fields
});

const mapDispatchToProps = (dispatch, props) => ({
    textFieldChangeHandler: (name, e) => {
        if (typeof e.value !== "string") {
            e.value = "";
        }
        dispatch(projectTypeEditTextFieldChangeHandler(name, e.value));
    },

    submitProjectTypeEdit: (id, ProjectTypeName, ProjectTypeCode) => {
        let data = {
            id: id,
            name: ProjectTypeName ? ProjectTypeName.value : "",
            code: ProjectTypeCode ? ProjectTypeCode.value : "",
            isDeleted: false
        }
        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Update Seller", "small"));

        console.log(data)

        instance.put(BASE_URL+"/project/project-type/update?id=" + id, data)
        .then(res => {
            dispatch(handleLoadingSpinnerClose());
        })
        .catch(error => {
            dispatch(handleLoadingSpinnerClose());
        })
    },

})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    submitProjectTypeEdit: (id) => {
        dispatchProps.submitProjectTypeEdit(
            id,
            stateProps.projectTypeFields.ProjectTypeName,
            stateProps.projectTypeFields.ProjectTypeCode
        )
    },
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(EditProjectType);
