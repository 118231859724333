import { connect } from "react-redux";
import ViewAllTimelineProfiles from "./component";
import instance, {BASE_URL} from "../../../../api/config";

import {
    saveAllLanguages,
} from "./actions";
import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../common/ReduxBased/LoadingSpinner/actions";

const mapStateToProps = state => ({
    langauges: state.USER_LANGUAGES.data.langauges,
})

const mapDispatchToProps = (dispatch, props) => ({
    fetchDataIfNeeded: (id) => {
        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Loading Languages...", "small"));
        instance.get(BASE_URL+"core/core-languages")
        .then(res => {
            dispatch(saveAllLanguages(res.data.data))
            dispatch(handleLoadingSpinnerClose());
        })
        .catch(err => {
            dispatch(handleLoadingSpinnerClose());
        })
    },
    deleteProfileHandler: (id) => {
        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Deleting Language", "small"));
        instance.delete(BASE_URL+"core/core-languages/remove/"+id)
        .then(res => {
            dispatch(handleLoadingSpinnerClose());
            window.location.reload();
        })
        .catch(err => {
            dispatch(handleLoadingSpinnerClose());
        })
    },
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ViewAllTimelineProfiles);