import { Redirect, Route } from "react-router-dom";
import React, {Component} from 'react';

class PrivateRoute extends Component {
    state ={
        tokenExpired: false
    }

    componentDidMount() {

        const sessionTokenCreatedTime = localStorage.getItem("SessionTokenCreatedTime")
        const SessionTokenExpiry = localStorage.getItem("SessionTokenExpiry")
        const now = new Date().getTime()
        const timeElapsed = now - sessionTokenCreatedTime

        if(timeElapsed > (SessionTokenExpiry * 1000)) {
            this.setState({tokenExpired: true})
            localStorage.clear()
            window.location.replace("/login");
        }

    }

    componentDidUpdate() {
        const sessionTokenCreatedTime = localStorage.getItem("SessionTokenCreatedTime")
        const SessionTokenExpiry = localStorage.getItem("SessionTokenExpiry")
        const now = new Date().getTime()
        const timeElapsed = now - sessionTokenCreatedTime

        if(timeElapsed > (SessionTokenExpiry * 1000)) {
            this.setState({tokenExpired: true})
            localStorage.clear()
            window.location.replace("/login");
        }

    }
    render() {
        const { component: Component, isAuthenticated, ...rest } = this.props;


        return (
            <Route
                {...rest}
                render={props =>
                    /* isAuthenticated && !this.state.tokenExpired */ true ? (
                        <>
                            <Component {...props} />
                        </>
                    ) : (
                        <>
                            <Redirect
                                to={{
                                    pathname: "/login",
                                    state: {from: props.location}
                                }}
                            />
                        </>
                    )
                    }

            />

        )
    }
}

export default PrivateRoute;
