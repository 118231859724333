import instance, { BASE_URL_USER } from "../../../../api/config";
import ls from "local-storage";

export const ACCOUNT_TYPE_PAGE_TEXT_CHANGE_HANDLER =
  "ACCOUNT_TYPE_PAGE_TEXT_CHANGE_HANDLER";
export const BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER =
  "BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER";
export const PERMISSIONS_PAGE_SELECT_CHANGE_HANDLER =
  "PERMISSIONS_PAGE_SELECT_CHANGE_HANDLER";
export const ACCOUNT_TYPE_PAGE_SAVE_ID_HANDLER =
  "ACCOUNT_TYPE_PAGE_SAVE_ID_HANDLER";
export const APP_PAGE_SAVE_ID_HANDLER = "APP_PAGE_SAVE_ID_HANDLER";
export const SAVE_ALL_ROLES_DATA = "SAVE_ALL_ROLES_DATA";
export const PROFILE_SAVE_ALL_ACCOUNT_TYPE_PROFILES =
  "PROFILE_SAVE_ALL_ACCOUNT_TYPE_PROFILES";
export const APP_PAGE_IS_ALLOWED_HANDLER = "APP_PAGE_IS_ALLOWED_HANDLER";

export const ROLE_PAGE_TEXT_CHANGE_HANDLER = "ROLE_PAGE_TEXT_CHANGE_HANDLER";
export const PERMISSION_PAGE_TEXT_CHANGE_HANDLER =
  "PERMISSION_PAGE_TEXT_CHANGE_HANDLER";
export const SAVE_ALL_ROLES_DATA_ID = "SAVE_ALL_ROLES_DATA_ID";
export const SAVE_ALL_APPS_DATA = "SAVE_ALL_APPS_DATA";

export const textFieldChangeHandler_Roles = (name, value) => (dispatch) => {
  dispatch({
    type: ROLE_PAGE_TEXT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const textFieldChangeHandler_Permissions =
  (name, value) => (dispatch) => {
    dispatch({
      type: PERMISSION_PAGE_TEXT_CHANGE_HANDLER,
      payload: value,
      key: name,
    });
  };

export const textFieldChangeHandler_I = (name, value) => (dispatch) => {
  dispatch({
    type: BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const selectFieldChangeHandler = (name, value) => (dispatch) => {
  dispatch({
    type: PERMISSIONS_PAGE_SELECT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const saveAllAccountTypesAndApp = (value) => (dispatch) => {
  let newArray = value.map((item) => {
    return [item.id, item.app_name];
  });
  dispatch({
    type: PROFILE_SAVE_ALL_ACCOUNT_TYPE_PROFILES,
    payload: newArray,
  });
};

export const saveRolesData = (value) => (dispatch) => {
  let newArray = value.map((item) => {
    return [item.name, item.accountGroup, item.createdDate];
  });
  dispatch({
    type: SAVE_ALL_ROLES_DATA,
    payload: newArray,
  });
};

export const saveRolesDataId = (value) => (dispatch) => {
  let newArray = value.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });
  dispatch({
    type: SAVE_ALL_ROLES_DATA_ID,
    payload: newArray,
  });
};

export const saveAppsData = (value) => (dispatch) => {
  let newArray = value.map((item) => {
    return {
      label: item,
      value: item,
    };
  });
  dispatch({
    type: SAVE_ALL_APPS_DATA,
    payload: newArray,
  });
};

export const saveAccountTypeIds = (value) => (dispatch) => {
  let newValue = value.map((item) => {
    return { value: item.id, label: item.name };
  });
  dispatch({
    type: ACCOUNT_TYPE_PAGE_SAVE_ID_HANDLER,
    payload: [...newValue],
  });
};

export const saveAppIds = (value) => (dispatch) => {
  let newValue = value.map((item) => {
    return { value: item.id, label: item };
  });
  dispatch({
    type: APP_PAGE_SAVE_ID_HANDLER,
    payload: [...newValue],
  });
};

export const checkIfUrlIsAllowed = (value) => (dispatch) => {
  return;
  // let accountTypeId =  ls.get('user_id')
  // let data = {
  //     params: {
  //     accountTypeId: ls.get('user_id')
  //     // accountTypeId: 1
  // }}
  // instance.get(BASE_URL_USER+`/user/core-account-types/getAllPermissions/${accountTypeId}`)
  // .then(res => {
  //     let itemVal = true;
  //     res.data.forEach(item => {
  //         if(item.url === value){
  //             // window.location.replace('/user');
  //             itemVal = item.allowed
  //         }
  //     });
  //     dispatch({
  //         type: APP_PAGE_IS_ALLOWED_HANDLER,
  //         payload: itemVal ? 'allowed' : 'not-allowed'
  //     });
  // })
  // .catch(error => {

  // })
};
