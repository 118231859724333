import ls from "local-storage";

export const SELLER_PAGE_TEXT_CHANGE_HANDLER = "SELLER_PAGE_TEXT_CHANGE_HANDLER";
export const BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER = "BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER";
export const BIDDING_PROFILE_PAGE_SELECT_CHANGE_HANDLER = "BIDDING_PROFILE_PAGE_SELECT_CHANGE_HANDLER";
export const BIDDING_PROFILE_PAGE_SAVE_ID_HANDLER = "BIDDING_PROFILE_PAGE_SAVE_ID_HANDLER";
export const SELLER_EDIT_TEXT_CHANGE_HANDLER = "SELLER_EDIT_TEXT_CHANGE_HANDLER";
export const VIEW_BIDDING_EDIT_TEXT_CHANGE_HANDLER = "VIEW_BIDDING_EDIT_TEXT_CHANGE_HANDLER";
export const PROFILE_SAVE_ALL_SELLER_PROFILES = "PROFILE_SAVE_ALL_SELLER_PROFILES"
export const SELLER_SESSION_TOKEN = "SELLER_SESSION_TOKEN";
export const SELLER_SESSION_SAVE = "SELLER_SESSION_SAVE";
export const SELLER_TEXT_CHANGE_HANDLER = "SELLER_TEXT_CHANGE_HANDLER";


export const textFieldChangeHandler = (name, value) => dispatch => {
    dispatch({
        type: SELLER_PAGE_TEXT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};

export const sellerEditTextFieldChangeHandler = (name, value) => dispatch => {
    dispatch({
        type: SELLER_TEXT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};

export const editSellerChangeHandler = ( value,name) => dispatch => {
    console.log(value)
    ls.set('EditSellerID', value)
    dispatch({
        type: SELLER_TEXT_CHANGE_HANDLER,
        payload: name[1],
        key: 'SellerUsernameCreate'
    });
    dispatch({
        type: SELLER_TEXT_CHANGE_HANDLER,
        payload: name[2],
        key: 'SellerEmailCreate'
    });
    dispatch({
        type: SELLER_TEXT_CHANGE_HANDLER,
        payload: name[3],
        key: 'SellerPasswordCreate'
    });
    dispatch({
        type: SELLER_TEXT_CHANGE_HANDLER,
        payload: name[4],
        key: 'SellerCompanyCreate'
    });
    dispatch({
        type: SELLER_TEXT_CHANGE_HANDLER,
        payload: name[5],
        key: 'SellerCountryCreate'
    });
    dispatch({
        type: SELLER_TEXT_CHANGE_HANDLER,
        payload: name[6],
        key: 'SellerCityCreate'
    });
    dispatch({
        type: SELLER_TEXT_CHANGE_HANDLER,
        payload: name[7],
        key: 'SellerAddress1Create'
    });
    dispatch({
        type: SELLER_TEXT_CHANGE_HANDLER,
        payload: name[8],
        key: 'SellerAddress2Create'
    });
    dispatch({
        type: SELLER_TEXT_CHANGE_HANDLER,
        payload: name[9],
        key: 'SellerPhoneCreate'
    });
    dispatch({
        type: SELLER_TEXT_CHANGE_HANDLER,
        payload: name[10],
        key: 'SellerPinCodeCreate'
    });
    dispatch({
        type: SELLER_TEXT_CHANGE_HANDLER,
        payload: name[11],
        key: 'SellerStatusCreate'
    });
    dispatch({
        type: SELLER_TEXT_CHANGE_HANDLER,
        payload: name[12],
        key: 'SellerVatNoCreate'
    });
};

export const saveALLSellerProfiles = (value) => dispatch => {
    let newArray = value.map((item) => {return [item.identifier, item.method, item.host,item.createdDate,item.modifiedDate,item.body, item.response]})
    dispatch({
        type: PROFILE_SAVE_ALL_SELLER_PROFILES,
        payload: newArray,
    });
};

export const saveSellerTokenResponse = (response, status) => dispatch => {

    dispatch({
        type: SELLER_SESSION_TOKEN,
        payloadSessionID: response,
        payloadFetchTokenMessage: status,

    });
};

export const saveAddSellerResponse = (sessionID, status) => dispatch => {
    dispatch({
        type: SELLER_SESSION_SAVE,
        payload: sessionID,
        payloadAddSellerMessage: status,

    });
};


export const textFieldChangeHandler_I = (name, value) => dispatch => {
    dispatch({
        type: BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};

export const selectFieldChangeHandler = (name, value) => dispatch => {
    dispatch({
        type: BIDDING_PROFILE_PAGE_SELECT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};
