export const CREATE_ATTRIBUTE = {
    data: {
        allAccountTypeProfiles: []
    },
    editData: {
        editDataId: '',
        editDataAccountType: '',
        editDataAccountGroup: ''
    },
    forms: {
        AttributesForm: {
            fields: {
                name: {
                    label: "Attribute Name",
                    name: "name",
                    placeholder: "Attribute Name",
                    value: "",
                },
                type: {
                    label: "Type",
                    name: "type",
                    placeholder: "Type",
                    value: ""
                },
                required: {
                    label: "Required",
                    name: "required",
                    placeholder: "Required",
                    value: [],
                    options: [
                        {value: true, label: "true"},
                        {value: false, label: "false"}
                    ]
                }

            }
        },
    },
    UISettings: {

    }
}
export default CREATE_ATTRIBUTE;
