import { connect } from "react-redux";
import CreateAttribute from "./component";

import {
    textFieldChangeHandler,
    selectFieldChangeHandler,
    saveAccountTypeIds,
} from "../actions";

import instance, {BASE_URL_USER} from "../../../../../../api/config";
import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../../common/ReduxBased/LoadingSpinner/actions";
import {
    checkIfUrlIsAllowed
} from "../../../Permissions/actions";

const mapStateToProps = state => ({
    fields: state.CREATE_ATTRIBUTE.forms.AttributesForm.fields,
    isAllowed: state.PERMISSIONS.UISettings.isAllowed,
})

const mapDispatchToProps = (dispatch, props) => ({
    textFieldChangeHandler: (name, e) => {
        dispatch(textFieldChangeHandler(name, e.value));
    },
    selectFieldChangeHandler: (name, e) => {
        dispatch(selectFieldChangeHandler(name, e));
    },
    getAccountTypeIDs: () => {
        instance.get(BASE_URL_USER+'core-account-types/getAccountTypes')
        .then(res => {
            dispatch(saveAccountTypeIds(res.data.data))
        })
        .catch(error => {

        })
    },


    submitAttribute: (name, type, required) => {
        let data = {
            "datatype": "string",
            "disabled": false,
            "name": name ? name.value : "",
            "required": required ? required.value[0].value : "",
            "type": type ? type.value : ""
          }

        instance.post(BASE_URL_USER+'/project/properties/create', data)
        .then(res => {
            console.log(res.data)
        })
        .catch(error => {
            dispatch(handleLoadingSpinnerClose());
        })
    },
    checkIfUrlIsAllowed: async () => {
        await dispatch(checkIfUrlIsAllowed('/core-account-types/addAccountType'))
    }
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    submitAttribute: () => {
        dispatchProps.submitAttribute(
            stateProps.fields.name,
            stateProps.fields.type,
            stateProps.fields.required,
            )
    },
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(CreateAttribute);
