import React, { Component } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import BusinessIcon from "@material-ui/icons/Business";
import PeopleIcon from "@material-ui/icons/People";
import { NavLink } from "react-router-dom";
import { ActivateDeactivate } from "../../../WEB3/components/ActivateDeactivate";
import CarbonContract from "../../../WEB3/components/CarbonContract";
import WhiteListContract from "../../../WEB3/components/WhiteListContract";
class DashboardLanding extends Component {
  componentDidMount = () => {
    // this.props.fetchDataIfNeeded();
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.dashboardwrapper}>
        <Grid container>
          <Grid item xs={12} md={3}>
            <div className={classes.dashboardboxwrapper}>
              <PeopleIcon />
              <span>Entrepreneurs</span>
              <NavLink to={"/user/all-sellers"}>View all</NavLink>
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div className={classes.dashboardboxwrapper}>
              <BusinessIcon />
              <span>Businesses</span>
              <NavLink to={"/user/all-buyers"}>View all</NavLink>
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div className={classes.dashboardboxwrapper}>
              <AccountTreeIcon />
              <span>Investors</span>
              <NavLink to={"/user/all-investors"}>View all</NavLink>
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <div className={classes.activateButtonsWrapper}>
              <h1>MetaMask Connection</h1>
              <ActivateDeactivate />
            </div>
            {/* <div className={classes.whitelistWrapper}>
              <WhiteListContract />
              <CarbonContract />
            </div> */}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(DashboardLanding);
