import { connect } from "react-redux";
import EditAccount from "./component";
import {
    textUpdateAccountType,
    UpdateAccountTypeProfile,
    selectFieldChangeHandler
} from "../actions";

const mapStateToProps = state => ({
    editFields: state.ACCOUNT_USER.editData,
    fields: state.ACCOUNT_USER.forms.AccountUserForm.fields
})

const mapDispatchToProps = (dispatch, props) => ({
    textUpdateTimelineProfile: (name, e) => {
        dispatch(textUpdateAccountType(name, e));
    },
    selectFieldChangeHandler: (name, e) => {
        dispatch(selectFieldChangeHandler(name, e));
    },
    UpdateAccountTypeProfile: (id,data) => {
        dispatch(UpdateAccountTypeProfile(id, data));
    }
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    submitTimelines: () => {
        dispatchProps.submitTimelines(
            stateProps.fields.FirstName,
            stateProps.fields.LastName,
            stateProps.fields.Status
            )
    },
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(EditAccount);