import {GET_ALL_PROJECT_TYPES, PROJECT_TYPE_TEXT_CHANGE_HANDLER, PROJECT_TYPE_CREATE_TEXT_CHANGE_HANDLER} from "./actions";

export const projectTypeReducer = (state = [], action) => {
  switch(action.type) {
    case GET_ALL_PROJECT_TYPES:
        return {
          ...state,
          data: {
            ...state.data,
            projectTypes: action.payload
          }
        };
    case PROJECT_TYPE_TEXT_CHANGE_HANDLER:
      return {
        ...state,
        forms: {
          ...state.forms,
          projectTypeFormEdit: {
            ...state.forms.projectTypeFormEdit,
            fields: {
              ...state.forms.projectTypeFormEdit.fields,
              [action.key]: {
                ...state.forms.projectTypeFormEdit.fields[action.key],
                value:action.payload
              }
            }
          }
        }
      };
    case PROJECT_TYPE_CREATE_TEXT_CHANGE_HANDLER:
      return {
        ...state,
        forms: {
          ...state.forms,
          projectTypeForm: {
            ...state.forms.projectTypeForm,
            fields: {
              ...state.forms.projectTypeForm.fields,
              [action.key]: {
                ...state.forms.projectTypeForm.fields[action.key],
                value:action.payload
              }
            }
          }
        }
      };
    default:
      return state;
  }
};

export default projectTypeReducer;
