import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import CustomTextValidator from "../../../../../common/ReduxBased/CustomTextValidator/component";

class AddNewRoles extends Component {
    
    SubmitHandler = () => {
        this.props.SubmitHandler();
    }
    render() {

        const {
            classes,
            textFieldChangeHandler,
            fields,
        } = this.props;
        return (
            <>
                <div className={classes.mainrapper}>
                <div className={classes.fieldswrapper}>
                    <p>Add New Role</p>

                    <ValidatorForm
                        onSubmit={this.SubmitHandler}
                        autoComplete="off"
                    >
                       <Grid container>

                            <Grid item xs={12} md={4}>
                               <div className="fieldwrapper">
                                <span>{fields.AccountGroup.label}</span>
                                <CustomTextValidator
                                id={fields.AccountGroup.name}
                                validations={true}
                                fullWidth={true}
                                type="text"
                                placeholder={fields.AccountGroup.placeholder}
                                name={fields.AccountGroup.name}
                                value={fields.AccountGroup.value}
                                onChange={value => {
                                    textFieldChangeHandler(
                                        fields.AccountGroup.name,
                                        value
                                    );
                                }}
                                validators={["required"]}
                                errorMessages={["Required"]}
                                className={classes.loginfields}
                            />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={4}>
                               <div className="fieldwrapper">
                                <span>{fields.Name.label}</span>
                                <CustomTextValidator
                                id={fields.Name.name}
                                validations={true}
                                fullWidth={true}
                                type="text"
                                placeholder={fields.Name.placeholder}
                                name={fields.Name.name}
                                value={fields.Name.value}
                                onChange={value => {
                                    textFieldChangeHandler(
                                        fields.Name.name,
                                        value
                                    );
                                }}
                                validators={["required"]}
                                errorMessages={["Required"]}
                                className={classes.loginfields}
                            />
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" className="submitbtn" type="submit">Submit</Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>

                </div>
            </div>
            </>

            
        )
    }


    
}
export default withStyles(styles)(AddNewRoles);