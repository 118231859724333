import { connect } from "react-redux";
import EditProjectType from "./component";
import {
    getAllDataTypes,
    projectAttributeEditTextFieldChangeHandler
} from "../actions";
import instance, {BASE_URL} from "../../../../../api/config";
import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import {selectFieldChangeHandler} from '../../ProjectManagement/Types/actions';

const mapStateToProps = state => ({
    fields: state.PROJECT_ATTRIBUTE.forms.projectAttributeForm.fields,
    dataTypes: state.PROJECT_ATTRIBUTE.data.dataTypes
});

const mapDispatchToProps = (dispatch, props) => ({
    fetchDataIfNeeded: () => {
        console.error('Fetch');
        instance.get(BASE_URL+"/project/data-types", {
            headers: {
                "Content-Type": "application/json"
            }})
            .then(res => {
                dispatch(getAllDataTypes(res.data))
            })
            .catch(err => {

            })
    },

    textFieldChangeHandler: (name, e) => {
        if (typeof e.value !== "string") {
            e.value = "";
        }
        dispatch(projectAttributeEditTextFieldChangeHandler(name, e.value));
    },

    selectFieldChangeHandler: (name, e) => {
        dispatch(selectFieldChangeHandler(name, e));
    },

    submitProjectAttributeEdit: (projectTypeId, id, dataType, name, code, isRequired, sortingOrder) => {
        let data = {
            id: id,
            projectTypeId: parseInt(projectTypeId, 10),
            dataType: dataType && dataType.value && dataType.value.length ? dataType.value[0]?.value : "",
            name: name ? name.value : "",
            code: code ? code.value : "",
            isRequired: isRequired && isRequired.value && isRequired.value.length ? isRequired.value[0]?.value : false,
            sortingOrder: sortingOrder ? sortingOrder.value : "",
            isDeleted: false
        }
        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Update  Attribute", "small"));

        instance.put(BASE_URL+"/project/project-attribute/update?id=" + id, data)
        .then(res => {
            dispatch(handleLoadingSpinnerClose());
            window.location.replace('/user/project/project-attribute/index/' + projectTypeId)
            dispatch(handleLoadingSpinnerClose());
        })
        .catch(error => {
            dispatch(handleLoadingSpinnerClose());
        })
    },

})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    submitProjectAttributeEdit: (projectTypeId, id) => {
        dispatchProps.submitProjectAttributeEdit(
            projectTypeId,
            id,
            stateProps.fields.dataType,
            stateProps.fields.name,
            stateProps.fields.code,
            stateProps.fields.isRequired,
            stateProps.fields.sortingOrder
        )
    },
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(EditProjectType);
