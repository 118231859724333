import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";
import { ValidatorForm } from "react-material-ui-form-validator";

class EditSeller extends Component {

    SubmitHandler = () => {
        this.props.submitSellerEdit();
    }

    render() {

        const {
            classes,
            textFieldChangeHandler,
            sellerFields
        } = this.props;
        return (
            <>
                <div className={classes.mainrapper}>
                <div className={classes.fieldswrapper}>
                    <p>Edit Seller</p>

                    <ValidatorForm
                        onSubmit={this.SubmitHandler}
                        autoComplete="off"
                    >
                       <Grid container>
                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.SellerUsernameCreate.label}</span>
                                   <CustomTextValidator
                                       id={sellerFields.SellerUsernameCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={sellerFields.SellerUsernameCreate.placeholder}
                                       name={sellerFields.SellerUsernameCreate.name}
                                       value={sellerFields.SellerUsernameCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               sellerFields.SellerUsernameCreate.name,
                                               value
                                           );
                                       }}
                                       validators={["required"]}
                                       errorMessages={["Required"]}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.SellerEmailCreate.label}</span>
                                   <CustomTextValidator
                                       id={sellerFields.SellerEmailCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="email"
                                       placeholder={sellerFields.SellerEmailCreate.placeholder}
                                       name={sellerFields.SellerEmailCreate.name}
                                       value={sellerFields.SellerEmailCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               sellerFields.SellerEmailCreate.name,
                                               value
                                           );
                                       }}
                                       validators={["required"]}
                                       errorMessages={["Required"]}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.SellerPasswordCreate.label}</span>
                                   <CustomTextValidator
                                       id={sellerFields.SellerPasswordCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="password"
                                       placeholder={sellerFields.SellerPasswordCreate.placeholder}
                                       name={sellerFields.SellerPasswordCreate.name}
                                       value={sellerFields.SellerPasswordCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               sellerFields.SellerPasswordCreate.name,
                                               value
                                           );
                                       }}
                                       validators={["required"]}
                                       errorMessages={["Required"]}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.SellerCompanyCreate.label}</span>
                                   <CustomTextValidator
                                       id={sellerFields.SellerCompanyCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={sellerFields.SellerCompanyCreate.placeholder}
                                       name={sellerFields.SellerCompanyCreate.name}
                                       value={sellerFields.SellerCompanyCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               sellerFields.SellerCompanyCreate.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.SellerCountryCreate.label}</span>
                                   <CustomTextValidator
                                       id={sellerFields.SellerCountryCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={sellerFields.SellerCountryCreate.placeholder}
                                       name={sellerFields.SellerCountryCreate.name}
                                       value={sellerFields.SellerCountryCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               sellerFields.SellerCountryCreate.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.SellerCityCreate.label}</span>
                                   <CustomTextValidator
                                       id={sellerFields.SellerCityCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={sellerFields.SellerCityCreate.placeholder}
                                       name={sellerFields.SellerCityCreate.name}
                                       value={sellerFields.SellerCityCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               sellerFields.SellerCityCreate.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.SellerAddress1Create.label}</span>
                                   <CustomTextValidator
                                       id={sellerFields.SellerAddress1Create.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={sellerFields.SellerAddress1Create.placeholder}
                                       name={sellerFields.SellerAddress1Create.name}
                                       value={sellerFields.SellerAddress1Create.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               sellerFields.SellerAddress1Create.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.SellerAddress2Create.label}</span>
                                   <CustomTextValidator
                                       id={sellerFields.SellerPhoneCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={sellerFields.SellerPhoneCreate.placeholder}
                                       name={sellerFields.SellerPhoneCreate.name}
                                       value={sellerFields.SellerPhoneCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               sellerFields.SellerPhoneCreate.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.SellerPhoneCreate.label}</span>
                                   <CustomTextValidator
                                       id={sellerFields.SellerPhoneCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={sellerFields.SellerPhoneCreate.placeholder}
                                       name={sellerFields.SellerPhoneCreate.name}
                                       value={sellerFields.SellerPhoneCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               sellerFields.SellerPhoneCreate.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.SellerPinCodeCreate.label}</span>
                                   <CustomTextValidator
                                       id={sellerFields.SellerPinCodeCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={sellerFields.SellerPinCodeCreate.placeholder}
                                       name={sellerFields.SellerPinCodeCreate.name}
                                       value={sellerFields.SellerPinCodeCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               sellerFields.SellerPinCodeCreate.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.SellerStatusCreate.label}</span>
                                   <CustomTextValidator
                                       id={sellerFields.SellerStatusCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={sellerFields.SellerStatusCreate.placeholder}
                                       name={sellerFields.SellerStatusCreate.name}
                                       value={sellerFields.SellerStatusCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               sellerFields.SellerStatusCreate.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.SellerVatNoCreate.label}</span>
                                   <CustomTextValidator
                                       id={sellerFields.SellerVatNoCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={sellerFields.SellerVatNoCreate.placeholder}
                                       name={sellerFields.SellerVatNoCreate.name}
                                       value={sellerFields.SellerVatNoCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               sellerFields.SellerVatNoCreate.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" className="submitbtn" type="submit">Update</Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </div>
            </div>
            </>
        )
    }
}

export default withStyles(styles)(EditSeller);
