import React from "react";
import HomeIcon from "@material-ui/icons/Home";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import BusinessIcon from "@material-ui/icons/Business";
import PeopleIcon from "@material-ui/icons/People";
import SettinsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import EventNoteIcon from "@material-ui/icons/EventNote";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import MenuBookIcon from "@material-ui/icons/MenuBook";

const user_list = [
  {
    label: "Dashboard",
    icon: <HomeIcon />,
    url: "/user",
  },
  {
    label: "Entrepreneurs",
    icon: <BusinessIcon />,
    appName: "sellerService",
    url: "/",
    children: [
      {
        label: "Entrepreneur",
        icon: <BusinessIcon />,
        url: "/",
        isFirst: true,
        children: [
          {
            label: "View All Entrepreneurs",
            icon: <BusinessIcon />,
            isSubMenu: true,
            url: "/user/all-sellers",
          },
          // {
          //     label: "Create new Entrepreneurs",
          //     icon: <BusinessIcon/>,
          //     isSubMenu: true,
          //     url: "/user/seller/create"
          // }
        ],
      },
    ],
  },
  {
    label: "Businesses",
    icon: <PeopleIcon />,
    appName: "buyerService",
    url: "/",
    children: [
      {
        label: "Business",
        icon: <PeopleIcon />,
        url: "/",
        isFirst: true,
        children: [
          {
            label: "View All Businesses",
            icon: <PeopleIcon />,
            isSubMenu: true,
            url: "/user/all-buyers",
          },
          // {
          //     label: "Create new Business",
          //     icon: <PeopleIcon/>,
          //     isSubMenu: true,
          //     url: "/user/buyer/create"
          // }
        ],
      },
    ],
  },
  {
    label: "Impact Investors",
    icon: <PeopleIcon />,
    appName: "investorService",
    url: "/",
    children: [
      {
        label: "Investors",
        icon: <PeopleIcon />,
        url: "/",
        isFirst: true,
        children: [
          {
            label: "View All Investors",
            icon: <PeopleIcon />,
            isSubMenu: true,
            url: "/user/all-investors",
          },
        ],
      },
    ],
  },
  {
    label: "Blockchain Management",
    icon: <AccountTreeIcon />,
    appName: "blockChainService",
    url: "/",
    children: [
      {
        label: "Smart Contract Deployment",
        icon: <AccountTreeIcon />,
        isSubMenu: true,
        url: "/user/blockchain/contracts/index",
      },
    ],
  },
  {
    label: "Project Management",
    icon: <AccountTreeIcon />,
    appName: "projectService",
    url: "/",
    children: [
      {
        label: "Projects",
        icon: <AccountTreeIcon />,
        url: "/",
        isFirst: true,
        children: [
          {
            label: "View All Projects",
            icon: <AccountTreeIcon />,
            isSubMenu: true,
            url: "/user/project/index",
          },
          // {
          //     label: "Create new Project",
          //     icon: <AccountTreeIcon/>,
          //     isSubMenu: true,
          //     url: "/user/project/create"
          // }
        ],
      },
      // {
      //     label: "Projecttypes",
      //     icon: <AccountTreeIcon/>,
      //     url: "/",
      //     isFirst: true,
      //     children: [
      //         {
      //             label: "View All Projecttypes",
      //             icon: <AccountTreeIcon/>,
      //             isSubMenu: true,
      //             url: "/user/project/project-type/index"
      //         },
      //         {
      //             label: "Create new Projecttype",
      //             icon: <AccountTreeIcon/>,
      //             isSubMenu: true,
      //             url: "/user/project/project-type/create"
      //         }
      //     ]
      // },
      // {
      //     label: "Main Attributes",
      //     icon: <AccountTreeIcon/>,
      //     url: "/",
      //     isFirst: true,
      //     children: [
      //         {
      //             label: "View All Main Attributes",
      //             icon: <AccountTreeIcon/>,
      //             isSubMenu: true,
      //             url: "/user/project/main-attribute/index"
      //         },
      //         {
      //             label: "Create new Main Attribute",
      //             icon: <AccountTreeIcon/>,
      //             isSubMenu: true,
      //             url: "/user/project/main-attribute/create"
      //         }
      //     ]
      // },
      // {
      //     label: "Bookbuilding",
      //     icon: <AccountTreeIcon/>,
      //     url: "/",
      //     isFirst: true,
      //     children: [
      //         {
      //             label: "View all projects in progress",
      //             icon: <AccountTreeIcon/>,
      //             isSubMenu: true,
      //             url: "/user/project/projects-in-progress"
      //         },
      //         {
      //             label: "View closed bidding",
      //             icon: <AccountTreeIcon/>,
      //             isSubMenu: true,
      //             url: "/user/project/closed-projects"
      //         }
      //     ]
      // },
      // {
      //     label: "Blockchain management",
      //     icon: <AccountTreeIcon/>,
      //     url: "/",
      //     isFirst: true,
      //     children: [
      //         {
      //             label: "View all projects - Carbon shares",
      //             icon: <AccountTreeIcon/>,
      //             isSubMenu: true,
      //             url: "/user/blockchain/projects/shares"
      //         },
      //         {
      //             label: "View all projects - Carbon credits",
      //             icon: <AccountTreeIcon/>,
      //             isSubMenu: true,
      //             url: "/user/blockchain/projects/credits"
      //         }
      //     ]
      // }
      // {
      //     label: "Attributes",
      //     icon: <AccountTreeIcon/>,
      //     url: "/",
      //     isFirst: true,
      //     children: [
      //         {
      //             label: "View All Attributes",
      //             icon: <AccountTreeIcon/>,
      //             isSubMenu: true,
      //             url: "/user/all-attributes"
      //         }
      //     ]
      // }
    ],
  },
  {
    label: "Logs",
    icon: <AccountTreeIcon />,
    appName: "logsService",
    url: "/",
    children: [
      {
        label: "Request Logs",
        icon: <AccountTreeIcon />,
        url: "/",
        isFirst: true,
        children: [
          {
            label: "Request Logs",
            icon: <AccountTreeIcon />,
            isSubMenu: true,
            url: "/user/request-seller-logs",
          },
        ],
      },
      {
        label: "Error Logs",
        icon: <AccountTreeIcon />,
        url: "/",
        isFirst: true,
        children: [
          {
            label: "Error Logs",
            icon: <AccountTreeIcon />,
            isSubMenu: true,
            url: "/user/error-seller-logs",
          },
        ],
      },
    ],
  },
  // {
  //     label: "User Management",
  //     icon: <PeopleIcon />,
  //     url: "/",
  //     children: [
  //         {
  //             label: "Account Type",
  //             icon: <PermIdentityIcon />,
  //             url: "/",
  //             isFirst: true,
  //             children: [
  //                 {
  //                     label: "Create Account Type",
  //                     icon: <PermIdentityIcon />,
  //                     isSubMenu: true,
  //                     url: "/user/create-usertype",
  //                     secretUrl: '/core-account-types/addAccountType'
  //                 },
  //                 {
  //                     label: "View All Account Types",
  //                     icon: <PermIdentityIcon />,
  //                     isSubMenu: true,
  //                     url: "/user/all-accounttype",
  //                     secretUrl: '/core-account-types/getAccountTypes'
  //                 }
  //             ],
  //         },
  //         // {
  //         //     label: "Accounts",
  //         // 	icon: <PermIdentityIcon />,
  //         // 	url: "/",
  //         // 	isFirst: true,
  //         // 	children: [
  //         // 		{
  //         // 			label: "Create Account",
  //         // 			icon: <PermIdentityIcon />,
  //         // 			isSubMenu: true,
  //         // 			url: "/user/create-account",
  //         // 			secretUrl: '/account-management/addAccount'
  //         // 		},
  //         // 		{
  //         // 			label: "View All Accounts",
  //         // 			icon: <PermIdentityIcon />,
  //         // 			isSubMenu: true,
  //         // 			url: "/user/all-accounts",
  //         // 			secretUrl: '/account-management/getAllAccounts'
  //         // 		}
  //         // 	],
  //         // },
  //         {
  //             label: "Users",
  //             icon: <AccountCircleIcon />,
  //             url: "/",
  //             isFirst: true,
  //             children: [
  //                 {
  //                     label: "Create User",
  //                     icon: <AccountCircleIcon />,
  //                     isSubMenu: true,
  //                     url: "/user/create-useraccount",
  //                     secretUrl: ''
  //                 },
  //                 {
  //                     label: "View All Users",
  //                     icon: <AccountCircleIcon />,
  //                     isSubMenu: true,
  //                     url: "/user/all-useraccount",
  //                     secretUrl: '/user-management/addUser'
  //                 }
  //             ],
  //         },
  //         {
  //             label: "Permissions",
  //             icon: <VpnKeyIcon />,
  //             url: "/",
  //             isFirst: true,
  //             children: [
  //                 {
  //                     label: "View All List",
  //                     icon: <VpnKeyIcon />,
  //                     isSubMenu: true,
  //                     url: "/user/all-permission",
  //                     secretUrl: ''
  //                 }
  //             ],
  //         }
  //     ]
  // },
  // {
  //     label: "Roles & Permissions",
  //     icon: <AccountTreeIcon/>,
  //     appName: "rolesService",
  //     url: "/",
  //     children: [
  //         {
  //             label: "Roles",
  //             icon: <AccountTreeIcon/>,
  //             url: "/",
  //             isFirst: true,
  //             children: [
  //                 {
  //                     label: "Add New",
  //                     icon: <AccountTreeIcon/>,
  //                     isSubMenu: true,
  //                     url: "/user/role-new"
  //                 },
  //                 {
  //                     label: "View All",
  //                     icon: <AccountTreeIcon/>,
  //                     isSubMenu: true,
  //                     url: "/user/role-all"
  //                 }
  //             ]
  //         },
  //         {
  //             label: "Permissions",
  //             icon: <AccountTreeIcon/>,
  //             url: "/",
  //             isFirst: true,
  //             children: [
  //                 {
  //                     label: "Add New",
  //                     icon: <AccountTreeIcon/>,
  //                     isSubMenu: true,
  //                     url: "/user/permissions-new"
  //                 },
  //                 {
  //                     label: "View All",
  //                     icon: <AccountTreeIcon/>,
  //                     isSubMenu: true,
  //                     url: "/user/permissions-all"
  //                 }
  //             ]
  //         },
  //     ]
  // },
  {
    label: "Logout",
    icon: <ExitToAppIcon />,
    url: "/user/logout",
  },
];

export default user_list;
