import { connect } from "react-redux";
import CreateBuyer from "./component";

import {
    projectAttributeCreateTextFieldChangeHandler,
    getAllDataTypes,
    selectFieldChangeHandler
} from "../actions";

import instance, { BASE_URL } from "../../../../../api/config";

import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../common/ReduxBased/LoadingSpinner/actions";

import {
    checkIfUrlIsAllowed
} from "../../Permissions/actions";

const mapStateToProps = state => ({
    fields: state.PROJECT_ATTRIBUTE.forms.projectAttributeForm.fields,
    dataTypes: state.PROJECT_ATTRIBUTE.data.dataTypes,
    isAllowed: state.PERMISSIONS.UISettings.isAllowed
})


const mapDispatchToProps = (dispatch, props) => ({
    fetchDataIfNeeded: () => {
        instance.get(BASE_URL+"/project/data-types", {
            headers: {
                "Content-Type": "application/json"
            }})
            .then(res => {
                dispatch(getAllDataTypes(res.data))
            })
            .catch(err => {

            })
    },
    textFieldChangeHandler: (name, e) => {
        if (typeof e.value !== "string") {
            e.value = "";
        }
        dispatch(projectAttributeCreateTextFieldChangeHandler(name, e.value));
    },

    submitProjectAttribute: (projectTypeId, dataType, name, code, isRequired, sortingOrder) => {

        let data = {
            projectTypeId: parseInt(projectTypeId, 10),
            dataType: dataType && dataType.value && dataType.value.length ? dataType.value[0]?.value : "",
            name: name ? name.value : "",
            code: code ? code.value : "",
            isRequired: isRequired && isRequired.value && isRequired.value.length ? isRequired.value[0]?.value : false,
            sortingOrder: sortingOrder ? sortingOrder.value : "",
            isDeleted: false
          }

        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Saving Project  Attribute", "small"));
        //todo STAGING, PROTOTYPE, LIVE

        instance.post(BASE_URL+'/project/attribute/create', data)
        .then(res => {
            window.location.replace('/user/project/project-attribute/index/' + projectTypeId);
            dispatch(handleLoadingSpinnerClose());
        })
        .catch(error => {
            console.log('error add project main attribute',error)
            dispatch(handleLoadingSpinnerClose());
        })
    },
    checkIfUrlIsAllowed: async () => {
        await dispatch(checkIfUrlIsAllowed('projectService'))
    },
    selectFieldChangeHandler: (name, e) => {
        dispatch(selectFieldChangeHandler(name, e));
    },
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    submitProjectAttribute: (projectTypeId) => {
        dispatchProps.submitProjectAttribute(
            projectTypeId,
            stateProps.fields.dataType,
            stateProps.fields.name,
            stateProps.fields.code,
            stateProps.fields.isRequired,
            stateProps.fields.sortingOrder
        )
    },
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(CreateBuyer);
