import { loginReducer } from "../components/Login/loginReducer";
import { confirmationDialogReducer } from "../components/common/ReduxBased/confirmationDialog/confirmationDialogReducer";
import { loadingSpinnerReducer } from "../components/common/ReduxBased/LoadingSpinner/loadingSpinnerReducer";
import { userDashboardReducer } from "../components/OmniConnect/Dashboard/userDashboardReducer";
import { userAccountTypeReducer } from "../components/OmniConnect/Dashboard/AccountType/userAccountTypeReducer";
import { userAccountUserReducer } from "../components/OmniConnect/Dashboard/UserAccount/userAccountUserReducer";
import { userAccountsReducer } from "../components/OmniConnect/Dashboard/Accounts/userAccountsReducer";
import { userPermissionsReducer } from "../components/OmniConnect/Dashboard/Permissions/userPermissionsReducer";
import { sellerReducer } from "../components/OmniConnect/Dashboard/SellerManagement/sellerReducer";
import { dashboardLandingReducer } from "../components/OmniConnect/Dashboard/Landing/dashboardLandingReducer";
import { userLanguagesReducer } from "../components/OmniConnect/Dashboard/Languages/userLanguagesReducer";
import { buyerReducer } from "../components/OmniConnect/Dashboard/BuyerManagement/buyerReducer";
import { projectReducer } from "../components/OmniConnect/Dashboard/ProjectManagement/projectReducer";
import { projectTypeReducer } from "../components/OmniConnect/Dashboard/ProjectTypeManagement/projectTypeReducer";
import { projectMainAttributeReducer } from "../components/OmniConnect/Dashboard/ProjectMainAttributeManagement/projectMainAttributeReducer";
import { projectAttributeReducer } from "../components/OmniConnect/Dashboard/ProjectAttributeManagement/projectAttributeReducer";
import { AttributeReducer } from "../components/OmniConnect/Dashboard/ProjectManagement/Attributes/attributeReducer";
import { registerReducer } from "../components/Register/registerReducer";
import { contractsReducer } from "../components/OmniConnect/Dashboard/BlockChainManagement/contractsReducer";

import { combineReducers } from "redux";
import reduceReducers from "reduce-reducers";

const rootReducer = reduceReducers(
  combineReducers({
    LOGIN: loginReducer,
    REGISTER: registerReducer,
    ALERT_DIALOG: confirmationDialogReducer,
    LOADING_SPINNER: loadingSpinnerReducer,
    DASHBOARD: userDashboardReducer,
    ACCOUNT_TYPE: userAccountTypeReducer,
    CREATE_ATTRIBUTE: AttributeReducer,
    ACCOUNT_USER: userAccountUserReducer,
    ACCOUNTS: userAccountsReducer,
    PERMISSIONS: userPermissionsReducer,
    SELLER: sellerReducer,
    BUYER: buyerReducer,
    PROJECT: projectReducer,
    PROJECT_TYPE: projectTypeReducer,
    PROJECT_MAIN_ATTRIBUTE: projectMainAttributeReducer,
    PROJECT_ATTRIBUTE: projectAttributeReducer,
    LANDING_DASHBOARD: dashboardLandingReducer,
    USER_LANGUAGES: userLanguagesReducer,
    CONTRACTS: contractsReducer,
  })
);

export default rootReducer;
