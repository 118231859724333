import { connect } from "react-redux";
import ViewAllAccountType from "./component";
import instance, {BASE_URL_USER} from "../../../../../api/config";
import {
    saveAllAccountTypes,
    editAccountTypeChangeHandler
} from "../actions";

import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import {
    checkIfUrlIsAllowed
} from "../../Permissions/actions";

const mapStateToProps = state => ({
    allProfiles: state.ACCOUNT_TYPE.data.allAccountTypeProfiles,
    isAllowed: state.PERMISSIONS.UISettings.isAllowed
})

const mapDispatchToProps = (dispatch, props) => ({
    fetchDataIfNeeded: (id) => {
        instance.get(BASE_URL_USER+"core-account-types/getAccountTypes")
        .then(res => {
            dispatch(saveAllAccountTypes(res.data.data))
        })
        .catch(err => {

        })
    },
    deleteProfileHandler: (id) => {
        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Deleting Account Type", "small"));
        instance.delete(BASE_URL_USER+"core-account-types/deleteAccountType/"+id)
        .then(res => {
            dispatch(handleLoadingSpinnerClose());
            window.location.reload();
        })
        .catch(err => {
            dispatch(handleLoadingSpinnerClose());
        })
    },
    editAccountTypeChangeHandler: (value,name) => {
        dispatch(editAccountTypeChangeHandler(value,name));
    },
    checkIfUrlIsAllowed: async () => {
        await dispatch(checkIfUrlIsAllowed('/core-account-types/getAccountTypes'))
    }
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ViewAllAccountType);