import {connect} from "react-redux";
import IndexProjectType from "./component";
import instance, {BASE_URL} from "../../../../../api/config";
import {
    getAllProjectAttributes,
    editProjectAttributeChangeHandler
} from "../actions";

import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import {
    checkIfUrlIsAllowed
} from "../../Permissions/actions";

const mapStateToProps = state => ({
    projectAttributes: state.PROJECT_ATTRIBUTE.data.projectAttributes,
    isAllowed: state.PERMISSIONS.UISettings.isAllowed
});

const mapDispatchToProps = (dispatch, props) => ({
    fetchDataIfNeeded: (projectTypeId) => {
        console.error('Fetch');
        instance.get(BASE_URL + "/project/attribute/type?id=" + projectTypeId, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(res => {
                dispatch(getAllProjectAttributes(res.data));
            })
            .catch(err => {

            });
    },

    deleteProjectAttributeHandler: (id) => {
        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Deleting Project  Attribute", "small"));
        instance.delete(BASE_URL + "/project/project-attribute/delete?id=" + id)
            .then(res => {
                dispatch(handleLoadingSpinnerClose());
                window.location.reload();
            })
            .catch(err => {
                dispatch(handleLoadingSpinnerClose());
            });
    },
    editProjectAttributeChangeHandler: (value, name) => {
        dispatch(editProjectAttributeChangeHandler(value, name));
    },
    checkIfUrlIsAllowed: async () => {
        await dispatch(checkIfUrlIsAllowed('projectService'));
    }

});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(IndexProjectType);
