import BG from "../../../../../resources/images/landingbg.svg";

export const styles = theme => ({
    mainrapper: {
        background:  `url(${BG})`,
        backgroundSize: "cover",
        padding: "32px 13px",
        minHeight: "calc(100vh - 115px)",
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    fieldswrapperseller: {
        background: "white",
        padding: 38,
        margin: "0px 38px",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        "& p": {
            color: "#4caf50",
            fontWeight:'bold',
            fontSize:20
        },
    },
    actions: {
        display: "block",
        padding: 7,
        minWidth: 100,
        "& button": {
            display: "block",
            width: "100%",
            marginBottom: 11
        }
    }
});