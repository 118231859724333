import { connect } from "react-redux";
import IndexProjectType from "./component";
import instance, { BASE_URL } from "../../../../../api/config";
import {
  getAllProjectTypes,
  editProjectTypeChangeHandler,
  //saveAddSellerResponse
} from "../actions";

import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import { checkIfUrlIsAllowed } from "../../Permissions/actions";

const mapStateToProps = (state) => ({
  projectTypes: state.PROJECT_TYPE.data.projectTypes,
  isAllowed: state.PERMISSIONS.UISettings.isAllowed,
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchDataIfNeeded: () => {
    console.error("Fetch");
    instance
      .get(BASE_URL + "/project/project-type/all/active", {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        dispatch(getAllProjectTypes(res.data));
      })
      .catch((err) => {});
  },

  deleteProjectTypeHandler: (id) => {
    dispatch(
      handleLoadingSpinnerOpenWithOptions(
        true,
        "Deleting Project Type",
        "small"
      )
    );
    instance
      .delete(BASE_URL + "/project/project-type/delete?id=" + id)
      .then((res) => {
        dispatch(handleLoadingSpinnerClose());
        window.location.reload();
      })
      .catch((err) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
  editProjectTypeChangeHandler: (value, name) => {
    dispatch(editProjectTypeChangeHandler(value, name));
  },
  checkIfUrlIsAllowed: async () => {
    await dispatch(checkIfUrlIsAllowed("projectService"));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(IndexProjectType);
