import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import {Button, Grid, InputLabel, MenuItem, Popover, Select} from "@material-ui/core";
import AlertDialog from "../../../../common/ReduxBased/confirmationDialog/container";
import EditSeller from "../EditSeller/container"
import Alert from '@material-ui/lab/Alert';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorMessage from "../../common/ErrorMessage/component";
import ls from 'local-storage';
import instance, {BASE_URL_BUYER_SELLER} from "../../../../../api/config";

toast.configure()
const ChangeSellerStatus = (props)=>{
    const [value,setValue]= React.useState()
    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const data = []

    props.selectedIds.map((id,index)=>{
        const obj = {
            "id" : id,
            "status" : value
        }
        data.push(obj)
    })

    const handleSave=()=>{
        instance.post(BASE_URL_BUYER_SELLER+"/project/changeSellerStatus", data)
            .then(res => {
                window.location.replace('/user/all-sellers')
            })
            .catch(error => {
                console.log(error.message)
            })
    }

    return(
        <Grid item xs={12} md={12}>
            <div style={{textAlign:"center"}}>
                <InputLabel id="projectStatusLabel">Select Project Status</InputLabel>
                <Select
                    labelId="projectStatusLabel"
                    id="projectStatus"
                    value={value}
                    onChange={handleChange}
                >
                    <MenuItem value={"PENDING"}>PENDING</MenuItem>
                    <MenuItem value={"APPROVED"}>APPROVED</MenuItem>
                    <MenuItem value={"CANCELED"}>CANCELED</MenuItem>
                </Select>
            </div>
            {
                !props.selectedIds.length &&
                <div style={{color:"red",fontSize:"12px", textAlign:"center"}}>Please select rows from table</div>
            }
            <div style={{textAlign:"center"}}>
                <Button style={{background:"#1D5485", color:"white",marginTop:"10px"}} onClick={()=>handleSave()}>Save</Button>
            </div>
        </Grid>
    )
}

class ViewRequestLogs extends Component {

    state = {
        show: false,
        showDelete: false,
        changeSellerStatus:false,
        currentId: "",
        anchorEl: null,
        resendebayUsername:"",
        resendebayEmail:""
    }

    showAlertDialog = (value, id) => {
        this.setState({
            show: value,
            currentId: id,
            showEdit:value
        })
    }

    handleClick = (event,dataValue,rowData) => {
        this.setState({
            anchorEl: event.currentTarget,
            currentId:dataValue,
            editData:rowData,
            resendebayUsername:rowData[3],
            resendebayEmail:rowData[4],

        })
    };

    handleClose = () => {
        this.setState({
            anchorEl: null
        })
    }

    showAlertDialogDelete = (value, id) => {
        this.setState({
            showDelete: value,
            currentId: id
        })
    }

    deleteProfileHandler = () => {
        this.setState({
            showDelete: false
        })
        this.props.deleteSellerHandler(this.state.currentId)
    }

    editHandler = (show,id,editData) => {
        console.log(id)
        this.props.editSellerChangeHandler(id,editData)
        this.setState({
            showEdit:show
        })
    }

    showEditSellerDialog = (value) => {
        this.setState({
            showEdit: value
        })
    }

    resendInvitationHandler = (username, email) => {
        this.props.resendInvitation(username,email)
    }

    componentDidMount = () => {
        this.props.checkIfUrlIsAllowed();
        this.props.fetchDataIfNeeded();
    }

    selectedIds = []
    customToolbarSelect = (selectedRows, displayData) => {
        let ids = []
        selectedRows.data.map((value,index)=>{
            let selectedValue = displayData.filter((_, dataIndex) => dataIndex === value.dataIndex);
            ids.push(selectedValue[0].data[0])
        })
        this.selectedIds = ids
    };

    render() {

        const {
            classes,
            allSellers,
            sellerAddStatus,
            addSellerResponseMsg,
            isAllowed
        } = this.props;
        const {currentId,editData,resendebayUsername,resendebayEmail}= this.state;
        const open = Boolean(this.state.anchorEl);
        const id = open ? 'simple-popover' : undefined;
        const columns = ["Identifier","Method","Host","Created Date","Modified Date","Body","Response",
        ];

        const options = {
        // filterType: 'checkbox',
            customToolbarSelect: this.customToolbarSelect,
        };

        return (
            <>
            {
                this.state.showDelete &&
                <AlertDialog
                    maxWidth={"sm"}
                    open={this.state.showDelete}
                    showTitle={true}
                    title={"Delete Seller"}
                    message={"Are you sure want to delete this seller?"}
                    cancelButtonText={"Close"}
                    cancelAlertDialog={() => this.showAlertDialogDelete(false)}
                    closeAlertDialog={() => this.showAlertDialogDelete(false)}
                    applyAlertDialog={() => this.deleteProfileHandler()}
                />
            }
             {
                this.state.showEdit &&
                <AlertDialog
                    maxWidth={"sm"}
                    open={this.state.showEdit}
                    showTitle={true}
                    title={"Edit Seller"}
                    message={<EditSeller />}
                    hideApplyButton={true}
                    cancelButtonText={"Close"}
                    cancelAlertDialog={() => this.showEditSellerDialog(false)}
                    closeAlertDialog={() => this.showEditSellerDialog(false)}
                    applyAlertDialog={() => this.showEditSellerDialog(false)}

                />
            }
                {
                    this.state.changeSellerStatus &&
                    <AlertDialog
                        maxWidth={"sm"}
                        open={this.state.changeSellerStatus}
                        showTitle={true}
                        title={"Edit Project Status"}
                        message={<ChangeSellerStatus selectedIds={this.selectedIds}/>}
                        hideApplyButton={true}
                        cancelButtonText={"Close"}
                        cancelAlertDialog={() => this.setState({changeSellerStatus : !this.state.changeSellerStatus})}
                        closeAlertDialog={() => this.setState({changeSellerStatus : !this.state.changeSellerStatus})}
                        applyAlertDialog={() => this.showEditSellerDialog(false)}
                    />
                }

                <div className={classes.mainrapper}>
                    <div className={classes.fieldswrapper}>
                    {addSellerResponseMsg ?
                    <Alert severity="success">{sellerAddStatus}</Alert>
                    :null}
                        <p>View all request logs</p>
                        <MUIDataTable
                            title={"Request Logs"}
                            data={allSellers}
                            columns={columns}
                            options={options}
                        />
                    </div>
                </div>
            </>
        )
    }
}
export default withStyles(styles)(ViewRequestLogs);
