import React, { Component } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Popover,
  Select,
} from "@material-ui/core";
import AlertDialog from "../../../../common/ReduxBased/confirmationDialog/container";
// import EditSeller from "../EditSeller/container"
import Alert from "@material-ui/lab/Alert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import instance, { BASE_URL_PROJECT } from "../../../../../api/config";
import { WHITELIST } from "../../../../WEB3/components/WhiteListContract";
import { CARBON } from "../../../../WEB3/components/CarbonContract";
import WhiteListContractDeployment from "../../../../WEB3/components/WhiteListContractDeployment";
import CarbonContractDeployment from "../../../../WEB3/components/CarbonContractDeployment";

toast.configure();
const ChangeProjectStatus = (props) => {
  const [value, setValue] = React.useState();
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const { popupStatusId, selectedIds } = props;
  let data;

  popupStatusId !== ""
    ? (data = {
        id: popupStatusId,
        status: value,
      })
    : selectedIds.map((id, index) => {
        data = {
          id: id,
          status: value,
        };
      });

  const handleSave = () => {
    instance
      .put(BASE_URL_PROJECT + "/project/onboarding/status-change", data)
      .then((res) => {
        window.location.replace("/user/project/index");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <Grid item xs={12} md={12}>
      <div style={{ textAlign: "center" }}>
        <InputLabel id="projectStatusLabel">Select Project Status</InputLabel>
        <Select
          labelId="projectStatusLabel"
          id="projectStatus"
          value={value}
          onChange={handleChange}
        >
          <MenuItem value={"PROJECT_AWAITING_APPROVAL"}>
            PROJECT_AWAITING_APPROVAL
          </MenuItem>
          <MenuItem value={"PROJECT_REJECTED"}>PROJECT_REJECTED</MenuItem>
          <MenuItem value={"PROJECT_APPROVED"}>PROJECT_APPROVED</MenuItem>
          <MenuItem value={"PROJECT_PUBLISHING_REQUESTED"}>
            PROJECT_PUBLISHING_REQUESTED
          </MenuItem>
          <MenuItem value={"PROJECT_LIVE"}>PROJECT_LIVE</MenuItem>
          <MenuItem value={"PROJECT_SOLD"}>PROJECT_SOLD</MenuItem>
          <MenuItem value={"PROJECT_ONHOLD"}>PROJECT_ONHOLD</MenuItem>
          <MenuItem value={"DRAFT"}>DRAFT</MenuItem>
        </Select>
      </div>
      {!props.selectedIds.length ||
        (!popupStatusId && (
          <div style={{ color: "red", fontSize: "12px", textAlign: "center" }}>
            Please select rows from table
          </div>
        ))}
      <div style={{ textAlign: "center" }}>
        <Button
          style={{ background: "#1D5485", color: "white", marginTop: "10px" }}
          onClick={() => handleSave()}
        >
          Save
        </Button>
      </div>
    </Grid>
  );
};

class ViewAllProjects extends Component {
  state = {
    show: false,
    showDelete: false,
    showEdit: false,
    changeProjectStatus: false,
    selectedSellerId: "",
    showChangeCompanyPopup: false,
    currentId: "",
    currentEmail: "",
    anchorEl: null,
    resendebayUsername: "",
    resendebayEmail: "",
    popupStatusId: "",
    canMint: false,
    whiteListContractAddress: null,
    carbonContractAddress: "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0",
    selectedProject: null,
  };

  getContractsList = async () => {
    try {
      const { data: res } = await instance.get("/blockchain/contract/list");
      return res;
    } catch (ex) {
      throw ex;
    }
  };
  getContractDetails = async (contractId) => {
    try {
      const { data: res } = await instance.get(
        "/blockchain/contract-address?contractId=" + contractId
      );
      return res;
    } catch (ex) {
      throw ex;
    }
  };
  fetchCarbonContractAddress = async () => {
    try {
      const contracts = await this.getContractsList();
      const carbonContract = contracts.find((f) => f.code === CARBON);
      if (carbonContract) {
        const contract = await this.getContractDetails(carbonContract.id);
        this.setState({ carbonContractAddress: contract.address });
      }
    } catch (ex) {
      throw ex;
    }
  };
  fetchWhiteListContractAddress = async () => {
    try {
      const contracts = await this.getContractsList();
      const whiteListContract = contracts.find((f) => f.code === WHITELIST);
      if (whiteListContract) {
        const contract = await this.getContractDetails(whiteListContract.id);
        this.setState({ whiteListContractAddress: contract.address });
      }
    } catch (ex) {
      throw ex;
    }
  };

  showAlertDialog = (value, id, data) => {
    this.setState({
      show: value,
      currentId: id,
      currentEmail: data[2],
    });
  };

  handleClick = (event, dataValue, rowData) => {
    this.setState({
      anchorEl: event.currentTarget,
      currentId: dataValue,
      editData: rowData,
      selectedContract: {
        id: rowData[0],
        name: rowData[1],
        status: rowData[2],
        address: rowData[3],
        canInitialize: rowData[1] === CARBON,
      },
    });
  };
  showAlertDialogClose = (value, id) => {
    this.setState({
      showEdit: value,
    });
  };
  showAlertDialogClosed = (value, id) => {
    this.setState({
      show: value,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  showAlertDialogDelete = (value, id) => {
    this.setState({
      showDelete: value,
      currentId: id,
    });
  };
  handleMint = (value, id, data) => {
    this.setState({
      showMintDialog: value,
      currentId: id,
      data,
    });
  };

  deleteProfileHandler = () => {
    this.setState({
      showDelete: false,
    });
    this.props.deleteSellerHandler(this.state.currentId);
  };

  editCompany = (show, id, editData) => {
    console.log(show, id);
    this.setState({
      selectedSellerId: id,
      showChangeCompanyPopup: show,
    });
  };

  editHandler = (show, id, editData) => {
    console.log(id);
    this.props.editSellerChangeHandler(id, editData);
    this.setState({
      showEdit: show,
    });
  };

  showEditSellerDialog = (value) => {
    this.setState({
      showEdit: value,
    });
  };

  resendInvitationHandler = (username, email) => {
    this.props.resendInvitation(username, email);
  };

  componentDidMount = () => {
    this.props.fetchDataIfNeeded();
  };

  selectedIds = [];
  customToolbarSelect = (selectedRows, displayData) => {
    let ids = [];
    selectedRows.data.map((value, index) => {
      let selectedValue = displayData.filter(
        (_, dataIndex) => dataIndex === value.dataIndex
      );
      ids.push(selectedValue[0].data[0]);
    });
    this.selectedIds = ids;
  };

  render() {
    const {
      classes,
      allContracts,
      fetchDataIfNeeded,
      whiteListContractAddress,
    } = this.props;
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;
    const columns = [
      "Id",
      "Smart Contract Type",
      "Status",
      "Address",
      {
        name: "Action",
        label: "Action",
        options: {
          customBodyRender: (props, data) => {
            let rowDataValue = data.rowData[0];
            let rowData = data.rowData;
            const { selectedContract } = this.state;
            return (
              <div>
                <Button
                  aria-describedby={id}
                  variant="contained"
                  color="primary"
                  onClick={(e) => this.handleClick(e, rowDataValue, rowData)}
                >
                  Actions
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={this.state.anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div className={classes.actions}>
                    {selectedContract?.name === WHITELIST && (
                      <WhiteListContractDeployment
                        onDeploy={fetchDataIfNeeded}
                        address={selectedContract?.address}
                        id={selectedContract?.id}
                      />
                    )}
                    {selectedContract?.name === CARBON && (
                      <CarbonContractDeployment
                        whiteListContractAddress={whiteListContractAddress}
                        onDeploy={fetchDataIfNeeded}
                        id={selectedContract?.id}
                        address={selectedContract?.address}
                      />
                    )}
                  </div>
                </Popover>
              </div>
            );
          },
        },
      },
    ];

    const options = {
      // filterType: 'checkbox',
      customToolbarSelect: this.customToolbarSelect,
    };

    return (
      <>
        {this.state.showDelete && (
          <AlertDialog
            maxWidth={"sm"}
            open={this.state.showDelete}
            showTitle={true}
            title={"Delete Seller"}
            message={"Are you sure want to delete this seller?"}
            cancelButtonText={"Close"}
            cancelAlertDialog={() => this.showAlertDialogDelete(false)}
            closeAlertDialog={() => this.showAlertDialogDelete(false)}
            applyAlertDialog={() => this.deleteProfileHandler()}
          />
        )}
        {this.state.changeProjectStatus && (
          <AlertDialog
            maxWidth={"sm"}
            open={this.state.changeProjectStatus}
            showTitle={true}
            title={"Edit Project Status"}
            message={
              <ChangeProjectStatus
                selectedIds={this.selectedIds}
                popupStatusId={this.state.popupStatusId}
              />
            }
            hideApplyButton={true}
            cancelButtonText={"Close"}
            cancelAlertDialog={() =>
              this.setState({
                changeProjectStatus: !this.state.changeProjectStatus,
              })
            }
            closeAlertDialog={() =>
              this.setState({
                changeProjectStatus: !this.state.changeProjectStatus,
              })
            }
            applyAlertDialog={() => this.showEditSellerDialog(false)}
          />
        )}

        <div className={classes.mainrapper}>
          <div className={classes.fieldswrapper}>
            <p>View all Contracts</p>
            <MUIDataTable
              title={"All Contracts"}
              data={allContracts}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </>
    );
  }
}
export default withStyles(styles)(ViewAllProjects);
