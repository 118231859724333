import { connect } from "react-redux";
import ViewAllProjects from "./component";
import instance, {
  BASE_URL_PROJECT,
  BASE_URL_BLOCKCHAIN,
} from "../../../../../api/config";
import { saveAllContracts } from "../actions";
import ls from "local-storage";
import { WHITELIST } from "../../../../WEB3/components/WhiteListContract";
import { CARBON } from "../../../../WEB3/components/CarbonContract";

import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";

export const getWhiteListAddress = (list) => {
  const item = list.find((f) => f[1] === WHITELIST);
  if (item) {
    return item[3];
  }
  return null;
};
export const getCarbonAddress = (list) => {
  debugger;
  const item = list.find((f) => f[1] === CARBON);
  if (item) {
    return item[3];
  }
  return null;
};

const mapStateToProps = (state) => ({
  allContracts: state.CONTRACTS.data?.list ?? [],
  whiteListContractAddress: getWhiteListAddress(
    state.CONTRACTS.data?.list ?? []
  ),
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchDataIfNeeded: async () => {
    dispatch(
      handleLoadingSpinnerOpenWithOptions(true, "Loading Projects", "small")
    );
    await instance
      .get(BASE_URL_BLOCKCHAIN + "/contract/list")
      .then((res) => {
        const contractsList = [...res.data];
        Promise.all(
          contractsList.map(async (item, index) => {
            await instance
              .get(
                BASE_URL_BLOCKCHAIN + "/contract-address?contractId=" + item.id
              )
              .then((cRes) => {
                if (cRes && cRes.data?.address) {
                  contractsList[index].status = "Deployed";
                  contractsList[index].address = cRes.data.address;
                } else {
                  contractsList[index].status = "Not deployed";
                }
              });
          })
        ).then(() => {
          console.log("list here");
          dispatch(saveAllContracts(contractsList));
        });
      })
      .catch((err) => {});
    dispatch(handleLoadingSpinnerClose());
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ViewAllProjects);
