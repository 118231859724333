import { Web3ReactProvider } from "@web3-react/core";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";

import { getProvider } from "./components/WEB3/utils/provider";

//Redux
import { Provider } from "react-redux";
import store from "./store/configurestore";

ReactDOM.render(
  <Provider store={store}>
    <Web3ReactProvider getLibrary={getProvider}>
      <App />
    </Web3ReactProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
