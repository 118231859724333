export const PROJECT = {
  data: {
    allSellersID: [],
    allSellers: [],
    attributes: [],
    mainAttributes: [],
    sellerTokenRes: "",
    addSellerResponseMsg: "",
    sellerAddStatus: "",
    fetchTokenStatus: "",
    projectName: "",
    offerPot: "",
    offerPotPrice: "",
    totalMinPrice: "",
  },
  editData: {
    editDataId: "",
    editDataFirstname: "",
    editDataLastname: "",
    editDataUserId: "",
    editDataEmail: "",
  },
  forms: {
    projectForm: {
      fields: {
        SpecialRemarks: {
          label: "Special Remarks",
          name: "SpecialRemarks",
          placeholder: "Special Remarks",
          value: "",
        },
        ProjectNameCreate: {
          label: "Project Name",
          name: "ProjectNameCreate",
          placeholder: "Project Name",
          value: "",
        },
        ProjectProjectDescriptionCreate: {
          label: "Project Description",
          name: "ProjectProjectDescriptionCreate",
          placeholder: "Project Description",
          value: "",
        },
        ProjectPublishedStatusCreate: {
          label: "Published Status",
          name: "ProjectPublishedStatusCreate",
          placeholder: "Published Status",
          value: "",
        },
        ProjectSellerIdCreate: {
          label: "Seller Id",
          name: "ProjectSellerIdCreate",
          placeholder: "Seller Id",
          value: "",
        },
        ProjectStatusCreate: {
          label: "Project Status",
          name: "ProjectStatusCreate",
          placeholder: "Project Status",
          value: "",
        },
        ProjectAmountOfCarbonCreate: {
          label: "Amount Of Carbon",
          name: "ProjectAmountOfCarbonCreate",
          placeholder: "Amount Of Carbon",
          value: "",
        },
        ProjectApprovalStatusCreate: {
          label: "Approval Status",
          name: "ProjectApprovalStatusCreate",
          placeholder: "Approval Status",
          value: "",
        },
        ProjectAreaInSquareFeetCreate: {
          label: "Area",
          name: "ProjectAreaInSquareFeetCreate",
          placeholder: "Area in square feet",
          value: "",
        },
        ProjectBookBuildingStatusCreate: {
          label: "Book Building Status",
          name: "ProjectBookBuildingStatusCreate",
          placeholder: "Book Building Status",
          value: "",
        },
        ProjectCancelledBySellerCreate: {
          label: "Cancelled By Seller",
          name: "ProjectCancelledBySellerCreate",
          placeholder: "Cancelled By Seller",
          value: [],
          options: [
            { value: true, label: "true" },
            { value: false, label: "false" },
          ],
        },
        ProjectCutGeneratedAsPredictedCreate: {
          label: "Cut Generated As Predicted",
          name: "ProjectCutGeneratedAsPredictedCreate",
          placeholder: "Cut Generated As Predicted",
          value: [],
          options: [
            { value: true, label: "true" },
            { value: false, label: "false" },
          ],
        },
        ProjectGoogleMapCoordinatesCreate: {
          label: "Google Map Coordinates",
          name: "ProjectGoogleMapCoordinatesCreate",
          placeholder: "Google Map Coordinates",
          value: "",
        },
        ProjectLocationCreate: {
          label: "Location",
          name: "ProjectLocationCreate",
          placeholder: "Location",
          value: "",
        },
        ProjectImgUrlCreate: {
          label: "Image Url",
          name: "ProjectImgUrlCreate",
          placeholder: "Image Url",
          value: "",
        },
        ProjectPutSoldStatusCreate: {
          label: "Put Sold Status",
          name: "ProjectPutSoldStatusCreate",
          placeholder: "Put Sold Status",
          value: [],
          options: [
            { value: true, label: "true" },
            { value: false, label: "false" },
          ],
        },
      },
    },

    projectFormEdit: {
      fields: {
        ProjectNameCreate: {
          label: "Project Name",
          name: "ProjectNameCreate",
          placeholder: "Project Name",
          value: "",
        },
        ProjectProjectDescriptionCreate: {
          label: "Project Description",
          name: "ProjectProjectDescriptionCreate",
          placeholder: "Project Description",
          value: "",
        },
        ProjectPublishedStatusCreate: {
          label: "Published Status",
          name: "ProjectPublishedStatusCreate",
          placeholder: "Published Status",
          value: "",
        },
        ProjectSellerIdCreate: {
          label: "Seller Id",
          name: "ProjectSellerIdCreate",
          placeholder: "Seller Id",
          value: "",
        },
        ProjectStatusCreate: {
          label: "Project Status",
          name: "ProjectStatusCreate",
          placeholder: "Project Status",
          value: "",
        },
        ProjectAmountOfCarbonCreate: {
          label: "Amount Of Carbon",
          name: "ProjectAmountOfCarbonCreate",
          placeholder: "Amount Of Carbon",
          value: "",
        },
        ProjectApprovalStatusCreate: {
          label: "Approval Status",
          name: "ProjectApprovalStatusCreate",
          placeholder: "Approval Status",
          value: "",
        },
        ProjectAreaInSquareFeetCreate: {
          label: "Area",
          name: "ProjectAreaInSquareFeetCreate",
          placeholder: "Area in square feet",
          value: "",
        },
        ProjectBookBuildingStatusCreate: {
          label: "Book Building Status",
          name: "ProjectBookBuildingStatusCreate",
          placeholder: "Book Building Status",
          value: "",
        },
        ProjectCancelledBySellerCreate: {
          label: "Cancelled By Seller",
          name: "ProjectCancelledBySellerCreate",
          placeholder: "Cancelled By Seller",
          value: [],
          options: [
            { value: true, label: "true" },
            { value: false, label: "false" },
          ],
        },
        ProjectCutGeneratedAsPredictedCreate: {
          label: "Cut Generated As Predicted",
          name: "ProjectCutGeneratedAsPredictedCreate",
          placeholder: "Cut Generated As Predicted",
          value: [],
          options: [
            { value: true, label: "true" },
            { value: false, label: "false" },
          ],
        },
        ProjectGoogleMapCoordinatesCreate: {
          label: "Google Map Coordinates",
          name: "ProjectGoogleMapCoordinatesCreate",
          placeholder: "Google Map Coordinates",
          value: "",
        },
        ProjectLocationCreate: {
          label: "Location",
          name: "ProjectLocationCreate",
          placeholder: "Location",
          value: "",
        },
        ProjectImgUrlCreate: {
          label: "Image Url",
          name: "ProjectImgUrlCreate",
          placeholder: "Image Url",
          value: "",
        },
        ProjectPutSoldStatusCreate: {
          label: "Put Sold Status",
          name: "ProjectPutSoldStatusCreate",
          placeholder: "Put Sold Status",
          value: [],
          options: [
            { value: true, label: "true" },
            { value: false, label: "false" },
          ],
        },
      },
    },
  },
  UISettings: {},
};
export default PROJECT;
