import {
  SELLER_PAGE_TEXT_CHANGE_HANDLER,
  BIDDING_PROFILE_PAGE_SELECT_CHANGE_HANDLER,
  BIDDING_PROFILE_PAGE_SELECT_CHANGE_HANDLER_EDIT,
  PROFILE_SAVE_ALL_SELLER_PROFILES,
  VIEW_BIDDING_EDIT_TEXT_CHANGE_HANDLER,
  SELLER_SESSION_TOKEN,
  SELLER_SESSION_SAVE,
  SELLER_TEXT_CHANGE_HANDLER,
  PROJECT_TEXT_CHANGE_HANDLER,
  SAVE_PROJECT_ATTRIBUTE,
  SAVE_PROJECT_ATTRIBUTE_MAIN,
  PROJECT_PAGE_MAIN_TEXT_CHANGE_HANDLER,
  PROJECT_PAGE_ATTRIBUTE_TEXT_CHANGE_HANDLER,
  PROFILE_CLOSED_VIEW_SELLER_PROFILES_BY_ID,
  PROFILE_SAVE_ALL_SELLER_PROFILES_BY_ID,
} from "./actions";

export const projectReducer = (state = [], action) => {
  switch (action.type) {
    case PROJECT_PAGE_MAIN_TEXT_CHANGE_HANDLER: {
      const oldMainAttr = state.data.mainAttributes;

      const updatedAttr = oldMainAttr.map((item) => {
        if (item.fieldName === action.key) {
          return {
            ...item,
            value: action?.payload?.value,
          };
        } else {
          return {
            ...item,
          };
        }
      });

      return {
        ...state,
        data: {
          ...state.data,
          mainAttributes: [...updatedAttr],
        },
      };
    }

    case PROJECT_PAGE_ATTRIBUTE_TEXT_CHANGE_HANDLER: {
      const oldMainAttr = state.data.attributes;

      const updatedAttr = oldMainAttr.map((item) => {
        if (item.attributeName === action.key) {
          return {
            ...item,
            value: action?.payload?.value,
          };
        } else {
          return {
            ...item,
          };
        }
      });

      return {
        ...state,
        data: {
          ...state.data,
          attributes: [...updatedAttr],
        },
      };
    }

    case SELLER_PAGE_TEXT_CHANGE_HANDLER: {
      return {
        ...state,
        forms: {
          ...state.forms,
          projectForm: {
            ...state.forms.projectForm,
            fields: {
              ...state.forms.projectForm.fields,
              [action.key]: {
                ...state.forms.projectForm.fields[action.key],
                ...action.payload,
              },
            },
          },
        },
      };
    }

    case PROJECT_TEXT_CHANGE_HANDLER: {
      return {
        ...state,
        forms: {
          ...state.forms,
          projectFormEdit: {
            ...state.forms.projectFormEdit,
            fields: {
              ...state.forms.projectFormEdit.fields,
              [action.key]: {
                ...state.forms.projectFormEdit.fields[action.key],
                value: action.payload,
              },
            },
          },
        },
      };
    }

    case PROFILE_SAVE_ALL_SELLER_PROFILES: {
      return {
          ...state,
          data: {
              ...state.data,
              allSellers: [...action.payload]
          }
      }
    }

    case SELLER_SESSION_TOKEN: {
      return {
        ...state,
        data: {
          ...state.data,
          sellerTokenRes: action.payloadSessionID,
          fetchTokenStatus: action.payloadFetchTokenMessage,
        },
      };
    }

    case SELLER_SESSION_SAVE: {
      return {
        ...state,
        data: {
          ...state.data,
          addSellerResponseMsg: action.payload,
          sellerAddStatus: action.payloadAddSellerMessage,
        },
      };
    }

    case SELLER_TEXT_CHANGE_HANDLER: {
      return {
        ...state,
        forms: {
          ...state.forms,
          projectFormEdit: {
            ...state.forms.projectFormEdit,
            fields: {
              ...state.forms.projectFormEdit.fields,
              [action.key]: {
                ...state.forms.projectFormEdit.fields[action.key],
                value: action.payload,
              },
            },
          },
        },
      };
    }

    case VIEW_BIDDING_EDIT_TEXT_CHANGE_HANDLER: {
      return {
        ...state,
        editViewData: {
          ...state.editData,
          editDataId: action.key,
          editCurrentDataId: action.viewCurrentKey,
          editstartBiddingPrice: action.payloadstartBiddingPrice,
          editendBiddingPrice: action.payloadendBiddingPrice,
          editfirstBiddingIncrement: action.payloadfirstBiddingIncrement,
          editsecondBiddingIncrement: action.payloadsecondBiddingIncrement,
        },
      };
    }

    case BIDDING_PROFILE_PAGE_SELECT_CHANGE_HANDLER: {
      return {
        ...state,
        forms: {
          ...state.forms,
          projectForm: {
            ...state.forms.projectForm,
            fields: {
              ...state.forms.projectForm.fields,
              [action.key]: {
                ...state.forms.projectForm.fields[action.key],
                value: action.payload === null ? [] : [action.payload],
              },
            },
          },
        },
      };
    }

    case BIDDING_PROFILE_PAGE_SELECT_CHANGE_HANDLER_EDIT: {
      return {
        ...state,
        forms: {
          ...state.forms,
          projectFormEdit: {
            ...state.forms.projectFormEdit,
            fields: {
              ...state.forms.projectFormEdit.fields,
              [action.key]: {
                ...state.forms.projectFormEdit.fields[action.key],
                value: action.payload === null ? [] : [action.payload],
              },
            },
          },
        },
      };
    }

    case SAVE_PROJECT_ATTRIBUTE: {
      return {
        ...state,
        data: {
          ...state.data,
          attributes: [...action.payload],
        },
      };
    }

    case SAVE_PROJECT_ATTRIBUTE_MAIN: {
      return {
        ...state,
        data: {
          ...state.data,
          mainAttributes: [...action.payload],
        },
      };
    }

    case PROFILE_SAVE_ALL_SELLER_PROFILES_BY_ID: {
      return {
        ...state,
        data: {
          ...state.data,
          allSellersID: [...action.payload],
        },
      };
    }

    case PROFILE_CLOSED_VIEW_SELLER_PROFILES_BY_ID: {
      return {
        ...state,
        data: {
          ...state.data,
          projectName: action.projectName,
          offerPot: action.offerPot,
          offerPotPrice: action.offerPotPrice,
          totalMinPrice: action.totalMinPrice,
        },
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
