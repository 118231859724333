import ls from "local-storage";
import moment from "moment";


export const SELLER_PAGE_TEXT_CHANGE_HANDLER =
  "SELLER_PAGE_TEXT_CHANGE_HANDLER";
export const BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER =
  "BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER";
export const BIDDING_PROFILE_PAGE_SELECT_CHANGE_HANDLER =
  "BIDDING_PROFILE_PAGE_SELECT_CHANGE_HANDLER";
export const BIDDING_PROFILE_PAGE_SELECT_CHANGE_HANDLER_EDIT =
  "BIDDING_PROFILE_PAGE_SELECT_CHANGE_HANDLER_EDIT";
export const SELLER_EDIT_TEXT_CHANGE_HANDLER =
  "SELLER_EDIT_TEXT_CHANGE_HANDLER";
export const VIEW_BIDDING_EDIT_TEXT_CHANGE_HANDLER =
  "VIEW_BIDDING_EDIT_TEXT_CHANGE_HANDLER";
export const PROFILE_SAVE_ALL_SELLER_PROFILES =
  "PROFILE_SAVE_ALL_SELLER_PROFILES";
export const SELLER_SESSION_TOKEN = "SELLER_SESSION_TOKEN";
export const SELLER_SESSION_SAVE = "SELLER_SESSION_SAVE";
export const PROJECT_TEXT_CHANGE_HANDLER = "PROJECT_TEXT_CHANGE_HANDLER";
export const SELLER_TEXT_CHANGE_HANDLER = "SELLER_TEXT_CHANGE_HANDLER";
export const SAVE_PROJECT_ATTRIBUTE = "SAVE_PROJECT_ATTRIBUTE";
export const SAVE_PROJECT_ATTRIBUTE_MAIN = "SAVE_PROJECT_ATTRIBUTE_MAIN";
export const PROFILE_SAVE_ALL_SELLER_PROFILES_BY_ID =
  "PROFILE_SAVE_ALL_SELLER_PROFILES_BY_ID";
export const PROFILE_CLOSED_VIEW_SELLER_PROFILES_BY_ID =
  "PROFILE_CLOSED_VIEW_SELLER_PROFILES_BY_ID";

// project attributes
export const PROJECT_PAGE_MAIN_TEXT_CHANGE_HANDLER =
  "PROJECT_PAGE_MAIN_TEXT_CHANGE_HANDLER";
export const PROJECT_PAGE_ATTRIBUTE_TEXT_CHANGE_HANDLER =
  "PROJECT_PAGE_ATTRIBUTE_TEXT_CHANGE_HANDLER";


export const textMainFieldChangeHandler = (name, value) => (dispatch) => {
  dispatch({
    type: PROJECT_PAGE_MAIN_TEXT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const textAttrFieldChangeHandler = (name, value) => (dispatch) => {
  dispatch({
    type: PROJECT_PAGE_ATTRIBUTE_TEXT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const textFieldChangeHandler = (name, value) => (dispatch) => {
  dispatch({
    type: SELLER_PAGE_TEXT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const sellerEditTextFieldChangeHandler = (name, value) => (dispatch) => {
  dispatch({
    type: SELLER_TEXT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const editSellerChangeHandler = (value, name) => (dispatch) => {
  ls.set("EditSellerID", value);
  dispatch({
    type: SELLER_TEXT_CHANGE_HANDLER,
    payload: name[1],
    key: "ProjectNameCreate",
  });
  dispatch({
    type: SELLER_TEXT_CHANGE_HANDLER,
    payload: name[2],
    key: "ProjectProjectDescriptionCreate",
  });
  dispatch({
    type: SELLER_TEXT_CHANGE_HANDLER,
    payload: name[3],
    key: "ProjectPublishedStatusCreate",
  });
  dispatch({
    type: SELLER_TEXT_CHANGE_HANDLER,
    payload: name[4],
    key: "ProjectSellerIdCreate",
  });
  dispatch({
    type: SELLER_TEXT_CHANGE_HANDLER,
    payload: name[5],
    key: "ProjectStatusCreate",
  });
  dispatch({
    type: SELLER_TEXT_CHANGE_HANDLER,
    payload: name[6],
    key: "ProjectAmountOfCarbonCreate",
  });
  dispatch({
    type: SELLER_TEXT_CHANGE_HANDLER,
    payload: name[7],
    key: "ProjectApprovalStatusCreate",
  });
  dispatch({
    type: SELLER_TEXT_CHANGE_HANDLER,
    payload: name[8],
    key: "ProjectAreaInSquareFeetCreate",
  });
  dispatch({
    type: SELLER_TEXT_CHANGE_HANDLER,
    payload: name[9],
    key: "ProjectBookBuildingStatusCreate",
  });
  dispatch({
    type: SELLER_TEXT_CHANGE_HANDLER,
    payload: name[10],
    key: "ProjectCancelledBySellerCreate",
  });
  dispatch({
    type: SELLER_TEXT_CHANGE_HANDLER,
    payload: name[11],
    key: "ProjectCutGeneratedAsPredictedCreate",
  });
  dispatch({
    type: SELLER_TEXT_CHANGE_HANDLER,
    payload: name[12],
    key: "ProjectGoogleMapCoordinatesCreate",
  });
  dispatch({
    type: SELLER_TEXT_CHANGE_HANDLER,
    payload: name[13],
    key: "ProjectLocationCreate",
  });
  dispatch({
    type: SELLER_TEXT_CHANGE_HANDLER,
    payload: name[14],
    key: "ProjectImgUrlCreate",
  });
  dispatch({
    type: SELLER_TEXT_CHANGE_HANDLER,
    payload: name[15],
    key: "ProjectPutSoldStatusCreate",
  });
};

export const saveProjectAttributes = (value) => (dispatch) => {
  const attributes = value.attributes.map((item) => {
    return {
      ...item,
      value: "",
    };
  });

  const mainAttributes = value.main.map((item) => {
    return {
      ...item,
      value: "",
    };
  });

  dispatch({
    type: SAVE_PROJECT_ATTRIBUTE,
    payload: attributes,
  });
  dispatch({
    type: SAVE_PROJECT_ATTRIBUTE_MAIN,
    payload: mainAttributes,
  });
};


export const saveALLProjects = (value) => dispatch => {
  let newArray = value.map((item) => {
    let modifiedDate = moment(item.lastModifiedDate).format("ddd MMM DD YYYY HH:mm:ss")
    return [item.id, item.projectName, item.sellerId, modifiedDate, item.onboardingStatus]})
  dispatch({
      type: PROFILE_SAVE_ALL_SELLER_PROFILES,
      payload: newArray,
  });
};

export const saveALLSellerProfiles = (value) => (dispatch) => {
  const data = value.length > 0 ? value[0] : [];

  const updatedAttr = data.attributes.map((item) => {
    return {
      name: item.attributeName,
      type: item.attributeType,
      value: item.attributeValue,
    };
  });

  const updatedMainAttr = data.main.map((item) => {
    return {
      name: item.fieldName,
      type: item.fieldType,
      value: item.fliedValue,
    };
  });
  let updatedData = [...updatedAttr, ...updatedMainAttr];

  let newArray = updatedData.map((item) => {
    return [item.name, item.value];
  });

  dispatch({
    type: PROFILE_SAVE_ALL_SELLER_PROFILES,
    payload: newArray,
  });
};

// export const saveALLClosedBidding = (value) => dispatch => {
//     let newArray = value.map((item) => {return [item.projectId, item.buyerId, item.amountOfPot,item.bidPricePerPot,item.createdBy, item.createdAt]})
//     dispatch({
//         type: PROFILE_SAVE_ALL_SELLER_PROFILES,
//         payload: newArray,
//     });
// };

export const saveALLClosedBidding = (value) => (dispatch) => {
  let newArray = value.map((item) => {
    return [
      item.id,
      item.mainAttributes[0].attributeValue,
      new Date(item.mainAttributes[1].attributeValue * 1000).toISOString().slice(0, 19).replace('T', ' '),
      new Date(item.mainAttributes[2].attributeValue * 1000).toISOString().slice(0, 19).replace('T', ' '),
      item.status,
    ];
  });
  dispatch({
    type: PROFILE_SAVE_ALL_SELLER_PROFILES,
    payload: newArray,
  });
};

export const saveALLClosedBiddingByID = (value) => (dispatch) => {
  let newArray = value.bids.map((item) => {
    return [
      item.projectId,
      item.companyName,
      item.buyerName,
      item.amountOfPot,
      item.bidPricePerPot,
      item.bidTime,
      item.allocatedPot?.allocatedPotAmount
        ? item.allocatedPot?.allocatedPotAmount
        : 0,
      item.allocatedPot?.allocatedPotPrice
        ? item.allocatedPot?.allocatedPotPrice
        : 0,
    ];
  });
  dispatch({
    type: PROFILE_SAVE_ALL_SELLER_PROFILES_BY_ID,
    payload: newArray,
  });
  dispatch({
    type: PROFILE_CLOSED_VIEW_SELLER_PROFILES_BY_ID,
    projectName: value.projectName,
    offerPot: value.offerPot,
    offerPotPrice: value.offerPotPrice,
    totalMinPrice: value.totalMinPrice,
  });
};

export const saveALLProgressBiddingByID = (value) => (dispatch) => {
  let newArray = value.bids.map((item) => {
    return [
      item.projectId,
      item.companyName,
      item.buyerName,
      item.amountOfPot,
      item.bidPricePerPot,
      new Date(item.bidTime).toISOString().slice(0, 19).replace('T', ' '),
      item.allocatedPot?.allocatedPotAmount
          ? item.allocatedPot?.allocatedPotAmount
          : 0,
      item.allocatedPot?.allocatedPotPrice
          ? item.allocatedPot?.allocatedPotPrice
          : 0,
    ];
  });
  dispatch({
    type: PROFILE_SAVE_ALL_SELLER_PROFILES_BY_ID,
    payload: newArray,
  });
  dispatch({
    type: PROFILE_CLOSED_VIEW_SELLER_PROFILES_BY_ID,
    projectName: value.projectName,
    offerPot: value.offerPot,
    offerPotPrice: value.offerPotPrice,
    totalMinPrice: value.totalMinPrice,
  });
};

export const saveALLProjectsInProgress = (value) => (dispatch) => {
  let newArray = value.map((item) => {
    let data = [item.id]
    item.mainAttributes.map((attribute) => {
      if(attribute.attributeName === "BidStartTime"){
        attribute.attributeValue = new Date(attribute.attributeValue * 1000).toISOString().slice(0, 19).replace('T', ' ')
      }
      if(attribute.attributeName === "BidEndTime"){
        attribute.attributeValue = new Date(attribute.attributeValue * 1000).toISOString().slice(0, 19).replace('T', ' ')
      }
      data.push(attribute.attributeValue)
    })
    return data;
  });
  console.log(newArray)
  dispatch({
    type: PROFILE_SAVE_ALL_SELLER_PROFILES,
    payload: newArray,
  });
};

export const saveALLBidding = (value) => (dispatch) => {
  console.log(value)
  let newArray = value.bids.map((item) => {
    return [
      item.projectId,
      item.companyName,
      item.buyerId,
      item.amountOfPot,
      item.bidPricePerPot,
      item.createdBy,
      item.createdBy,
    ];
  });
  dispatch({
    type: PROFILE_SAVE_ALL_SELLER_PROFILES,
    payload: newArray,
  });
};

export const saveSellerTokenResponse = (response, status) => (dispatch) => {
  dispatch({
    type: SELLER_SESSION_TOKEN,
    payloadSessionID: response,
    payloadFetchTokenMessage: status,
  });
};

export const saveAddSellerResponse = (sessionID, status) => (dispatch) => {
  dispatch({
    type: SELLER_SESSION_SAVE,
    payload: sessionID,
    payloadAddSellerMessage: status,
  });
};

export const textFieldChangeHandler_I = (name, value) => (dispatch) => {
  dispatch({
    type: BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const selectFieldChangeHandler = (name, value) => (dispatch) => {
  dispatch({
    type: BIDDING_PROFILE_PAGE_SELECT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const selectFieldChangeHandlerEdit = (name, value) => (dispatch) => {
  console.log("asasdsa", name, value);
  dispatch({
    type: BIDDING_PROFILE_PAGE_SELECT_CHANGE_HANDLER_EDIT,
    payload: value,
    key: name,
  });
};
