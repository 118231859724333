import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import CustomTextValidator from "./../common/ReduxBased/CustomTextValidator/component";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Redirect, NavLink } from "react-router-dom";
import ls from 'local-storage'
import AlertDialog from "../common/ReduxBased/confirmationDialog/container";
import ReactSelectValidator from "../common/ReduxBased/CustomReactSelectValidator/ReactSelectValidator";


class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRegisterPopup : false
        };
    }

    SubmitHandler = () => {
        this.props.submitFormHandler()
    }

    render() {
        const {
            classes,
            fields,
            textFieldChangeHandler,
            selectFieldChangeHandler,
            isPopup,
            error,
        } = this.props;

        const isUserLogin = ls.get("user_id");

        return (
            isUserLogin ? <Redirect to="/user" /> :
            <>
            <div className={isPopup ? "" :classes.polygonbg}>
                 <Grid container className={isPopup ? "" : classes.loginWrapper} justify="center">
                        <h1  >Register new user</h1>
                        <ValidatorForm
                        onSubmit={this.SubmitHandler}
                        autoComplete="off"
                        >
                            <ReactSelectValidator
                                id={fields.CompanyType.name}
                                name={fields.CompanyType.name}
                                value={fields.CompanyType.value}
                                placeholder={fields.CompanyType.placeholder}
                                validations={true}
                                fullWidth={true}
                                onChange={value => {
                                    selectFieldChangeHandler(
                                        fields.CompanyType.name,
                                        value
                                    );
                                }}
                                validators={["required"]}
                                errorMessages={["Required"]}
                                options={fields.CompanyType.options}
                                className="selectfield"
                            />
                            {
                                error ? <span className={classes.error}>{error}</span> : null
                            }
                            <Button variant="contained" color="primary" type="submit" className={classes.loginSubmitBtn} >
                                Next
                            </Button>
                        </ValidatorForm>
                </Grid>
                </div>
            </>
        )
    }
}

export default withStyles(styles)(Register);
