import { connect } from "react-redux";
import EditSeller from "./component";
import {
    sellerEditTextFieldChangeHandler
} from "../actions";
import ls from 'local-storage';
import instance, {BASE_URL_BUYER_SELLER} from "../../../../../api/config";
import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../common/ReduxBased/LoadingSpinner/actions";

const mapStateToProps = state => ({
    editFields: state.SELLER.editData,
    sellerFields: state.SELLER.forms.sellerFormEdit.fields
})


const mapDispatchToProps = (dispatch, props) => ({
    textFieldChangeHandler: (name, e) => {
        if (typeof e.value !== "string") {
            e.value = "";
        }
        dispatch(sellerEditTextFieldChangeHandler(name, e.value));
    },

    submitSellerEdit: (SellerUsernameCreate,SellerEmailCreate,SellerAddress1Create,
                       SellerPasswordCreate,SellerCompanyCreate,SellerCountryCreate,
                       SellerCityCreate,SellerAddress2Create,SellerPhoneCreate,SellerPinCodeCreate,
                       SellerStatusCreate, SellerVatNoCreate) => {
        let EditsellerId= ls.get('EditSellerID');
        console.log("EditsellerId", EditsellerId)
        let data = {
            addressLine1: SellerAddress1Create ? SellerAddress1Create.value : "",
            addressLine2: SellerAddress2Create ? SellerAddress2Create.value : "",
            city: SellerCityCreate ? SellerCityCreate.value : "",
            company: SellerCompanyCreate ? SellerCompanyCreate.value : "",
            country: SellerCountryCreate ? SellerCountryCreate.value : "",
            email: SellerEmailCreate ? SellerEmailCreate.value : "",
            name: SellerUsernameCreate ? SellerUsernameCreate.value : "",
            password: SellerPasswordCreate ? SellerPasswordCreate.value : "",
            phonenumber: SellerPhoneCreate ? SellerPhoneCreate.value : "",
            pincode: SellerPinCodeCreate ? SellerPinCodeCreate.value : "",
            status: SellerStatusCreate ? SellerStatusCreate.value : "",
            vatno: SellerVatNoCreate ? SellerVatNoCreate.value : ""
        }
        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Update Seller", "small"));

        instance.put(BASE_URL_BUYER_SELLER+"/seller/api/seller/"+EditsellerId, data)
        .then(res => {
            window.location.replace('/user/all-sellers')
            dispatch(handleLoadingSpinnerClose());
        })
        .catch(error => {
            dispatch(handleLoadingSpinnerClose());
        })
    },

})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    submitSellerEdit: () => {
        dispatchProps.submitSellerEdit(
            stateProps.sellerFields.SellerUsernameCreate,
            stateProps.sellerFields.SellerEmailCreate,
            stateProps.sellerFields.SellerPasswordCreate,
            stateProps.sellerFields.SellerCompanyCreate,
            stateProps.sellerFields.SellerCountryCreate,
            stateProps.sellerFields.SellerCityCreate,
            stateProps.sellerFields.SellerAddress1Create,
            stateProps.sellerFields.SellerAddress2Create,
            stateProps.sellerFields.SellerPhoneCreate,
            stateProps.sellerFields.SellerPinCodeCreate,
            stateProps.sellerFields.SellerStatusCreate,
            stateProps.sellerFields.SellerVatNoCreate,
            )
    },
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(EditSeller);
