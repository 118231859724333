import { connect } from "react-redux";
import EditProjectType from "./component";
import {
    getAllDataTypes,
    projectMainAttributeEditTextFieldChangeHandler
} from "../actions";
import instance, {BASE_URL} from "../../../../../api/config";
import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import {selectFieldChangeHandler} from '../../ProjectManagement/Types/actions';

const mapStateToProps = state => ({
    fields: state.PROJECT_MAIN_ATTRIBUTE.forms.projectMainAttributeForm.fields,
    dataTypes: state.PROJECT_MAIN_ATTRIBUTE.data.dataTypes
});

const mapDispatchToProps = (dispatch, props) => ({
    fetchDataIfNeeded: () => {
        console.error('Fetch');
        instance.get(BASE_URL+"/project/data-types", {
            headers: {
                "Content-Type": "application/json"
            }})
            .then(res => {
                dispatch(getAllDataTypes(res.data))
            })
            .catch(err => {

            })
    },

    textFieldChangeHandler: (name, e) => {
        if (typeof e.value !== "string") {
            e.value = "";
        }
        dispatch(projectMainAttributeEditTextFieldChangeHandler(name, e.value));
    },

    selectFieldChangeHandler: (name, e) => {
        dispatch(selectFieldChangeHandler(name, e));
    },

    submitProjectMainAttributeEdit: (id, dataType, value, isRequired, sortingOrder) => {
        let data = {
            id: id,
            dataType: dataType && dataType.value && dataType.value.length ? dataType.value[0]?.value : "",
            value: value ? value.value : "",
            isRequired: isRequired && isRequired.value && isRequired.value.length ? isRequired.value[0]?.value : false,
            sortingOrder: sortingOrder ? sortingOrder.value : ""
        }
        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Update Main Attribute", "small"));

        instance.put(BASE_URL+"/project/main-attributes/update?id=" + id, data)
        .then(res => {
            dispatch(handleLoadingSpinnerClose());
            window.location.replace('/user/project/main-attribute/index')
            dispatch(handleLoadingSpinnerClose());
        })
        .catch(error => {
            dispatch(handleLoadingSpinnerClose());
        })
    },

})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    submitProjectMainAttributeEdit: (id) => {
        dispatchProps.submitProjectMainAttributeEdit(
            id,
            stateProps.fields.dataType,
            stateProps.fields.value,
            stateProps.fields.isRequired,
            stateProps.fields.sortingOrder
        )
    },
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(EditProjectType);
