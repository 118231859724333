import { SAVE_ALL_CONTRACTS } from "./actions";

export const contractsReducer = (state = [], action) => {
  switch (action.type) {
    case SAVE_ALL_CONTRACTS: {
      return {
        ...state,
        data: {
          ...state.data,
          list: [...action.payload],
        },
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
