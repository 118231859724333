import { connect } from "react-redux";
import Register from "./component";
import {
    textFieldChangeHandler,
    submitFormHandler,
    selectFieldChangeHandler
} from "./actions";

const mapStateToProps = state => ({
    fields: state.REGISTER.forms.registerForm.fields,
    isLogin: state.LOGIN.UISetting.isLogin,
    error: state.LOGIN.UISetting.error
})

const mapDispatchToProps = (dispatch, props) => ({
    selectFieldChangeHandler: (name, e) => {
        dispatch(selectFieldChangeHandler(name, e));
    },
    textFieldChangeHandler: (name, e) => {
        console.log(name,e.value)
        if (typeof e.value !== "string") {
            e.value = "";
        }
        dispatch(textFieldChangeHandler(name, e));
    },

    submitFormHandler: (companyName, companyType) => {
        let data = {
                "companyName": companyName,
                "companyType": companyType
        }
        dispatch(submitFormHandler(data));
    }
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    submitFormHandler: () => {
        dispatchProps.submitFormHandler(stateProps.fields.CompanyName.value, stateProps.fields.CompanyType.value[0].value)
    },
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(Register);
