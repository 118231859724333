export const GET_ALL_PROJECT_MAIN_ATTRIBUTES = 'GET_ALL_PROJECT_MAIN_ATTRIBUTES';
export const PROJECT_MAIN_ATTRIBUTE_TEXT_CHANGE_HANDLER = 'PROJECT_MAIN_ATTRIBUTE_TEXT_CHANGE_HANDLER';
export const GET_ALL_DATA_TYPES = 'GET_ALL_DATA_TYPES';
export const PROJECT_MAIN_ATTRIBUTE_SELECT_CHANGE_HANDLER = 'PROJECT_MAIN_ATTRIBUTE_SELECT_CHANGE_HANDLER';

export const getAllProjectMainAttributes = (data) => dispatch => {
    dispatch(
        {
            type: GET_ALL_PROJECT_MAIN_ATTRIBUTES,
            payload: data
        }
    );
};

export const getAllDataTypes = (data) => dispatch => {
    dispatch(
        {
            type: GET_ALL_DATA_TYPES,
            payload: data
        }
    );
};

export const selectFieldChangeHandler = (name, value) => dispatch => {
    dispatch({
        type: PROJECT_MAIN_ATTRIBUTE_SELECT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};

export const editProjectMainAttributeChangeHandler = (value, name) => dispatch => {
    dispatch({
        type: PROJECT_MAIN_ATTRIBUTE_TEXT_CHANGE_HANDLER,
        payload: name[1],
        key: 'value'
    });
    dispatch({
        type: PROJECT_MAIN_ATTRIBUTE_SELECT_CHANGE_HANDLER,
        payload: {value: name[2], label: name[2]},
        key: 'dataType'
    });
    dispatch({
        type: PROJECT_MAIN_ATTRIBUTE_TEXT_CHANGE_HANDLER,
        payload: {value: name[3] === "true" ? true : false, label: name[3]},
        key: 'isRequired'
    });
    dispatch({
        type: PROJECT_MAIN_ATTRIBUTE_TEXT_CHANGE_HANDLER,
        payload: name[4],
        key: 'sortingOrder'
    });
};

export const projectMainAttributeEditTextFieldChangeHandler = (name, value) => dispatch => {
    dispatch({
        type: PROJECT_MAIN_ATTRIBUTE_TEXT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};

export const projectMainAttributeCreateTextFieldChangeHandler = (name, value) => dispatch => {
    dispatch({
        type: PROJECT_MAIN_ATTRIBUTE_TEXT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};
