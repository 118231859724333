import {
    LOGIN_PAGE_TEXT_CHANGE_HANDLER,
    LOGIN_SUBMIT_FORM_HANDLER,
    LOGIN_PAGE_ERROR_HANDLER
} from "./actions";
import {BIDDING_PROFILE_PAGE_SELECT_CHANGE_HANDLER} from "../OmniConnect/Dashboard/ProjectManagement/actions";

export const registerReducer = (state = [], action) => {
    switch (action.type) {
        case LOGIN_PAGE_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    registerForm: {
                        ...state.forms.registerForm,
                        fields: {
                            ...state.forms.registerForm.fields,
                            [action.key]: {
                                ...state.forms.registerForm.fields[action.key],
                                ...action.payload
                            }
                        }
                    }
                }
            };
        }

        case BIDDING_PROFILE_PAGE_SELECT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    registerForm: {
                        ...state.forms.registerForm,
                        fields: {
                            ...state.forms.registerForm.fields,
                            [action.key]: {
                                ...state.forms.registerForm.fields[action.key],
                                value: (action.payload === null) ? [] : [action.payload]
                            }
                        }
                    }
                }
            }
        }

        case LOGIN_SUBMIT_FORM_HANDLER: {
            return {
                ...state,
                data: {
                    ...state.data,
                    submitLogin: {
                        ...state.data.submitLogin,
                        result: action.payload
                    }
                }
            };
        }

        case LOGIN_PAGE_ERROR_HANDLER: {
            return {
                ...state,
                UISetting: {
                    ...state.UISetting,
                    error: action.payload
                }
            };
        }
        default: {
            return {
                ...state
            };
        }
    }
};
