import { connect } from "react-redux";
import ViewProjectDetails from "./component";
// import {
//     editViewBiddingChangeHandler
// } from "../../actions";


const mapStateToProps = state => ({
   
})

const mapDispatchToProps = (dispatch, props) => ({
    editViewBiddingChangeHandler: (value,current_id,name) => {
        // dispatch(editViewBiddingChangeHandler(value,current_id,name));
    },

})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ViewProjectDetails);