export const PERMISSIONS = {
    data: {
        allAccountTypeProfiles: [],
        allRoles: []
    },
    forms: {
        Roles: {
            fields: {
                AccountGroup: {
                    label: "Account Group",
                    name: "AccountGroup",
                    placeholder: "Account Group",
                    value: "",
                },
                Name: {
                    label: "Name",
                    name: "Name",
                    placeholder: "Name",
                    value: "",
                },
            }
        },
        Permissions: {
            fields: {
                AccountType: {
                    label: "Account Type",
                    name: "AccountType",
                    placeholder: "Account Type",
                    value: [],
                    options: []
                },
                AppName: {
                    label: "App Name",
                    name: "AppName",
                    placeholder: "App Name",
                    value: [],
                    options: []
                },
            }
        },
        biddingIncrementForm: {
            fields: {
                ProfileId: {
                    label: "Bidding Profile Id",
                    name: "ProfileId",
                    placeholder: "Bidding Profile Id",
                    value: [],
                    options: []
                },
                EndBiddingPrice: {
                    label: "End Bidding Price",
                    name: "EndBiddingPrice",
                    placeholder: "End Bidding Price",
                    value: ""
                },
                FirstIncrement: {
                    label: "First Bidding Increment",
                    name: "FirstIncrement",
                    placeholder: "First Bidding Increment",
                    value: ""
                },
                SecondIncrement: {
                    label: "Second Bidding Increment",
                    name: "SecondIncrement",
                    placeholder: "Second Bidding Increment",
                    value: ""
                },
                StartBiddingPrice: {
                    label: "Start Bidding Price",
                    name: "StartBiddingPrice",
                    placeholder: "Start Bidding Price",
                    value: ""
                },
            }
        },
    },
    UISettings: {
        isAllowed: 'loading'
    }
}
export default PERMISSIONS;