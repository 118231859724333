export const GET_ALL_PROJECT_TYPES = 'GET_ALL_PROJECT_TYPES';
export const PROJECT_TYPE_TEXT_CHANGE_HANDLER = 'PROJECT_TYPE_TEXT_CHANGE_HANDLER';
export const PROJECT_TYPE_CREATE_TEXT_CHANGE_HANDLER = 'PROJECT_TYPE_CREATE_TEXT_CHANGE_HANDLER';


export const getAllProjectTypes = (data) => dispatch => {
  dispatch(
      {
        type: GET_ALL_PROJECT_TYPES,
        payload: data
      }
  );
};

export const editProjectTypeChangeHandler = ( value,name) => dispatch => {
    dispatch({
        type: PROJECT_TYPE_TEXT_CHANGE_HANDLER,
        payload: name[1],
        key: 'ProjectTypeName'
    });
    dispatch({
        type: PROJECT_TYPE_TEXT_CHANGE_HANDLER,
        payload: name[2],
        key: 'ProjectTypeCode'
    });
};

export const projectTypeEditTextFieldChangeHandler = (name, value) => dispatch => {
    dispatch({
        type: PROJECT_TYPE_TEXT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};

export const projectTypeCreateTextFieldChangeHandler = (name, value) => dispatch => {
    dispatch({
        type: PROJECT_TYPE_CREATE_TEXT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};
