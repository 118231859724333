import React from "react";
import { useWeb3React } from "@web3-react/core";
import { Contract, ethers, Signer } from "ethers";
import {
  ChangeEvent,
  MouseEvent,
  ReactElement,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import WhitelistArtifact from "../../../artifacts/contracts/Whitelist.sol/Whitelist.json";
import { Provider } from "../utils/provider";
import { SectionDivider } from "./SectionDivider";
import axios from "../../../api/config";
import { Button } from "@material-ui/core";

export const WHITELIST = "WhiteListing";
const DEFAULT_ADDRESS = "0x0000000000000000000000000000000000000000";

export default function WhiteListContract(props: any): ReactElement {
  const context = useWeb3React<Provider>();
  const { library, active } = context;

  const [signer, setSigner] = useState<Signer>();
  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState(props.address || "");
  const id = props.id;
  const { onDeploy = () => {} } = props;

  useEffect((): void => {
    if (!library) {
      setSigner(undefined);
      return;
    }
    setSigner(library.getSigner());
  }, [library]);

  const updateContractAddress = async (id: any, address: string) => {
    try {
      const { data: res } = await axios.post("/blockchain/contract-address", {
        contractId: id,
        address,
        isAddressConstant: true,
      });
      return res;
    } catch (ex) {
      throw ex;
    }
  };
  useEffect(() => {
    const postContractInList = async (data: any) => {
      try {
        const { data: res } = await axios.post("/blockchain/contract", data);
        return res;
      } catch (ex) {
        throw ex;
      }
    };
    const getContractsList = async () => {
      try {
        const { data: res } = await axios.get("/blockchain/contract/list");
        return res;
      } catch (ex) {
        throw ex;
      }
    };
    const getContractDetails = async (contractId: any) => {
      try {
        const { data: res } = await axios.get(
          "/blockchain/contract-address?contractId=" + contractId
        );
        return res;
      } catch (ex) {
        throw ex;
      }
    };
    const fetchWhiteListContractAddress = async () => {
      try {
        const contracts = await getContractsList();
        const whiteListContract = contracts.find(
          (f: any) => f.code === WHITELIST
        );
        if (!whiteListContract) {
          // const { id } = await postContractInList({
          //   name: WHITELIST,
          //   code: WHITELIST,
          // });
          // setWhiteListContractId(id);
        } else {
          const contract = await getContractDetails(whiteListContract.id);
          setAddress(contract.address);
        }
      } catch (ex) {
        throw ex;
      }
    };

    try {
      // fetchWhiteListContractAddress();
    } catch (ex: any) {
      console.error("Error in fetching white list contract", ex.message);
    }
  }, []);

  function handleWhiteListDefaultAddress(contractAddress: any) {
    async function whiteListDefault() {
      try {
        const Whitelist = new ethers.ContractFactory(
          WhitelistArtifact.abi,
          WhitelistArtifact.bytecode,
          signer
        );
        const whitelistContract = Whitelist.attach(contractAddress);
        const res = await whitelistContract.initializeWhitelist();
        if (res) {
          await updateContractAddress(id, contractAddress);
          window.alert(`Whitelist deployed to: ${contractAddress}`);
          setAddress(contractAddress);
          onDeploy();
        }
      } catch (error: any) {
        window.alert(
          "Error!" +
            (error && error.data
              ? `\n\n${error.data.message}`
              : `\n\n${error.message}`)
        );
      } finally {
        setIsLoading(false);
      }
    }
    whiteListDefault();
  }

  function handleDeployContract() {
    // event.preventDefault();
    // only deploy the Greeter contract one time, when a signer is defined
    if (!signer) {
      return;
    }
    async function deployWhitelistContract(signer: Signer): Promise<void> {
      setIsLoading(true);
      const Whitelist = new ethers.ContractFactory(
        WhitelistArtifact.abi,
        WhitelistArtifact.bytecode,
        signer
      );
      try {
        const whitelistContract = await Whitelist.deploy();
        await whitelistContract.deployed();
        handleWhiteListDefaultAddress(whitelistContract.address);
      } catch (error: any) {
        window.alert(
          "Error!" +
            (error && error.data
              ? `\n\n${error.data.message}`
              : `\n\n${error.message}`)
        );
      } finally {
        setIsLoading(false);
      }
    }
    deployWhitelistContract(signer);
  }

  if (!active) {
    return <></>;
  }

  return (
    <>
      {address ? (
        <Label>Deployed</Label>
      ) : (
        <Button onClick={handleDeployContract} disabled={isLoading}>
          {isLoading ? "Please wait..." : "Deploy"}
        </Button>
      )}
    </>
  );
}
const Label = styled.label`
  font-weight: bold;
  font-style: italic;
`;

const StyledDeployContractButton = styled.button`
  width: 180px;
  height: 2rem;
  border-radius: 1rem;
  border-color: blue;
  cursor: pointer;
  place-self: center;
`;

const StyledWhitelistDiv = styled.div``;

const StyledLabel = styled.label`
  font-weight: bold;
  display: block;
  margin-top: 1em;
`;
