import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";
import { ValidatorForm } from "react-material-ui-form-validator";
import ReactSelectValidator from '../../../../common/ReduxBased/CustomReactSelectValidator/ReactSelectValidator';

class EditProjectType extends Component {
    submitHandler = (id) => {
        this.props.submitProjectMainAttributeEdit(id);
    }

    render() {

        const {
            classes,
            textFieldChangeHandler,
            selectFieldChangeHandler,
            dataTypes,
            fields,
            currentId
        } = this.props;

        return (
            <>
                <div className={classes.mainrapper}>
                <div className={classes.fieldswrapper}>
                    <p>Edit Main Attribute</p>

                    <ValidatorForm
                        onSubmit={() => this.submitHandler(currentId)}
                        autoComplete="off"
                    >
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <div className="fieldwrapper">
                                    <span>{fields.dataType.label}</span>
                                    <ReactSelectValidator
                                        id={fields.dataType.name}
                                        name={fields.dataType.name}
                                        value={fields.dataType.value}
                                        placeholder={fields.dataType.placeholder}
                                        validations={true}
                                        fullWidth={true}
                                        onChange={value => {
                                            selectFieldChangeHandler(
                                                fields.dataType.name,
                                                value
                                            );
                                        }}
                                        options={dataTypes.map((dataType) => ({value: dataType, label: dataType}))}
                                        className="selectfield"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="fieldwrapper">
                                    <span>{fields.value.label}</span>
                                    <CustomTextValidator
                                        id={fields.value.name}
                                        fullWidth={true}
                                        validations={true}
                                        type="text"
                                        placeholder={fields.value.placeholder}
                                        name={fields.value.name}
                                        value={fields.value.value}
                                        onChange={value => {
                                            textFieldChangeHandler(
                                                fields.value.name,
                                                value
                                            );
                                        }}
                                        validators={["required"]}
                                        errorMessages={["Required"]}
                                        className="textfield"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="fieldwrapper">
                                    <span>{fields.isRequired.label}</span>
                                    <ReactSelectValidator
                                        id={fields.isRequired.name}
                                        name={fields.isRequired.name}
                                        value={fields.isRequired.value}
                                        placeholder={fields.isRequired.placeholder}
                                        validations={true}
                                        fullWidth={true}
                                        onChange={value => {
                                            selectFieldChangeHandler(
                                                fields.isRequired.name,
                                                value
                                            );
                                        }}
                                        options={fields.isRequired.options}
                                        className="selectfield"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="fieldwrapper">
                                    <span>{fields.sortingOrder.label}</span>
                                    <CustomTextValidator
                                        id={fields.sortingOrder.name}
                                        fullWidth={true}
                                        validations={true}
                                        type="number"
                                        placeholder={fields.sortingOrder.placeholder}
                                        name={fields.sortingOrder.name}
                                        value={fields.sortingOrder.value}
                                        onChange={value => {
                                            textFieldChangeHandler(
                                                fields.sortingOrder.name,
                                                value
                                            );
                                        }}
                                        validators={["required"]}
                                        errorMessages={["Required"]}
                                        className="textfield"
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" className="submitbtn" type="submit">Update</Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </div>
            </div>
            </>
        )
    }
}

export default withStyles(styles)(EditProjectType);
