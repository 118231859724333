import React, { Component, useEffect } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Popover,
  Select,
} from "@material-ui/core";
import AlertDialog from "../../../../common/ReduxBased/confirmationDialog/container";
import EditSeller from "../EditSeller/container";
import Alert from "@material-ui/lab/Alert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewAllProjects from "./Projects/container";
import ViewAllUserDetails from "./UserDetails/container";
import instance, { BASE_URL_INVESTOR } from "../../../../../api/config";
import ViewSustainabilityForm from "./SustainabilityFormDetails/container";
import Whitelister from "../../../../WEB3/components/Whitelister";
import ViewAllCompanyDetails from "../../BuyerManagement/ViewAllBuyers/CompanyDetails/container";

toast.configure();

const ChangeBuyerStatus = (props) => {
  const [value, setValue] = React.useState(props.status || "");
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const { popupStatusId, selectedIds } = props;
  let data;

  popupStatusId !== ""
    ? (data = {
        id: popupStatusId,
        status: value,
      })
    : selectedIds.map((id, index) => {
        data = {
          id: id,
          status: value,
        };
      });

  const handleSave = () => {
    instance
      .post(BASE_URL_INVESTOR + "/api/changeAdminInvestorStatus", data)
      .then((res) => {
        window.location.replace("/user/all-investors");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <Grid item xs={12} md={12}>
      <div style={{ textAlign: "center" }}>
        <InputLabel id="projectStatusLabel">Select Project Status</InputLabel>
        <Select
          labelId="projectStatusLabel"
          id="projectStatus"
          value={value}
          onChange={handleChange}
        >
          <MenuItem value={"REGISTRATION_PENDING"}>
            REGISTRATION_PENDING
          </MenuItem>
          <MenuItem value={"REGISTRATION_APPROVED"}>
            REGISTRATION_APPROVED
          </MenuItem>
          <MenuItem value={"REGISTRATION_DENIED"}>REGISTRATION_DENIED</MenuItem>
          <MenuItem value={"ONBOARDING_REQUESTED"}>
            ONBOARDING_REQUESTED
          </MenuItem>
          <MenuItem value={"ONBOARDING_IN_PROGRESS"}>
            ONBOARDING_IN_PROGRESS
          </MenuItem>
          <MenuItem value={"ONBOARDING_APPROVED"}>ONBOARDING_APPROVED</MenuItem>
          <MenuItem value={"ONBOARDING_REJECT"}>ONBOARDING_REJECT</MenuItem>
          <MenuItem value={"ONBOARDED"}>ONBOARDED</MenuItem>
          <MenuItem value={"WHITELISTED"}>WHITELISTED</MenuItem>
          <MenuItem value={"SIGNED_PURCHASE_AGREEMENT"}>
            SIGNED_PURCHASE_AGREEMENT
          </MenuItem>
        </Select>
      </div>
      {!props.selectedIds.length ||
        (!popupStatusId && (
          <div style={{ color: "red", fontSize: "12px", textAlign: "center" }}>
            Please select rows from table
          </div>
        ))}
      <div style={{ textAlign: "center" }}>
        <Button
          style={{ background: "#1D5485", color: "white", marginTop: "10px" }}
          onClick={() => handleSave()}
        >
          Save
        </Button>
      </div>
    </Grid>
  );
};

class ViewImpactInvestors extends Component {
  state = {
    show: false,
    showDelete: false,
    showProjects: false,
    showUsers: false,
    selectedSellerId: "",
    showChangeCompanyPopup: false,
    currentId: "",
    anchorEl: null,
    resendebayUsername: "",
    resendebayEmail: "",
    changeBuyerStatus: false,
    showSustainability: false,
  };

  showAlertDialog = (value, id) => {
    this.setState({
      show: value,
      currentId: id,
      showEdit: value,
    });
  };

  showProjectsAlertDialog = (value, id, data) => {
    this.setState({
      showProjects: value,
      currentId: id,
      currentEmail: data[2],
    });
  };

  showUserAlertDialog = (value, id, data) => {
    this.setState({
      showUsers: value,
      currentId: id,
      currentEmail: data[2],
    });
  };

  showCompanyAlertDialog = (value, id, data) => {
    this.setState({
      showCompanyProfile: value,
      currentId: id,
      currentEmail: data[2],
    });
  };
  closeCompanyProfileDialog = () => {
    this.setState({
      showCompanyProfile: false,
    });
  };

  showSustainabilityAlertDialog = (value, id, data) => {
    this.setState({
      showSustainability: value,
      currentId: id,
      currentEmail: data[2],
    });
  };

  handleClick = (event, dataValue, rowData) => {
    this.setState({
      anchorEl: event.currentTarget,
      currentId: dataValue,
      editData: rowData,
      resendebayUsername: rowData[3],
      resendebayEmail: rowData[4],
      tanganyAddress: rowData[7],
      status: rowData[6],
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  showAlertDialogDelete = (value, id) => {
    this.setState({
      showDelete: value,
      currentId: id,
    });
  };

  deleteProfileHandler = () => {
    this.setState({
      showDelete: false,
    });
    this.props.deleteInvestorHandler(this.state.currentId);
  };

  editCompany = (show, id, editData) => {
    console.log(show, id);
    this.setState({
      selectedSellerId: id,
      showChangeCompanyPopup: show,
    });
  };

  editHandler = (show, id, editData) => {
    console.log(id);
    this.props.editSellerChangeHandler(id, editData);
    this.setState({
      showEdit: show,
    });
  };

  showEditSellerDialog = (value) => {
    this.setState({
      showEdit: value,
    });
  };

  showAlertDialogClosed = (value, id) => {
    this.setState({
      show: value,
      showProjects: value,
      showUsers: value,
      showSustainability: value,
    });
  };

  resendInvitationHandler = (username, email) => {
    this.props.resendInvitation(username, email);
  };

  componentDidMount = () => {
    this.props.checkIfUrlIsAllowed();
    this.props.fetchDataIfNeeded();
  };

  selectedIds = [];
  customToolbarSelect = (selectedRows, displayData) => {
    let ids = [];
    selectedRows.data.map((value, index) => {
      let selectedValue = displayData.filter(
        (_, dataIndex) => dataIndex === value.dataIndex
      );
      ids.push(selectedValue[0].data[0]);
    });
    this.selectedIds = ids;
  };

  render() {
    const {
      classes,
      allSellers,
      sellerAddStatus,
      addSellerResponseMsg,
      isAllowed,
      whiteListContractAddress,
    } = this.props;
    const {
      currentId,
      editData,
      resendebayUsername,
      resendebayEmail,
      tanganyAddress,
      status,
    } = this.state;
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;
    const columns = [
      "ID",
      "Name",
      "Email",
      "Email Verified",
      "Company Name",
      "Address",
      "Status",
      {
        name: "Action",
        label: "Action",
        options: {
          customBodyRender: (props, data) => {
            let rowDataValue = data.rowData[0];
            let rowData = data.rowData;

            return (
              <div>
                <Button
                  aria-describedby={id}
                  variant="contained"
                  color="primary"
                  onClick={(e) => this.handleClick(e, rowDataValue, rowData)}
                >
                  Actions
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={this.state.anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div className={classes.actions}>
                    <Whitelister
                      status={status}
                      onWhiteList={this.props.fetchDataIfNeeded}
                      sellerId={currentId}
                      isWhitelist={true}
                      whiteListContractAddress={whiteListContractAddress}
                      tanganyAddress={tanganyAddress}
                    />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        this.showProjectsAlertDialog(true, currentId, editData)
                      }
                    >
                      Projects Details
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        this.showCompanyAlertDialog(true, currentId, editData)
                      }
                    >
                      Company Profile
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        this.showUserAlertDialog(true, currentId, editData)
                      }
                    >
                      User Details
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        this.setState({
                          changeBuyerStatus: !this.state.changeBuyerStatus,
                          popupStatusId: editData[0],
                        })
                      }
                    >
                      Change Status
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        this.showSustainabilityAlertDialog(
                          true,
                          currentId,
                          editData
                        )
                      }
                    >
                      View Sustainability Form
                    </Button>
                    {/* <Button color="primary" variant="outlined" onClick={()=>this.editCompany(true, currentId, editData)}>Change company</Button>
                                    <Button color="primary" variant="outlined" onClick={()=>this.editHandler(true, currentId, editData)}>Edit</Button> */}
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        this.showAlertDialogDelete(true, currentId)
                      }
                    >
                      Delete
                    </Button>
                  </div>
                </Popover>
              </div>
            );
          },
        },
      },
    ];

    const options = {
      // filterType: 'checkbox',
      customToolbarSelect: this.customToolbarSelect,
    };

    return (
      <>
        {this.state.showCompanyProfile && (
          <AlertDialog
            maxWidth={"lg"}
            open={this.state.showCompanyProfile}
            showTitle={false}
            hideApplyButton={true}
            message={
              <ViewAllCompanyDetails
                currentId={this.state.currentId}
                currentEmail={this.state.currentEmail}
                showAlertDialog={this.showAlertDialog}
                tanganyAddress={this.state.tanganyAddress}
              />
            }
            cancelButtonText={"Close"}
            cancelAlertDialog={() => this.closeCompanyProfileDialog()}
            closeAlertDialog={() => this.closeCompanyProfileDialog()}
            applyAlertDialog={() => this.closeCompanyProfileDialog()}
          />
        )}
        {this.state.showDelete && (
          <AlertDialog
            maxWidth={"sm"}
            open={this.state.showDelete}
            showTitle={true}
            title={"Delete Seller"}
            message={"Are you sure want to delete this seller?"}
            cancelButtonText={"Close"}
            cancelAlertDialog={() => this.showAlertDialogDelete(false)}
            closeAlertDialog={() => this.showAlertDialogDelete(false)}
            applyAlertDialog={() => this.deleteProfileHandler()}
          />
        )}

        {this.state.showEdit && (
          <AlertDialog
            maxWidth={"sm"}
            open={this.state.showEdit}
            showTitle={true}
            title={"Edit Seller"}
            message={<EditSeller />}
            hideApplyButton={true}
            cancelButtonText={"Close"}
            cancelAlertDialog={() => this.showEditSellerDialog(false)}
            closeAlertDialog={() => this.showEditSellerDialog(false)}
            applyAlertDialog={() => this.showEditSellerDialog(false)}
          />
        )}
        {this.state.changeBuyerStatus && (
          <AlertDialog
            maxWidth={"sm"}
            open={this.state.changeBuyerStatus}
            showTitle={true}
            title={"Edit Project Status"}
            message={
              <ChangeBuyerStatus
                status={this.state.status}
                selectedIds={this.selectedIds}
                popupStatusId={this.state.popupStatusId}
              />
            }
            hideApplyButton={true}
            cancelButtonText={"Close"}
            cancelAlertDialog={() =>
              this.setState({
                changeBuyerStatus: !this.state.changeBuyerStatus,
              })
            }
            closeAlertDialog={() =>
              this.setState({
                changeBuyerStatus: !this.state.changeBuyerStatus,
              })
            }
            applyAlertDialog={() => this.showEditSellerDialog(false)}
          />
        )}

        {this.state.showProjects && (
          <AlertDialog
            maxWidth={"lg"}
            open={this.state.showProjects}
            showTitle={false}
            hideApplyButton={true}
            message={
              <ViewAllProjects
                currentId={this.state.currentId}
                currentEmail={this.state.currentEmail}
                showProjectsAlertDialog={this.showProjectsAlertDialog}
              />
            }
            cancelButtonText={"Close"}
            cancelAlertDialog={() => this.showAlertDialogClosed(false)}
            closeAlertDialog={() => this.showAlertDialogClosed(false)}
            applyAlertDialog={() => this.showAlertDialogClosed(false)}
          />
        )}

        {this.state.showSustainability && (
          <AlertDialog
            maxWidth={"lg"}
            open={this.state.showSustainability}
            showTitle={false}
            hideApplyButton={true}
            message={
              <ViewSustainabilityForm
                currentId={this.state.currentId}
                currentEmail={this.state.currentEmail}
                showSustainabilityAlertDialog={
                  this.showSustainabilityAlertDialog
                }
              />
            }
            cancelButtonText={"Close"}
            cancelAlertDialog={() => this.showAlertDialogClosed(false)}
            closeAlertDialog={() => this.showAlertDialogClosed(false)}
            applyAlertDialog={() => this.showAlertDialogClosed(false)}
          />
        )}

        {this.state.showUsers && (
          <AlertDialog
            maxWidth={"lg"}
            open={this.state.showUsers}
            showTitle={false}
            hideApplyButton={true}
            message={
              <ViewAllUserDetails
                currentId={this.state.currentId}
                currentEmail={this.state.currentEmail}
                showUserAlertDialog={this.showUserAlertDialog}
              />
            }
            cancelButtonText={"Close"}
            cancelAlertDialog={() => this.showAlertDialogClosed(false)}
            closeAlertDialog={() => this.showAlertDialogClosed(false)}
            applyAlertDialog={() => this.showAlertDialogClosed(false)}
          />
        )}

        <div className={classes.mainrapper}>
          <div className={classes.fieldswrapper}>
            {addSellerResponseMsg ? (
              <Alert severity="success">{sellerAddStatus}</Alert>
            ) : null}
            <p>View all Impact Investors</p>
            <MUIDataTable
              title={"Impact Investors"}
              data={allSellers}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </>
    );
  }
}
export default withStyles(styles)(ViewImpactInvestors);
