export const PROJECT_ATTRIBUTE = {
    data: {
        projectAttributes: [],
        dataTypes: []
    },
    forms: {
        projectAttributeForm: {
            fields: {
                dataType: {
                    label: "Data Type",
                    name: "dataType",
                    placeholder: "Data Type",
                    value: {}
                },
                name: {
                    label: "Name",
                    name: "name",
                    placeholder: "Name",
                    value: ""
                },
                code: {
                    label: "Code",
                    name: "code",
                    placeholder: "Code",
                    value: ""
                },
                isRequired: {
                    label: "Is Required",
                    name: "isRequired",
                    placeholder: "Is Required",
                    value: [{value: true, label: "true"}],
                    options: [
                        {value: true, label: "true"},
                        {value: false, label: "false"}
                    ]
                },
                sortingOrder: {
                    label: "Sorting Order",
                    name: "sortingOrder",
                    placeholder: "Sorting Order",
                    value: 0
                }
            }
        }
    },
    UISettings: {}
};
export default PROJECT_ATTRIBUTE;
