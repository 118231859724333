import React, {useState} from "react"
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";
import {ValidatorForm} from "react-material-ui-form-validator";
import {Button} from "@material-ui/core";
import instance, {BASE_URL_BUYER_SELLER} from "../../../../../api/config";

const ChangeCompany = (props)=>{
    console.log(props.selectedIds)
    const [companyValue, setCompanyValue] = useState()

    const saveCompany = ()=>{
        let data = {
            "companyName": companyValue.value,
            "id": props.selectedIds
        }
        instance.post(BASE_URL_BUYER_SELLER+"/seller/api/changeSellerCompanyName", data)
            .then(res => {
                console.log(res.data)
            })
            .catch(error => {
                console.log(error.response.message)
            })
    }


    return (
        <>
            <ValidatorForm

                autoComplete="off"
            >
            <CustomTextValidator
                id={"changeCompany"}
                fullWidth={true}
                validations={true}
                type="text"
                placeholder={"Change Company"}
                name={"Change Company"}
                value={companyValue && companyValue.value}
                onChange={value => {
                    setCompanyValue(value)
                }}
                validators={["required"]}
                errorMessages={["Required"]}
                className="textfield"
            />
            <div style={{paddingTop:"30px", textAlign:"center"}}>
                <Button variant="contained" color="primary" className="submitbtn" type="submit"  onClick={() => saveCompany()}>Save</Button>
            </div>
            </ValidatorForm>
            </>
    )
}

export default ChangeCompany
