import React, { Component } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import instance, { BASE_URL_BUYER } from "../../../../../../api/config";
import { NavLink } from "react-router-dom";
import { Button, Popover } from "@material-ui/core";
import AlertDialog from "../../../../../common/ReduxBased/confirmationDialog/container";
import { Grid } from "@material-ui/core";

import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../../common/ReduxBased/LoadingSpinner/actions";

class ViewSustainabilityForm extends Component {
  state = {
    allDetails: [],
    show: false,
    showDelete: false,
    currentId: "",
    anchorEl: null,
    editData: "",
    shortTermData: [],
    langTermData: [],
  };

  handleClick = (event, dataValue, rowData) => {
    this.setState({
      anchorEl: event.currentTarget,
      currentID: dataValue,
      editData: rowData,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  showAlertDialog = (value, id) => {
    this.setState({
      show: value,
      currentId: id,
    });
  };
  showAlertDialogClose = (value, id) => {
    this.setState({
      showEdit: value,
    });
  };

  showAlertDialogDelete = (value, id) => {
    this.setState({
      showDelete: value,
      currentId: id,
    });
  };

  editHandler = (show, id, current_id, editData) => {
    // this.props.editViewBiddingChangeHandler(id,current_id,editData)
    this.setState({
      showEdit: show,
    });
  };

  deleteProfileHandler = (id) => {
    this.setState({
      showDelete: false,
    });

    const stateCurrentId = this.state.currentId;
    const propsCurrentId = this.props.currentId;
  };

  componentDidMount = () => {
    let data = {
      params: {
        buyerId: this.props.currentId,
      },
    };
    instance
      .get(BASE_URL_BUYER + "/api/get-sustainablility-form", data)
      .then((res) => {
        let shortTerm = [res.data.shortTermReductionTarget].map((item) => {
          return [item.id, item.mainMeasures, item.target, item.targetYear];
        });
        let longTerm = [res.data.longTermReductionTarget].map((item) => {
          return [item.id, item.mainMeasures, item.target, item.targetYear];
        });
        this.setState({
          shortTermData: [...shortTerm],
          langTermData: [...longTerm],
          allDetails: res.data,
        });
      })
      .catch((err) => {});
  };

  viewTimelines = (value) => {};

  showEditViewBiddingDialog = (value) => {
    this.setState({
      showEdit: value,
    });
  };

  render() {
    const { classes, currentId, currentEmail } = this.props;
    const { editData } = this.state;

    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;

    const columns = ["ID", "Main Measures", "Target", "Target Year"];

    const options = {};

    return (
      <>
        {this.state.showDelete && (
          <AlertDialog
            maxWidth={"sm"}
            open={this.state.showDelete}
            showTitle={true}
            title={"Delete Bidding Increment"}
            message={"Are you sure want to delete this bidding Increment?"}
            cancelButtonText={"Close"}
            cancelAlertDialog={() => this.showAlertDialogDelete(false)}
            closeAlertDialog={() => this.showAlertDialogDelete(false)}
            applyAlertDialog={() => this.deleteProfileHandler()}
          />
        )}
        {/* {
                this.state.showEdit &&
                <AlertDialog
                    maxWidth={"sm"}
                    open={this.state.showEdit}
                    showTitle={true}
                    title={"Edit Profile"}
                    message={<EditViewBidding />}
                    cancelButtonText={"Close"}
                    hideApplyButton={true}
                    cancelAlertDialog={() => this.showEditViewBiddingDialog(false)}
                    closeAlertDialog={() => this.showEditViewBiddingDialog(false)}
                    applyAlertDialog={() => this.showEditViewBiddingDialog(false)}

                />
            } */}
        <div className={classes.mainrapper}>
          <div className={classes.fieldswrapper}>
            <p>View all Sustainability Form Data id: {currentId}</p>

            <Grid container>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>{"User ID"}</span>
                  <div className={classes.fieldsdata}>
                    {this.state.allDetails.id}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span> {"Created By"}</span>
                  <div className={classes.fieldsdata}>
                    {this.state.allDetails.createdBy}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>{"Baseline Year"}</span>
                  <div className={classes.fieldsdata}>
                    {this.state.allDetails.baselineYear}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>{"Document Url"}</span>
                  <div className={classes.fieldsdata}>
                    {this.state.allDetails.documentUrl}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span> {"Industry"}</span>
                  <div className={classes.fieldsdata}>
                    {this.state.allDetails.industry}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>{"Membership Network"}</span>
                  <div className={classes.fieldsdata}>
                    {this.state.allDetails.membershipNetwork}
                  </div>
                </div>
              </Grid>
              {this.state.allDetails.industry?.toLowerCase() === "other" && (
                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{"Industry Details"}</span>
                    <div className={classes.fieldsdata}>
                      {this.state.allDetails.otherIndustry}
                    </div>
                  </div>
                </Grid>
              )}
              {this.state.allDetails.membershipNetwork?.toLowerCase() ===
                "other" && (
                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{"Membership Network Details"}</span>
                    <div className={classes.fieldsdata}>
                      {this.state.allDetails.otherMembershipNetwork}
                    </div>
                  </div>
                </Grid>
              )}
            </Grid>
            <br />
            <br />

            <MUIDataTable
              title={"Short Term Reduction Target"}
              data={this.state.shortTermData}
              columns={columns}
              options={options}
            />
            <br />
            <br />

            <MUIDataTable
              title={"Long Term Reduction Target"}
              data={this.state.langTermData}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(ViewSustainabilityForm);
