export const SAVE_ALL_CONTRACTS = "SAVE_ALL_CONTRACTS";

export const saveAllContracts = (value) => (dispatch) => {
  let newArray = value.map((item) => {
    return [item?.id, item?.name, item?.status, item?.address];
  });
  dispatch({
    type: SAVE_ALL_CONTRACTS,
    payload: newArray,
  });
};
