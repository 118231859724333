import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import instance, {BASE_URL_PROJECT} from "../../../../../../api/config";
import { NavLink } from "react-router-dom";
import { Button, Popover } from "@material-ui/core";
import AlertDialog from "../../../../../common/ReduxBased/confirmationDialog/container";

import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../../common/ReduxBased/LoadingSpinner/actions";

class ViewAllProjects extends Component {

    state = {
        allProjects: [],
        show: false,
        showDelete: false,
        currentId: "",
        anchorEl: null,
        editData:""
    }

    handleClick = (event,dataValue,rowData) => {
        this.setState({
            anchorEl: event.currentTarget,
            currentID:dataValue,
            editData:rowData,
        })
    };

    handleClose = () => {
        this.setState({
            anchorEl: null
        })
    }
    
    showAlertDialog = (value, id) => {
        this.setState({
            show: value,
            currentId: id
        })
    }
    showAlertDialogClose = (value, id) => {
        this.setState({
            showEdit: value,
        })
    }

    showAlertDialogDelete = (value, id) => {
        this.setState({
            showDelete: value,
            currentId: id
        })
    }

    editHandler = (show,id,current_id,editData) => {
        // this.props.editViewBiddingChangeHandler(id,current_id,editData)
        this.setState({
            showEdit:show
        })
    }
    

    deleteProfileHandler = (id) => {
        this.setState({
            showDelete: false
        })

        const stateCurrentId = this.state.currentId;
        const propsCurrentId = this.props.currentId;

        // handleLoadingSpinnerOpenWithOptions(true, "Deleting Bidding Profile", "small");
        // instance.delete(BASE_URL_PROJECT+"bidding-increment-profile/bidding-increment/remove/"+stateCurrentId+"/"+propsCurrentId)
        // .then(res => {
        //     handleLoadingSpinnerClose();
        //     window.location.reload();
        // })
        // .catch(err => {
        //     handleLoadingSpinnerClose();
        // }) 
    }

    componentDidMount = () => {
        let data = {
            params: {
                sellerEmail: this.props.currentEmail
        }}
        instance.get(BASE_URL_PROJECT+"/project/onboarding/getAllProjectsBySellerEmail", data)
        .then(res => {
            let newArray = res.data.map((item) => {return [item.id, item.projectName, item.projecType, item.onboardingStatus]})
            this.setState({
                allProjects: [...newArray]
            })
        })
        .catch(err => {

        })
    }

    viewTimelines = (value) => {
        
    }

    showEditViewBiddingDialog = (value) => {
        this.setState({
            showEdit: value
        })
    }

    render() {

        const {
            classes,
            currentId,
            currentEmail
        } = this.props;
        const { editData}= this.state;

        const open = Boolean(this.state.anchorEl);
        const id = open ? 'simple-popover' : undefined;
        
        const columns = ["ID","Project Name", "Project Type","Status",   
        // {
        //     name: "Action",
        //     label: "Action",
        //     options: {
        //         customBodyRender: (props, data) => {
        //             let rowDataValue = data.rowData[0]
        //             // let rowDataViewIDValue = data.rowData[5]
        //             let rowData = data.rowData
        //             return (
        //                 <div>
                            
        //                     <Button aria-describedby={id} variant="contained" color="primary" onClick={(e)=>this.handleClick(e,rowDataValue,rowData)}>
        //                         Actions
        //                     </Button>
        //                     <Popover
        //                         id={id}
        //                         open={open}
        //                         anchorEl={this.state.anchorEl}
        //                         onClose={this.handleClose}
        //                         anchorOrigin={{
        //                         vertical: 'bottom',
        //                         horizontal: 'center',
        //                         }}
        //                         transformOrigin={{
        //                         vertical: 'top',
        //                         horizontal: 'center',
        //                         }}
        //                     >
        //                         <div className={classes.actions}>
        //                             <Button color="primary" variant="outlined" onClick={()=>this.editHandler(true, currentId,this.state.currentID, editData)}>Edit</Button>
        //                             <Button color="primary" variant="outlined" onClick={() => this.showAlertDialogDelete(true, this.state.currentID)}>Delete</Button>
        //                         </div>
        //                     </Popover>
        //                 </div>
        //             )
        //         },
        //     }
        // }
    ];

        const options = {
        };

        return (
            <>

            {
                this.state.showDelete &&
                <AlertDialog
                    maxWidth={"sm"}
                    open={this.state.showDelete}
                    showTitle={true}
                    title={"Delete Bidding Increment"}
                    message={"Are you sure want to delete this bidding Increment?"}
                    cancelButtonText={"Close"}
                    cancelAlertDialog={() => this.showAlertDialogDelete(false)}
                    closeAlertDialog={() => this.showAlertDialogDelete(false)}
                    applyAlertDialog={() => this.deleteProfileHandler()}
                />
            }
            {/* {
                this.state.showEdit &&
                <AlertDialog
                    maxWidth={"sm"}
                    open={this.state.showEdit}
                    showTitle={true}
                    title={"Edit Profile"}
                    message={<EditViewBidding />}
                    cancelButtonText={"Close"}
                    hideApplyButton={true}
                    cancelAlertDialog={() => this.showEditViewBiddingDialog(false)}
                    closeAlertDialog={() => this.showEditViewBiddingDialog(false)}
                    applyAlertDialog={() => this.showEditViewBiddingDialog(false)}

                />
            } */}
                <div className={classes.mainrapper}>
                    <div className={classes.fieldswrapper}>
                        <p>View all Projects id: {currentId}</p>
                        <MUIDataTable 
                            title={"Projects Details"} 
                            data={this.state.allProjects} 
                            columns={columns} 
                            options={options}
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default withStyles(styles)(ViewAllProjects);