import {LOGIN} from "../components/Login/initialState";
import {ALERT_DIALOG} from "../components/common/ReduxBased/confirmationDialog/initialState";
import {LOADING_SPINNER} from "../components/common/ReduxBased/LoadingSpinner/initialState";
import {DASHBOARD} from "../components/OmniConnect/Dashboard/initialState";
import {ACCOUNT_TYPE} from "../components/OmniConnect/Dashboard/AccountType/initialState";
import {PERMISSIONS} from "../components/OmniConnect/Dashboard/Permissions/initialState";
import {ACCOUNT_USER} from "../components/OmniConnect/Dashboard/UserAccount/initialState";
import {ACCOUNTS} from "../components/OmniConnect/Dashboard/Accounts/initialState";
import {LANDING_DASHBOARD} from "../components/OmniConnect/Dashboard/Landing/initialState";
import {SELLER} from "../components/OmniConnect/Dashboard/SellerManagement/initialState";
import {BUYER} from "../components/OmniConnect/Dashboard/BuyerManagement/initialState";
import {PROJECT} from "../components/OmniConnect/Dashboard/ProjectManagement/initialState";
import {PROJECT_TYPE} from "../components/OmniConnect/Dashboard/ProjectTypeManagement/initialState";
import {PROJECT_MAIN_ATTRIBUTE} from "../components/OmniConnect/Dashboard/ProjectMainAttributeManagement/initialState";
import {PROJECT_ATTRIBUTE} from "../components/OmniConnect/Dashboard/ProjectAttributeManagement/initialState";
import {USER_LANGUAGES} from "../components/OmniConnect/Dashboard/Languages/initialState";
import {CREATE_ATTRIBUTE} from "../components/OmniConnect/Dashboard/ProjectManagement/Attributes/initialState"
import {REGISTER} from "../components/Register/initialState";


const initalState = {
    LOGIN: LOGIN,
    REGISTER:REGISTER,
    ALERT_DIALOG: ALERT_DIALOG,
    LOADING_SPINNER: LOADING_SPINNER,
    DASHBOARD: DASHBOARD,
    ACCOUNT_TYPE: ACCOUNT_TYPE,
    ACCOUNT_USER: ACCOUNT_USER,
    ACCOUNTS: ACCOUNTS,
    PERMISSIONS: PERMISSIONS,
    SELLER: SELLER,
    BUYER: BUYER,
    PROJECT: PROJECT,
    PROJECT_TYPE: PROJECT_TYPE,
    PROJECT_MAIN_ATTRIBUTE: PROJECT_MAIN_ATTRIBUTE,
    PROJECT_ATTRIBUTE: PROJECT_ATTRIBUTE,
    CREATE_ATTRIBUTE,
    LANDING_DASHBOARD: LANDING_DASHBOARD,
    USER_LANGUAGES: USER_LANGUAGES
};

export default initalState;
