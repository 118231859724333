import React from "react";
import { useWeb3React } from "@web3-react/core";
import { Contract, ethers, Signer } from "ethers";
import {
  ChangeEvent,
  MouseEvent,
  ReactElement,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import WhitelistArtifact from "../../../artifacts/contracts/Whitelist.sol/Whitelist.json";
import { Provider } from "../utils/provider";
import { SectionDivider } from "./SectionDivider";
import axios from "../../../api/config";
import { Button } from "@material-ui/core";

export const WHITELIST = "Whitelisting";

export default function WhiteListContract(props: any): ReactElement {
  const context = useWeb3React<Provider>();
  const { library, active } = context;

  const [signer, setSigner] = useState<Signer>();
  const [whitelistContract, setWhitelistContract] = useState<Contract>();
  const [whitelist, setWhitelist] = useState<string>("");
  const [whitelistInput, setWhitelistInput] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [whiteListContractAddress, setWhiteListContractAddress] = useState(
    localStorage.getItem("WLCA") || ""
  );
  const [whiteListContractId, setWhiteListContractId] = useState(null);

  useEffect((): void => {
    if (!library) {
      setSigner(undefined);
      return;
    }

    setSigner(library.getSigner());
  }, [library]);

  useEffect((): void => {
    if (!whitelistContract) {
      return;
    }
  }, [whitelistContract, whitelist]);

  const updateContractAddress = async (id: any, address: string) => {
    try {
      const { data: res } = await axios.post("/blockchain/contract-address", {
        contractId: id,
        address,
        isAddressConstant: true,
      });
      return res;
    } catch (ex) {
      throw ex;
    }
  };
  useEffect(() => {
    const postContractInList = async (data: any) => {
      try {
        const { data: res } = await axios.post("/blockchain/contract", data);
        return res;
      } catch (ex) {
        throw ex;
      }
    };
    const getContractsList = async () => {
      try {
        const { data: res } = await axios.get("/blockchain/contract/list");
        return res;
      } catch (ex) {
        throw ex;
      }
    };
    const getContractDetails = async (contractId: any) => {
      try {
        const { data: res } = await axios.get(
          "/blockchain/contract-address?contractId=" + contractId
        );
        return res;
      } catch (ex) {
        throw ex;
      }
    };
    const fetchWhiteListContractAddress = async () => {
      try {
        const contracts = await getContractsList();
        const whiteListContract = contracts.find(
          (f: any) => f.code === WHITELIST
        );
        if (!whiteListContract) {
          // const { id } = await postContractInList({
          //   name: WHITELIST,
          //   code: WHITELIST,
          // });
          // setWhiteListContractId(id);
        } else {
          const contract = await getContractDetails(whiteListContract.id);
          setWhiteListContractAddress(contract.address);
          setWhiteListContractId(contract.id);
        }
      } catch (ex) {
        throw ex;
      }
    };

    try {
      // fetchWhiteListContractAddress();
    } catch (ex: any) {
      console.error("Error in fetching white list contract", ex.message);
    }
  }, []);

  function handleDeployContract(event: MouseEvent<HTMLButtonElement>) {
    // event.preventDefault();
    // only deploy the Greeter contract one time, when a signer is defined
    if (!signer) {
      return;
    }
    async function deployWhitelistContract(signer: Signer): Promise<void> {
      setIsLoading(true);
      const Whitelist = new ethers.ContractFactory(
        WhitelistArtifact.abi,
        WhitelistArtifact.bytecode,
        signer
      );
      try {
        const whitelistContract = await Whitelist.deploy();
        await whitelistContract.deployed();
        setWhitelistContract(whitelistContract);
        window.alert(`Whitelist deployed to: ${whitelistContract.address}`);
        setWhiteListContractAddress(whitelistContract.address);
        localStorage.removeItem("CC__INIT");
        localStorage.setItem("WLCA", whitelistContract.address);
        // await updateContractAddress(
        //   whiteListContractId,
        //   whitelistContract.address
        // );
      } catch (error: any) {
        window.alert(
          "Error!" + (error && error.message ? `\n\n${error.message}` : "")
        );
      }
      setIsLoading(false);
    }
    deployWhitelistContract(signer);
  }

  if (!active) {
    return <></>;
  }

  return (
    <>
      {isLoading && <p>Adding Contract ...</p>}

      <StyledWhitelistDiv>
        <StyledDeployContractButton
          // disabled={!active || whiteListContractAddress ? true : false}
          style={{
            cursor:
              !active || whiteListContractAddress ? "not-allowed" : "pointer",
            borderColor: !active || whiteListContractAddress ? "unset" : "blue",
          }}
          onClick={handleDeployContract}
        >
          Deploy Whitelist Contract
        </StyledDeployContractButton>

        <StyledLabel>
          {whiteListContractAddress ? (
            `White List Contract address: ${whiteListContractAddress}`
          ) : (
            <i>Whitelist contract not deployed yet</i>
          )}
        </StyledLabel>
      </StyledWhitelistDiv>
    </>
  );
}

const StyledDeployContractButton = styled.button`
  width: 180px;
  height: 2rem;
  border-radius: 1rem;
  border-color: blue;
  cursor: pointer;
  place-self: center;
`;

const StyledWhitelistDiv = styled.div``;

const StyledLabel = styled.label`
  font-weight: bold;
  display: block;
  margin-top: 1em;
`;
