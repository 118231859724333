import axios from "axios";

import { getAppStoreValue } from "../components/common/Helpers";

// For Dev
export const BASE_HOST = "gcx-apigateway-dev.i-ways-network.org";

// For Staging
// export const BASE_HOST = "gcx-apigateway-staging.i-ways-network.org";

export const BASE_LOGIN_URL = "https://" + BASE_HOST + "/user/user-management/";
export const BASE_URL = "https://" + BASE_HOST;
export const BASE_URL_USER = "https://" + BASE_HOST + "/user";
export const BASE_URL_INVESTOR = "https://" + BASE_HOST + "/investor";
export const BASE_URL_PROJECT = "https://" + BASE_HOST + "/project";
export const BASE_URL_BUYER = "https://" + BASE_HOST + "/buyer";
export const BASE_URL_SELLER = "https://" + BASE_HOST + "/seller";
export const BASE_URL_BLOCKCHAIN = "https://" + BASE_HOST + "/blockchain";
export const BASE_URL_BUYER_SELLER = BASE_URL;

const headers = {
  "Content-Type": "application/JSON",
  "Access-Control-Allow-Origin": "*",
};

const instance = axios.create({
  baseURL: BASE_URL,
  headers: headers,
});

const axiosWithouAuth = axios.create({ baseURL: BASE_URL, headers: headers });

instance.interceptors.request.use(
  function (request) {
    let token = localStorage.getItem("sessionToken");
    if (!!token) {
      request.headers.Authorization = `Bearer ${token}`;
    } else {
      request.config = { headers: { "Content-Type": "multipart/form-data" } };
    }

    // Do something with response data
    return request;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    try {
      var res = JSON.parse(
        response.request.responseText.replace(
          /("[^"]*"\s*:\s*)(\d{16,})/g,
          '$1"$2"'
        )
      );
      response.data.responseObj = res.responseObj;
    } catch (e) {
      console.log("Error Parsing json", e);
    }

    return response;
  },
  function (e) {
    if (!!e.response) {
      let status = e.response.status;
    }
    return Promise.reject(e);
  }
);

export default instance;
export { axiosWithouAuth };
