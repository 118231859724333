import { connect } from "react-redux";
import CreateUserAccount from "./component";

import {
    textFieldChangeHandler,
    selectFieldChangeHandler,
    saveAccountUserIds,
    setUserAccountErrorHandler
} from "../actions";

import instance, {BASE_URL_USER} from "../../../../../api/config";
import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import {
    checkIfUrlIsAllowed
} from "../../Permissions/actions";

const mapStateToProps = state => ({
    fields: state.ACCOUNT_USER.forms.AccountUserForm.fields,
    userAccountError: state.ACCOUNT_USER.UISettings.userAccountError,
    isAllowed: state.PERMISSIONS.UISettings.isAllowed
})

const mapDispatchToProps = (dispatch, props) => ({
    textFieldChangeHandler: (name, e) => {
        if (typeof e.value !== "string") {
            e.value = "";
        }
        dispatch(textFieldChangeHandler(name, e));
    },
    selectFieldChangeHandler: (name, e) => {
        dispatch(selectFieldChangeHandler(name, e));
    },
    getAccountUserIDs: () => {
        
        instance.get(BASE_URL_USER+'core-account-types/getAccountTypes')
        .then(res => {
            dispatch(saveAccountUserIds(res.data.data))
        })
        .catch(error => {

        })
    },
    submitAccountUser: (FirstName, LastName, AccountName, Email, Password, ConfirmPassword, AccountTypeid, Language, Status, RedirectUrl) => {
        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Saving User Account", "small"));

        let data = {
            firstName: FirstName ? FirstName.value : "",
            lastName: LastName ? LastName.value : "",
            email: Email ? Email.value : "",
            password: Password ? Password.value : "",
            accountTypeId: AccountTypeid.value[0] ? AccountTypeid.value[0].value : "",
            language: Language ? Language.value[0].label : "",
            isActive: Status.value ? Status.value[0].label : 0,
            redirectUrl: RedirectUrl ? RedirectUrl.value : ""
          }
        //   if(data.password.length < 7) {
        //     dispatch(setUserAccountErrorHandler("Password should be more than 8 characters"));
        //         dispatch(handleLoadingSpinnerClose());
                
        //   }
        //   if(data.password !== data.confirmPassword) {
        //     dispatch(setUserAccountErrorHandler("Password does not match"));
        //     dispatch(handleLoadingSpinnerClose());
        // }else {
            dispatch(setUserAccountErrorHandler(""));
            instance.post(BASE_URL_USER+'user-management/addUser', data)
            .then(res => {
                window.location.replace('/user/all-useraccount')
                dispatch(handleLoadingSpinnerClose());
            })
            .catch(error => {
                dispatch(handleLoadingSpinnerClose());
            })
        // }
    },
    checkIfUrlIsAllowed: async () => {
        await dispatch(checkIfUrlIsAllowed('/user-management/addUser'))
    }
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    submitAccountUser: () => {
        dispatchProps.submitAccountUser(
            stateProps.fields.FirstName,
            stateProps.fields.LastName,
            stateProps.fields.AccountName,
            stateProps.fields.Email,
            stateProps.fields.Password,
            stateProps.fields.ConfirmPassword,
            stateProps.fields.AccountTypeid,
            stateProps.fields.Language,
            stateProps.fields.Status,
            stateProps.fields.RedirectUrl
            )
    },
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(CreateUserAccount);