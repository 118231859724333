import { connect } from "react-redux";
import CreateProject from "./component";

import {
    textFieldChangeHandler,
    selectFieldChangeHandler,
    saveAddSellerResponse, saveALLSellerProfiles,
    saveProjectAttributes,
    textMainFieldChangeHandler,
    textAttrFieldChangeHandler
} from "../actions";
import ls from 'local-storage';

import instance, { BASE_URL } from "../../../../../api/config";
import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import {
    checkIfUrlIsAllowed
} from "../../Permissions/actions";

const mapStateToProps = state => ({
    fields: state.PROJECT.forms.projectForm.fields,
    addSellerResponseMsg: state.SELLER.data.addSellerResponseMsg,
    attributes: state.PROJECT.data.attributes,
    mainAttributes: state.PROJECT.data.mainAttributes,
    sellerAddStatus:state.SELLER.data.sellerAddStatus,
    isAllowed: state.PERMISSIONS.UISettings.isAllowed,
})


const mapDispatchToProps = (dispatch, props) => ({
    fetchJsonFields: () => {
        instance.get(BASE_URL+"/project/properties/json")
            .then(res => {
                dispatch(saveProjectAttributes(res.data))
            })
            .catch(err => {

            })
    },

    textFieldChangeHandler: (name, e) => {
        if (typeof e.value !== "string") {
            e.value = "";
        }
        dispatch(textFieldChangeHandler(name, e));
    },
    selectFieldChangeHandler: (name, e) => {
        dispatch(selectFieldChangeHandler(name, e));
    },
    textMainFieldChangeHandler: (name, value) => {
        dispatch(textMainFieldChangeHandler(name, value))
    },

    textAttrFieldChangeHandler: (name, value) => {
        dispatch(textAttrFieldChangeHandler(name, value))
    },
    SubmitHandler: (attributes, mainAttributes) => {

        const updatedAttr = attributes.map(item => {
            return {
                attributeName: item.attributeName,
                attributeType: item.attributeType,
                attributeValue: item.value
            }
        })

        const updatedMainAttr = mainAttributes.map(item => {
            return {
                fieldName: item.fieldName,
                fieldType: item.fieldType,
                fliedValue: item.value
            }
        })
        let data = {
                main: [...updatedMainAttr],
                attributes: [...updatedAttr]
        }

        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Adding Project", "small"));

        instance.post(BASE_URL+'/project/saveMain', data)
        .then(res => {
            window.location.replace('/user/all-projects')
            dispatch(handleLoadingSpinnerClose());
        })
        .catch(error => {
            dispatch(handleLoadingSpinnerClose());
        })
    },
    checkIfUrlIsAllowed: async () => {
        await dispatch(checkIfUrlIsAllowed('projectService'))
    }
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    SubmitHandler: () => {
        dispatchProps.SubmitHandler(
            stateProps.attributes,
            stateProps.mainAttributes
            )
    },
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(CreateProject);
