import BG from "../../../../../../resources/images/landingbg.svg";

export const styles = theme => ({
    mainrapper: {
        background:  `url(${BG})`,
        backgroundSize: "cover",
        padding: "32px 13px",
        width: "100%",
        minHeight: "calc(100vh - 116px)",
        "& .fieldwrapper": {
            padding: "0px 13px",
        },
        "& .textfield": {
            marginTop: 13,
            marginBottom: 22,
            "& div": {
                height: "61px",
            }
        },
        "& .selectfield": {
            "& .react-select__value-container": {
                padding: 7,
            }
        },
        "& .submitbtn": {
            margin: 13,
            height: 61,
            width: 230,
            float: "left",
            marginTop: "30px"
        }
    },
    fieldswrapper: {
        background: "white",
        padding: 38,
        margin: "0px 38px",
        "& p": {
            color: "#707070",
        },
    },
    actions: {
        display: "block",
        padding: 7,
        minWidth: 100,
        "& button": {
            display: "block",
            width: "100%",
            marginBottom: 11
        }
    }
});