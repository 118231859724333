import { connect } from "react-redux";
import CreateBuyer from "./component";

import {
    textFieldChangeHandler,
    selectFieldChangeHandler,
    saveAddSellerResponse
} from "../actions";
import ls from 'local-storage';

import instance, { BASE_URL_BUYER_SELLER, BASE_URL } from "../../../../../api/config";
import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import {
    checkIfUrlIsAllowed
} from "../../Permissions/actions";

const mapStateToProps = state => ({
    fields: state.BUYER.forms.buyerForm.fields,
    addSellerResponseMsg: state.SELLER.data.addSellerResponseMsg,
    sellerAddStatus:state.SELLER.data.sellerAddStatus,
    isAllowed: state.PERMISSIONS.UISettings.isAllowed
})


const mapDispatchToProps = (dispatch, props) => ({
    textFieldChangeHandler: (name, e) => {
        if (typeof e.value !== "string") {
            e.value = "";
        }
        dispatch(textFieldChangeHandler(name, e));
    },
    selectFieldChangeHandler: (name, e) => {
        dispatch(selectFieldChangeHandler(name, e));
    },

    submitSellerProfile: (SellerUsernameCreate,SellerEmailCreate, SellerPasswordCreate,SellerCompanyCreate,SellerCountryCreate,
                          SellerCityCreate,SellerAddress1Create,SellerAddress2Create,SellerPhoneCreate,SellerPinCodeCreate,
                          SellerVatNoCreate) => {
        let data = {
            addressLine1: SellerAddress1Create ? SellerAddress1Create.value : "",
            addressLine2: SellerAddress2Create ? SellerAddress2Create.value : "",
            city: SellerCityCreate ? SellerCityCreate.value : "",
            companyName: SellerCompanyCreate ? SellerCompanyCreate.value : "",
            country: SellerCountryCreate ? SellerCountryCreate.value : "",
            email: SellerEmailCreate ? SellerEmailCreate.value : "",
            name: SellerUsernameCreate ? SellerUsernameCreate.value : "",
            password: SellerPasswordCreate ? SellerPasswordCreate.value : "",
            phonenumber: SellerPhoneCreate ? SellerPhoneCreate.value : "",
            pincode: SellerPinCodeCreate ? SellerPinCodeCreate.value : "",
            vatno: SellerVatNoCreate ? SellerVatNoCreate.value : "",
          }

        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Saving Seller", "small"));
        //todo STAGING, PROTOTYPE, LIVE

        instance.post(BASE_URL+'/buyer/api/register-buyer', data)
        .then(res => {
            window.location.replace('/login')
            dispatch(handleLoadingSpinnerClose());
        })
        .catch(error => {
            console.log('error add seller',error)
            dispatch(handleLoadingSpinnerClose());
        })
    },
    checkIfUrlIsAllowed: async () => {
        await dispatch(checkIfUrlIsAllowed('buyerService'))
    }
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    submitSellerProfile: () => {
        dispatchProps.submitSellerProfile(
            stateProps.fields.SellerUsernameCreate,
            stateProps.fields.SellerEmailCreate,
            stateProps.fields.SellerPasswordCreate,
            stateProps.fields.SellerCompanyCreate,
            stateProps.fields.SellerCountryCreate,
            stateProps.fields.SellerCityCreate,
            stateProps.fields.SellerAddress1Create,
            stateProps.fields.SellerAddress2Create,
            stateProps.fields.SellerPhoneCreate,
            stateProps.fields.SellerPinCodeCreate,
            stateProps.fields.SellerVatNoCreate,
            )
    },
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(CreateBuyer);
