import { connect } from "react-redux";
import CreateBuyer from "./component";

import {
    projectMainAttributeCreateTextFieldChangeHandler,
    getAllDataTypes,
    selectFieldChangeHandler
} from "../actions";

import instance, { BASE_URL } from "../../../../../api/config";

import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../common/ReduxBased/LoadingSpinner/actions";

import {
    checkIfUrlIsAllowed
} from "../../Permissions/actions";

const mapStateToProps = state => ({
    fields: state.PROJECT_MAIN_ATTRIBUTE.forms.projectMainAttributeForm.fields,
    dataTypes: state.PROJECT_MAIN_ATTRIBUTE.data.dataTypes,
    isAllowed: state.PERMISSIONS.UISettings.isAllowed
})


const mapDispatchToProps = (dispatch, props) => ({
    fetchDataIfNeeded: () => {
        instance.get(BASE_URL+"/project/data-types", {
            headers: {
                "Content-Type": "application/json"
            }})
            .then(res => {
                dispatch(getAllDataTypes(res.data))
            })
            .catch(err => {

            })
    },
    textFieldChangeHandler: (name, e) => {
        if (typeof e.value !== "string") {
            e.value = "";
        }
        dispatch(projectMainAttributeCreateTextFieldChangeHandler(name, e.value));
    },

    submitProjectMainAttribute: (dataType, value, isRequired, sortingOrder) => {

        let data = {
            dataType: dataType && dataType.value && dataType.value.length ? dataType.value[0]?.value : "",
            value: value ? value.value : "",
            isRequired: isRequired && isRequired.value && isRequired.value.length ? isRequired.value[0]?.value : false,
            sortingOrder: sortingOrder ? sortingOrder.value : ""
          }

        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Saving Project Main Attribute", "small"));
        //todo STAGING, PROTOTYPE, LIVE

        instance.post(BASE_URL+'/project/main-attribute/create', data)
        .then(res => {
            window.location.replace('/user/project/main-attribute/index')
            dispatch(handleLoadingSpinnerClose());
        })
        .catch(error => {
            console.log('error add project main attribute',error)
            dispatch(handleLoadingSpinnerClose());
        })
    },
    checkIfUrlIsAllowed: async () => {
        await dispatch(checkIfUrlIsAllowed('projectService'))
    },
    selectFieldChangeHandler: (name, e) => {
        dispatch(selectFieldChangeHandler(name, e));
    },
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    submitProjectMainAttribute: () => {
        dispatchProps.submitProjectMainAttribute(
            stateProps.fields.dataType,
            stateProps.fields.value,
            stateProps.fields.isRequired,
            stateProps.fields.sortingOrder
        )
    },
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(CreateBuyer);
