import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../common/ReduxBased/LoadingSpinner/actions";
import ls from 'local-storage'

import instance, { BASE_URL, BASE_LOGIN_URL } from "../../api/config";
import { reverseString } from "./../common/Helpers";
import {BIDDING_PROFILE_PAGE_SELECT_CHANGE_HANDLER} from "../OmniConnect/Dashboard/ProjectManagement/actions";
export const LOGIN_PAGE_TEXT_CHANGE_HANDLER = "LOGIN_PAGE_TEXT_CHANGE_HANDLER";
export const LOGIN_SUBMIT_FORM_HANDLER = "LOGIN_SUBMIT_FORM_HANDLER";
export const LOGIN_PAGE_ERROR_HANDLER = "LOGIN_PAGE_ERROR_HANDLER";

export const textFieldChangeHandler = (name, value) => dispatch => {
    dispatch({
        type: LOGIN_PAGE_TEXT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};

export const selectFieldChangeHandler = (name, value) => dispatch => {
    console.log("asasdsa",name,value)
    dispatch({
        type: BIDDING_PROFILE_PAGE_SELECT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};

export const submitFormHandler = (value) => dispatch => {
    console.log(value)
            if(value.companyType === "SELLER"){
                window.location.replace("/register/seller");
                }
                if(value.companyType === "BUYER"){
                window.location.replace("/register/buyer");
            }
}
