import React, { Component } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import {
  Button,
  Grid,
  Popover,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import AlertDialog from "../../../../common/ReduxBased/confirmationDialog/container";
import EditProject from "../EditProject/container";
import Alert from "@material-ui/lab/Alert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorMessage from "../../common/ErrorMessage/component";
import ls from "local-storage";
import ReactSelectValidator from "../../../../common/ReduxBased/CustomReactSelectValidator/ReactSelectValidator";
import instance, { BASE_URL_BUYER_SELLER } from "../../../../../api/config";
import { handleLoadingSpinnerClose } from "../../../../common/ReduxBased/LoadingSpinner/actions";

toast.configure();

class ViewClosedBiddingByProjectID extends Component {
  state = {
    show: false,
    changeProjectStatus: false,
    showDelete: false,
    currentId: "",
    anchorEl: null,
    resendebayUsername: "",
    resendebayEmail: "",
    selectedTableRows: [],
  };

  showAlertDialog = (value, id) => {
    this.setState({
      show: value,
      currentId: id,
      showEdit: value,
    });
  };

  handleClick = (event, dataValue, rowData) => {
    this.setState({
      anchorEl: event.currentTarget,
      currentId: dataValue,
      editData: rowData,
      resendebayUsername: rowData[3],
      resendebayEmail: rowData[4],
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  showAlertDialogDelete = (value, id) => {
    this.setState({
      showDelete: value,
      currentId: id,
    });
  };

  deleteProfileHandler = () => {
    this.setState({
      showDelete: false,
    });
    this.props.deleteSellerHandler(this.state.currentId);
  };

  editHandler = (show, id, editData) => {
    this.props.editSellerChangeHandler(id, editData);
    this.setState({
      showEdit: show,
    });
  };

  showEditSellerDialog = (value) => {
    this.setState({
      showEdit: value,
    });
  };

  resendInvitationHandler = (username, email) => {
    this.props.resendInvitation(username, email);
  };

  componentDidMount = () => {
    this.props.checkIfUrlIsAllowed();
    this.props.fetchDataIfNeeded();
  };

  selectedIds = [];
  customToolbarSelect = (selectedRows, displayData) => {
    let ids = [];
    selectedRows.data.map((value, index) => {
      let selectedValue = displayData.filter(
        (_, dataIndex) => dataIndex === value.dataIndex
      );
      ids.push(selectedValue[0].data[0]);
    });
    this.selectedIds = ids;
    console.log(this.selectedIds);
  };

  options = {
    filterType: "checkbox",
    customToolbarSelect: this.customToolbarSelect,
  };

  render() {
    const {
      classes,
      allSellersID,
      sellerAddStatus,
      addSellerResponseMsg,
      isAllowed,
      projectName,
      offerPot,
      offerPotPrice,
      totalMinPrice,
    } = this.props;
    const { currentId, editData, resendebayUsername, resendebayEmail } =
      this.state;
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;
    // const columns = ["Project Id","Buyer Id","Amount of Pot","Bid Price Per Pot","Created By", "Created Date"];
    const columns = [
      "Id",
      "Company Name",
      "Buyer",
      "Offered POTs",
      "Offered price/ POTs",
      "Bidding Date and Time",
      "Allocated POTs",
      "End Price /POT",
    ];

    return (
      <>
        {
          <div className={classes.mainrapper}>
            <div className={classes.fieldswrapper}>
              <p>Project Name :{projectName}</p>
              <p>Offered POTs :{offerPot}</p>
              <p>Minimum price per POT :{offerPotPrice}</p>
              <p>Total minumum amount :{totalMinPrice}</p>
              <MUIDataTable
                title={projectName}
                data={allSellersID}
                columns={columns}
                options={this.options}
              />
            </div>
          </div>
        }
      </>
    );
  }
}
export default withStyles(styles)(ViewClosedBiddingByProjectID);
