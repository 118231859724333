export const REGISTER = {
    data: {
        submitLogin: {
            result: {},
            request: {
                required: false,
                url: "login",
                params: {
                    grant_type: "password",
                    scope: "webclient"
                },
                method: "post"
            },
            error: {}
        },
    },
    forms: {
        registerForm: {
            fields: {
                CompanyName: {
                    label: "CompanyName",
                    name: "CompanyName",
                    placeholder: "Company Name",
                    value: ""
                },
                CompanyType: {
                    label: "CompanyType",
                    name: "CompanyType",
                    placeholder: "Company Type",
                    value: "",
                    options: [
                        {value: "SELLER", label: "SELLER"},
                        {value: "BUYER", label: "BUYER"}
                    ]
                }
            }
        }
    },
    UISetting: {
        isLogin: false,
        error: ""
    }
};

export default REGISTER;
