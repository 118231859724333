import React from "react";
import { useWeb3React } from "@web3-react/core";
import { Contract, ethers, Signer } from "ethers";
import {
  ChangeEvent,
  MouseEvent,
  ReactElement,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import CarbonArtifact from "../../../artifacts/contracts/CarbonContract.sol/CarbonContract.json";
import { Provider } from "../utils/provider";
import { SectionDivider } from "./SectionDivider";
import axios from "../../../api/config";
import { WHITELIST } from "./Whitelister";

export const CARBON = "Carbon Credit";

export default function CarbonContract(props: any): ReactElement {
  const isCarbon = props.isCarbon;
  const tanganyAddress = props?.tanganyAddress;
  const context = useWeb3React<Provider>();
  const { library, active } = context;

  const [signer, setSigner] = useState<Signer>();
  const [carbonContract, setCarbonContract] = useState<Contract>();
  const [carbon, setCarbon] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [carbonContractAddress, setCarbonContractAddress] = useState(
    localStorage.getItem("CCA") || ""
  );
  const [whiteListContractId, setCarbonContractId] = useState(null);
  const [whiteListContractAddress, setWhiteListContractAddress] = useState(
    localStorage.getItem("WLCA") || null
  );
  const [values, setValues] = useState({
    uri: "",
    retireAddress: "",
  });

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const initialzeContract = (carbonContract: any) => {
    if (!values.uri || !values.retireAddress) {
      alert("URI and Retire address both are required");
      return false;
    }
    async function initialize() {
      try {
        debugger;
        const Carbon = new ethers.ContractFactory(
          CarbonArtifact.abi,
          CarbonArtifact.bytecode,
          signer
        );
        const contract = Carbon.attach(carbonContractAddress);
        const res = await contract.carbonInitialize(
          localStorage.getItem("WLCA"),
          values.uri,
          values.retireAddress
        );
        if (res) {
          alert("Carbon contract has been initialized successfully");
        }
      } catch (ex: any) {
        console.error(ex);
        alert(ex.message);
      }
    }
    initialize();
  };

  useEffect((): void => {
    if (!library) {
      setSigner(undefined);
      return;
    }

    setSigner(library.getSigner());
  }, [library]);

  useEffect((): void => {
    if (!carbonContract) {
      return;
    }
  }, [carbonContract, carbon]);

  const updateContractAddress = async (id: any, address: string) => {
    try {
      const { data: res } = await axios.post("/blockchain/contract-address", {
        contractId: id,
        address,
        isAddressConstant: true,
      });
      return res;
    } catch (ex) {
      throw ex;
    }
  };
  useEffect(() => {
    const postContractInList = async (data: any) => {
      try {
        const { data: res } = await axios.post("/blockchain/contract", data);
        return res;
      } catch (ex) {
        throw ex;
      }
    };
    const getContractsList = async () => {
      try {
        const { data: res } = await axios.get("/blockchain/contract/list");
        return res;
      } catch (ex) {
        throw ex;
      }
    };
    const getContractDetails = async (contractId: any) => {
      try {
        const { data: res } = await axios.get(
          "/blockchain/contract-address?contractId=" + contractId
        );
        return res;
      } catch (ex) {
        throw ex;
      }
    };
    const fetchCarbonContractAddress = async () => {
      try {
        const contracts = await getContractsList();
        const carbonContract = contracts.find((f: any) => f.code === CARBON);
        const whiteListContract = contracts.find(
          (f: any) => f.code === WHITELIST
        );
        if (!whiteListContract) {
          return false;
        } else {
          const contract = await getContractDetails(whiteListContract.id);
          setWhiteListContractAddress(contract.address);
        }
        if (!carbonContract) {
          const { id } = await postContractInList({
            name: CARBON,
            code: CARBON,
          });
          setCarbonContractId(id);
        } else {
          const contract = await getContractDetails(carbonContract.id);
          // setCarbonContractAddress(contract.address);
          setCarbonContractId(contract.id);
        }
      } catch (ex) {
        throw ex;
      }
    };

    try {
      // fetchCarbonContractAddress();
    } catch (ex: any) {
      console.error("Error in fetching white list contract", ex.message);
    }
  }, []);

  function handleDeployContract(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    // only deploy the Greeter contract one time, when a signer is defined
    if (carbonContract || !signer) {
      return;
    }

    async function deployCarbonContract(signer: Signer): Promise<void> {
      setIsLoading(true);
      const Carbon = new ethers.ContractFactory(
        CarbonArtifact.abi,
        CarbonArtifact.bytecode,
        signer
      );

      try {
        const carbonContract = await Carbon.deploy();
        await carbonContract.deployed();
        setCarbonContract(carbonContract);
        window.alert(`Carbon deployed to: ${carbonContract.address}`);
        setCarbonContractAddress(carbonContract.address);
        localStorage.setItem("CCA", carbonContract.address);
        // await updateContractAddress(
        //   whiteListContractId,
        //   carbonContract.address
        // );
      } catch (error: any) {
        window.alert(
          "Error!" + (error && error.message ? `\n\n${error.message}` : "")
        );
      }
      setIsLoading(false);
    }

    deployCarbonContract(signer);
  }

  if (!whiteListContractAddress || !active) {
    return <></>;
  }

  return (
    <>
      {isLoading && <p>Adding Contract ...</p>}

      <StyledCarbonDiv>
        <StyledDeployContractButton
          // disabled={!active || carbonContractAddress ? true : false}
          style={{
            cursor:
              !active || carbonContractAddress ? "not-allowed" : "pointer",
            borderColor: !active || carbonContractAddress ? "unset" : "blue",
          }}
          onClick={handleDeployContract}
        >
          Deploy Carbon Contract
        </StyledDeployContractButton>
        <StyledLabel>
          {carbonContractAddress ? (
            `Carbon Contract address: ${carbonContractAddress}`
          ) : (
            <i>Carbon contract not deployed yet</i>
          )}
        </StyledLabel>
      </StyledCarbonDiv>
      <div>
        <FormControl>
          <label>URI</label>
          <input onChange={handleChange} name="uri" value={values["uri"]} />
        </FormControl>
        <FormControl>
          <label>Retire Address</label>
          <input
            onChange={handleChange}
            name="retireAddress"
            value={values["retireAddress"]}
          />
        </FormControl>
        <StyledDeployContractButton
          // disabled={!active || carbonContractAddress ? true : false}
          style={{
            cursor:
              !active || carbonContractAddress ? "not-allowed" : "pointer",
            borderColor: !active || carbonContractAddress ? "unset" : "blue",
          }}
          onClick={initialzeContract}
        >
          Initialize Carbon Contract
        </StyledDeployContractButton>
      </div>
    </>
  );
}

const StyledDeployContractButton = styled.button`
  width: 180px;
  height: 2rem;
  border-radius: 1rem;
  border-color: blue;
  cursor: pointer;
  place-self: center;
`;

const StyledCarbonDiv = styled.div`
  margin-top: 2em;
`;

const StyledLabel = styled.label`
  font-weight: bold;
  margin-top: 0.5em;
  display: block;
  margin-bottom: 1em;
`;

const FormControl = styled.div`
  margin-bottom: 16px;
  label {
    min-width: 150px;
    display: inline-block;
  }
  input {
    border: none;
    background: none;
    border: 1px solid black;
    padding: 10px;
    border-radius: 4px;
  }
`;
