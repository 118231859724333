import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";
import { ValidatorForm } from "react-material-ui-form-validator";
import ReactSelectValidator from "../../../../common/ReduxBased/CustomReactSelectValidator/ReactSelectValidator";

class EditProject extends Component {
    SubmitHandler = () => {
        this.props.submitProjectEdit1();
    }

    render() {

        const {
            classes,
            textFieldChangeHandler,
            selectFieldChangeHandlerEdit,
            sellerFields
        } = this.props;

        return (
            <>
                <div className={classes.mainrapper}>
                <div className={classes.fieldswrapper}>
                    <p>Edit Project</p>

                    <ValidatorForm
                        onSubmit={this.SubmitHandler}
                        autoComplete="off"
                    >
                       <Grid container>
                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.ProjectNameCreate.label}</span>
                                   <CustomTextValidator
                                       id={sellerFields.ProjectNameCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={sellerFields.ProjectNameCreate.placeholder}
                                       name={sellerFields.ProjectNameCreate.name}
                                       value={sellerFields.ProjectNameCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               sellerFields.ProjectNameCreate.name,
                                               value
                                           );
                                       }}
                                       validators={["required"]}
                                       errorMessages={["Required"]}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.ProjectProjectDescriptionCreate.label}</span>
                                   <CustomTextValidator
                                       id={sellerFields.ProjectProjectDescriptionCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={sellerFields.ProjectProjectDescriptionCreate.placeholder}
                                       name={sellerFields.ProjectProjectDescriptionCreate.name}
                                       value={sellerFields.ProjectProjectDescriptionCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               sellerFields.ProjectProjectDescriptionCreate.name,
                                               value
                                           );
                                       }}
                                       validators={["required"]}
                                       errorMessages={["Required"]}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.ProjectPublishedStatusCreate.label}</span>
                                   <CustomTextValidator
                                       id={sellerFields.ProjectPublishedStatusCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={sellerFields.ProjectPublishedStatusCreate.placeholder}
                                       name={sellerFields.ProjectPublishedStatusCreate.name}
                                       value={sellerFields.ProjectPublishedStatusCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               sellerFields.ProjectPublishedStatusCreate.name,
                                               value
                                           );
                                       }}
                                       validators={["required"]}
                                       errorMessages={["Required"]}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.ProjectSellerIdCreate.label}</span>
                                   <CustomTextValidator
                                       id={sellerFields.ProjectSellerIdCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="number"
                                       placeholder={sellerFields.ProjectSellerIdCreate.placeholder}
                                       name={sellerFields.ProjectSellerIdCreate.name}
                                       value={sellerFields.ProjectSellerIdCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               sellerFields.ProjectSellerIdCreate.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.ProjectStatusCreate.label}</span>
                                   <CustomTextValidator
                                       id={sellerFields.ProjectStatusCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={sellerFields.ProjectStatusCreate.placeholder}
                                       name={sellerFields.ProjectStatusCreate.name}
                                       value={sellerFields.ProjectStatusCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               sellerFields.ProjectStatusCreate.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.ProjectAmountOfCarbonCreate.label}</span>
                                   <CustomTextValidator
                                       id={sellerFields.ProjectAmountOfCarbonCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="number"
                                       placeholder={sellerFields.ProjectAmountOfCarbonCreate.placeholder}
                                       name={sellerFields.ProjectAmountOfCarbonCreate.name}
                                       value={sellerFields.ProjectAmountOfCarbonCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               sellerFields.ProjectAmountOfCarbonCreate.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.ProjectApprovalStatusCreate.label}</span>
                                   <CustomTextValidator
                                       id={sellerFields.ProjectApprovalStatusCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={sellerFields.ProjectApprovalStatusCreate.placeholder}
                                       name={sellerFields.ProjectApprovalStatusCreate.name}
                                       value={sellerFields.ProjectApprovalStatusCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               sellerFields.ProjectApprovalStatusCreate.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.ProjectAreaInSquareFeetCreate.label}</span>
                                   <CustomTextValidator
                                       id={sellerFields.ProjectAreaInSquareFeetCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="number"
                                       placeholder={sellerFields.ProjectAreaInSquareFeetCreate.placeholder}
                                       name={sellerFields.ProjectAreaInSquareFeetCreate.name}
                                       value={sellerFields.ProjectAreaInSquareFeetCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               sellerFields.ProjectAreaInSquareFeetCreate.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.ProjectBookBuildingStatusCreate.label}</span>
                                   <CustomTextValidator
                                       id={sellerFields.ProjectBookBuildingStatusCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={sellerFields.ProjectBookBuildingStatusCreate.placeholder}
                                       name={sellerFields.ProjectBookBuildingStatusCreate.name}
                                       value={sellerFields.ProjectBookBuildingStatusCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               sellerFields.ProjectBookBuildingStatusCreate.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.ProjectCancelledBySellerCreate.label}</span>
                                   <ReactSelectValidator
                                       id={sellerFields.ProjectCancelledBySellerCreate.name}
                                       name={sellerFields.ProjectCancelledBySellerCreate.name}
                                       value={sellerFields.ProjectCancelledBySellerCreate.value === "true" ? [{value:true, label:"true"}] : sellerFields.ProjectCancelledBySellerCreate.value === "false" ? [{value:false, label:"false"}] : sellerFields.ProjectCancelledBySellerCreate.value}
                                       placeholder={sellerFields.ProjectCancelledBySellerCreate.placeholder}
                                       validations={true}
                                       fullWidth={true}
                                       onChange={value => {
                                           selectFieldChangeHandlerEdit(
                                               sellerFields.ProjectCancelledBySellerCreate.name,
                                               value
                                           );
                                       }}
                                       validators={["required"]}
                                       errorMessages={["Required"]}
                                       options={sellerFields.ProjectCancelledBySellerCreate.options}
                                       className="selectfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.ProjectCutGeneratedAsPredictedCreate.label}</span>
                                   <ReactSelectValidator
                                       id={sellerFields.ProjectCutGeneratedAsPredictedCreate.name}
                                       name={sellerFields.ProjectCutGeneratedAsPredictedCreate.name}
                                       value={sellerFields.ProjectCutGeneratedAsPredictedCreate.value === "true" ? [{value:true, label:"true"}] : sellerFields.ProjectCutGeneratedAsPredictedCreate.value === "false" ? [{value:false, label:"false"}] : sellerFields.ProjectCutGeneratedAsPredictedCreate.value}
                                       placeholder={sellerFields.ProjectCutGeneratedAsPredictedCreate.placeholder}
                                       validations={true}
                                       fullWidth={true}
                                       onChange={value => {
                                           selectFieldChangeHandlerEdit(
                                               sellerFields.ProjectCutGeneratedAsPredictedCreate.name,
                                               value
                                           );
                                       }}
                                       validators={["required"]}
                                       errorMessages={["Required"]}
                                       options={sellerFields.ProjectCutGeneratedAsPredictedCreate.options}
                                       className="selectfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.ProjectGoogleMapCoordinatesCreate.label}</span>
                                   <CustomTextValidator
                                       id={sellerFields.ProjectGoogleMapCoordinatesCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={sellerFields.ProjectGoogleMapCoordinatesCreate.placeholder}
                                       name={sellerFields.ProjectGoogleMapCoordinatesCreate.name}
                                       value={sellerFields.ProjectGoogleMapCoordinatesCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               sellerFields.ProjectGoogleMapCoordinatesCreate.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.ProjectLocationCreate.label}</span>
                                   <CustomTextValidator
                                       id={sellerFields.ProjectLocationCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={sellerFields.ProjectLocationCreate.placeholder}
                                       name={sellerFields.ProjectLocationCreate.name}
                                       value={sellerFields.ProjectLocationCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               sellerFields.ProjectLocationCreate.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.ProjectImgUrlCreate.label}</span>
                                   <CustomTextValidator
                                       id={sellerFields.ProjectImgUrlCreate.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={sellerFields.ProjectImgUrlCreate.placeholder}
                                       name={sellerFields.ProjectImgUrlCreate.name}
                                       value={sellerFields.ProjectImgUrlCreate.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               sellerFields.ProjectImgUrlCreate.name,
                                               value
                                           );
                                       }}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{sellerFields.ProjectPutSoldStatusCreate.label}</span>
                                   <ReactSelectValidator
                                       id={sellerFields.ProjectPutSoldStatusCreate.name}
                                       name={sellerFields.ProjectPutSoldStatusCreate.name}
                                       value={sellerFields.ProjectPutSoldStatusCreate.value === "true" ? [{value:true, label:"true"}] : sellerFields.ProjectPutSoldStatusCreate.value === "false" ? [{value:false, label:"false"}] : sellerFields.ProjectPutSoldStatusCreate.value}
                                       placeholder={sellerFields.ProjectPutSoldStatusCreate.placeholder}
                                       validations={true}
                                       fullWidth={true}
                                       onChange={value => {
                                           selectFieldChangeHandlerEdit(
                                               sellerFields.ProjectPutSoldStatusCreate.name,
                                               value
                                           );
                                       }}
                                       validators={["required"]}
                                       errorMessages={["Required"]}
                                       options={sellerFields.ProjectPutSoldStatusCreate.options}
                                       className="selectfield"
                                   />
                               </div>
                           </Grid>

                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" className="submitbtn" type="submit">Update</Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </div>
            </div>
            </>
        )
    }
}

export default withStyles(styles)(EditProject);
