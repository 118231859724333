import React, {Component} from "react";
import PropTypes from "prop-types";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
    MuiThemeProvider,
    createMuiTheme,
    withStyles
} from "@material-ui/core/styles";
import user_list from "./userSiderbarList";
import {Link} from "react-router-dom";
import Collapse from '@material-ui/core/Collapse';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
// import Theme from "./../../../resources/theme/defaultTheme";
import {styles} from "./styles";
import ls from 'local-storage';
import {getAppStoreValue} from "../Helpers";
import {reverseString} from "../../common/Helpers";
import instance, {BASE_URL_USER} from "../../../api/config";


class Sidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            arrayListFinalPre: []
        };
    }

    handleClick(item) {
        this.setState(prevState => (
            {[item]: !prevState[item]}
        ));
    }

    allowedURLHandler(value) {

        let sidebarAllowedURLs = ls.get('sidebarAllowedURLs') ? ls.get('sidebarAllowedURLs') : [];
        if (sidebarAllowedURLs) {
            return true;
        } else {
            const isAllowed = sidebarAllowedURLs.filter(item => item.actionUrl === value.secretUrl);
            return isAllowed.length ? isAllowed[0].isAllowed : true;
        }

    }

    handler(children) {
        const {classes} = this.props;
        const {state} = this;

        return children.map((subOption) => {
            const isAllow = this.allowedURLHandler(subOption);
            if (!subOption.children) {
                return (
                    isAllow ? <>
                        <div key={subOption.label}>
                            <Link
                                key={subOption.label}
                                to={subOption.url || "/"}
                                className={classes.menuItemLinkStyle}>

                                <ListItem
                                    onClick={() => this.handleClick(subOption.label)}
                                    className={(subOption.isSubMenu ? classes.menuSubListItembg : classes.topmenulink)}
                                    button
                                >

                                    <ListItemIcon className={(subOption.isSubMenu ? classes.menuSubListItemIcon : '')}>
                                        {subOption.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={subOption.label}
                                    />

                                </ListItem>
                            </Link>
                        </div>
                    </> : null
                );
            }
            return (
                <>
                    <div key={subOption.name}>
                        <ListItem
                            onClick={() => this.handleClick(subOption.label)}
                            className={subOption.isFirst ? classes.topmenulinkNew : classes.topmenulink}
                            button
                        >
                            <ListItemIcon>
                                {subOption.icon}
                            </ListItemIcon>
                                <ListItemText
                                    primary={subOption.label}/>
                            {state[subOption.label] ?
                                <ArrowDropUp/> :
                                <ArrowDropDown/>
                            }
                        </ListItem>
                        <Collapse
                            in={state[subOption.label]}
                            timeout="auto"
                            unmountOnExit
                        >
                            {this.handler(subOption.children)}
                        </Collapse>
                    </div>
                </>
            );
        });
    }

    componentDidMount = () => {
        const accountTypeId = localStorage.getItem("user_id");
        instance.get(BASE_URL_USER + `/user/core-account-types/getAllPermissions/${accountTypeId}`)
            .then(res => {
                this.setState({arrayListFinalPre: res.data});
            });
    };

    render() {

        let AclGetEventAlloweddata = ls.get('AclGetEventAlloweddata');
        let AclGetUserAlloweddata = ls.get('AclGetUserAlloweddata');
        let AclGetSellerAlloweddata = ls.get('AclGetSellerAlloweddata');

        const {open, onClose, classes} = this.props;


        const allowedServices = this.state.arrayListFinalPre.filter((item) => item.allowed === true);
        const finalArr = [];
        user_list.map((item, index) => {
            allowedServices.map((item1, index) => {
                if (item.appName && item.appName === item1.appName) {
                    finalArr.push(item);
                }
            });
        });

        const list = user_list;

        const newEventList = list.filter(eventValue => "Event Management" !== eventValue.label);
        const newUserList = list.filter(userValue => "User Management" !== userValue.label);
        const newSellerList = list.filter(sellerValue => "Seller Management" !== sellerValue.label);

        const ConditionEventList = AclGetEventAlloweddata === false ? newEventList : list;
        const ConditionUserList = AclGetUserAlloweddata === false ? newUserList : list;
        const ConditionSellerList = AclGetSellerAlloweddata === false ? newSellerList : list;

        const arrayList1 = ConditionEventList.filter(x => ConditionUserList.includes(x));
        let arrayListFinal = arrayList1.filter(x => ConditionSellerList.includes(x));

        // const list = user_list;
        const theme = createMuiTheme({
            overrides: {
                MuiPaper: {
                    root: {
                        backgroundColor: "#180940",
                        color: "white"
                    }
                },
                MuiSvgIcon: {
                    root: {
                        color: "white"
                    }
                },
                MuiList: {
                    padding: {
                        paddingTop: 0
                    }
                },
                MuiListItemIcon: {
                    root: {
                        minWidth: 35
                    }
                },
                MuiListItem: {
                    button: {
                        borderBottom: "#180940 1px solid"
                    }
                }
            }
        });
        return (
            <MuiThemeProvider theme={theme}>
                <Drawer
                    // className={classes.modal}
                    // className={{ root: classes.MuiPaperRoot }}
                    variant="temporary"
                    anchor="left"
                    elevation={0}
                    BackdropProps={{invisible: true}}
                    onBackdropClick={onClose}
                    classes={{
                        root: classes.MuiDrawerRootZIndex,
                        paperAnchorLeft: classes.setWidth
                    }}
                    open={open}>
                    <List
                        // className={`${classes.MuiListPadding} ${classes.MuiPaperRoot}`}
                    >
                        {this.handler(arrayListFinal)}

                    </List>
                </Drawer>
            </MuiThemeProvider>

        );
    }

}

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Sidebar);
