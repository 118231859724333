export const GET_ALL_PROJECT_ATTRIBUTES = 'GET_ALL_PROJECT_ATTRIBUTES';
export const PROJECT_ATTRIBUTE_TEXT_CHANGE_HANDLER = 'PROJECT_ATTRIBUTE_TEXT_CHANGE_HANDLER';
export const GET_ALL_DATA_TYPES = 'GET_ALL_DATA_TYPES';
export const PROJECT_ATTRIBUTE_SELECT_CHANGE_HANDLER = 'PROJECT_ATTRIBUTE_SELECT_CHANGE_HANDLER';

export const getAllProjectAttributes = (data) => dispatch => {
    dispatch(
        {
            type: GET_ALL_PROJECT_ATTRIBUTES,
            payload: data
        }
    );
};

export const getAllDataTypes = (data) => dispatch => {
    dispatch(
        {
            type: GET_ALL_DATA_TYPES,
            payload: data
        }
    );
};

export const selectFieldChangeHandler = (name, value) => dispatch => {
    dispatch({
        type: PROJECT_ATTRIBUTE_SELECT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};

export const editProjectAttributeChangeHandler = (value, name) => dispatch => {
    dispatch({
        type: PROJECT_ATTRIBUTE_TEXT_CHANGE_HANDLER,
        payload: name[1],
        key: 'name'
    });
    dispatch({
        type: PROJECT_ATTRIBUTE_TEXT_CHANGE_HANDLER,
        payload: name[2],
        key: 'code'
    });
    dispatch({
        type: PROJECT_ATTRIBUTE_SELECT_CHANGE_HANDLER,
        payload: {value: name[3], label: name[3]},
        key: 'dataType'
    });
    dispatch({
        type: PROJECT_ATTRIBUTE_TEXT_CHANGE_HANDLER,
        payload: {value: name[4] === "true", label: name[4]},
        key: 'isRequired'
    });
    dispatch({
        type: PROJECT_ATTRIBUTE_TEXT_CHANGE_HANDLER,
        payload: name[5],
        key: 'sortingOrder'
    });
};

export const projectAttributeEditTextFieldChangeHandler = (name, value) => dispatch => {
    dispatch({
        type: PROJECT_ATTRIBUTE_TEXT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};

export const projectAttributeCreateTextFieldChangeHandler = (name, value) => dispatch => {
    dispatch({
        type: PROJECT_ATTRIBUTE_TEXT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};
