import { connect } from "react-redux";
import AddNewPermissions from "./component";
import instance, {BASE_URL} from "../../../../../../api/config";
import {
    textFieldChangeHandler_Permissions,
    selectFieldChangeHandler,
    saveRolesDataId,
    saveAppsData
} from "../../actions";

import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../../common/ReduxBased/LoadingSpinner/actions";

const mapStateToProps = state => ({
    fields: state.PERMISSIONS.forms.Permissions.fields,
})

const mapDispatchToProps = (dispatch, props) => ({
    fetchDataIfNeeded: () => {

        instance.get(BASE_URL+'/user/core-account-types/getAccountTypes')
        .then(res => {
            dispatch(saveRolesDataId(res.data.data))
        })
        .catch(error => {
        })

        //get all apps
        instance.get(BASE_URL+'/user/apps/getAllApps')
        .then(res => {
            dispatch(saveAppsData(res.data.data))
        })
        .catch(error => {
        })
    },
    textFieldChangeHandler: (name, e) => {
        if (typeof e.value !== "string") {
            e.value = "";
        }
        dispatch(textFieldChangeHandler_Permissions(name, e));
    },
    selectFieldChangeHandler: (name, e) => {
        dispatch(selectFieldChangeHandler(name, e));
    },
    SubmitHandler: (AccountType, AppName) => {

        let data = {
            accountTypeId: AccountType.length > 0 ? AccountType[0].value : "",
            appName: AppName.length > 0 ? AppName[0].value : ""
          }
        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Adding Permission...", "small"));

        instance.post(BASE_URL+'/user/apps/getAppActions?accountTypeId='+parseInt(data.accountTypeId)+'&appName='+data.appName, data)
        .then(res => {
            window.location.replace('/user/permissions-all')
            dispatch(handleLoadingSpinnerClose());
        })
        .catch(error => {
            dispatch(handleLoadingSpinnerClose());
        })
    }
})


const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...ownProps,
    ...dispatchProps,
    SubmitHandler: () => {
        dispatchProps.SubmitHandler(
            stateProps.fields.AccountType.value,
            stateProps.fields.AppName.value,
            )
    },
    ...ownProps,
})
export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(AddNewPermissions);
