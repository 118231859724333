import { connect } from "react-redux";
import ViewAllAccounts from "./component";
import instance, {BASE_URL_USER} from "../../../../../api/config";
import {
    saveAllAccounts,
    editAccountChangeHandler
} from "../actions";

import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import {
    checkIfUrlIsAllowed
} from "../../Permissions/actions";

const mapStateToProps = state => ({
    allProfiles: state.ACCOUNTS.data.allAccountsProfiles,
    isAllowed: state.PERMISSIONS.UISettings.isAllowed
   
})

const mapDispatchToProps = (dispatch, props) => ({
    fetchDataIfNeeded: (id) => {
        instance.get(BASE_URL_USER+"account-management/getAllAccounts")
        .then(res => {
            dispatch(saveAllAccounts(res.data.data))
        })
        .catch(err => {

        })
    },
    deleteProfileHandler: (id) => {
        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Deleting Account", "small"));
        instance.delete(BASE_URL_USER+"account-management/deleteAccount/"+id)
        .then(res => {
            dispatch(handleLoadingSpinnerClose());
            window.location.reload();
        })
        .catch(err => {
            dispatch(handleLoadingSpinnerClose());
        })
    },
    editAccountChangeHandler: (value,name) => {
        dispatch(editAccountChangeHandler(value,name));
    },
    checkIfUrlIsAllowed: async () => {
        await dispatch(checkIfUrlIsAllowed('/account-management/getAllAccounts'))
    }
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ViewAllAccounts);