export const PROJECT_TYPE = {
    data: {
        projectTypes: []
    },
    forms: {
        projectTypeForm: {
            fields: {
                ProjectTypeName: {
                    label: "Project Type Name",
                    name: "ProjectTypeName",
                    placeholder: "Project Type Name",
                    value: ""
                },
                ProjectTypeCode: {
                    label: "Project Type Code",
                    name: "ProjectTypeCode",
                    placeholder: "Project Type Code",
                    value: ""
                }
            }
        },

        projectTypeFormEdit: {
            fields: {
                ProjectTypeName: {
                    label: "Project Type Name",
                    name: "ProjectTypeName",
                    placeholder: "Project Type Name",
                    value: ""
                },
                ProjectTypeCode: {
                    label: "Project Type Code",
                    name: "ProjectTypeCode",
                    placeholder: "Project Type Code",
                    value: ""
                }
            }
        }
    },
    UISettings: {}
};
export default PROJECT_TYPE;
