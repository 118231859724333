import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { NavLink } from "react-router-dom";
import { Button, Popover } from "@material-ui/core";
import AlertDialog from "../../../common/ReduxBased/confirmationDialog/container";
class AllLanguages extends Component {

    state = {
        show: false,
        showDelete: false,
        currentId: "",
        anchorEl: null,
        editData:''
    }

    handleClick = (event,dataValue,rowData) => {
        this.setState({
            anchorEl: event.currentTarget,
            currentId:dataValue,
            editData:rowData
        })
    };

    handleClose = () => {
        this.setState({
            anchorEl: null
        })
    }

    showAlertDialog = (value, id) => {
        this.setState({
            show: value,
            currentId: id
        })
    }

    showAlertDialogDelete = (value, id) => {
        this.setState({
            showDelete: value,
            currentId: id
        })
    }

    deleteProfileHandler = () => {
        this.setState({
            showDelete: false
        })
        this.props.deleteProfileHandler(this.state.currentId)
    }

    componentDidMount = () => {
        this.props.fetchDataIfNeeded();
    }

    render() {

        const {
            classes,
            langauges
        } = this.props;

        const {currentId} = this.state;

        const open = Boolean(this.state.anchorEl);
        const id = open ? 'simple-popover' : undefined;

        const columns = ["ID", "Language Name", "Language Code",
        {
            name: "Action",
            label: "Action",
            options: {
                customBodyRender: (props, data) => {
                    let rowDataValue = data.rowData[0];
                    let rowData = data.rowData
                    return (
                        <div>

                            <Button aria-describedby={id} variant="contained" color="primary" onClick={(e)=>this.handleClick(e,rowDataValue,rowData)}>
                                Actions
                            </Button>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={this.state.anchorEl}
                                onClose={this.handleClose}
                                anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                                }}
                                transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                                }}
                            >
                                <div className={classes.actions}>
                                {/* <Button color="primary" variant="outlined" onClick={()=>this.editHandler(true, currentId, editData)}>Edit</Button> */}
                                <Button color="primary" variant="outlined" onClick={() => this.showAlertDialogDelete(true, currentId)}>Delete</Button>

                                </div>
                            </Popover>
                        </div>
                    )
                },
            }
        }
    ];

        const options = {
        };

        return (
            <>
            {
                this.state.showDelete &&
                <AlertDialog
                    maxWidth={"sm"}
                    open={this.state.showDelete}
                    showTitle={true}
                    title={"Delete Event"}
                    message={"Are you sure want to delete this Event?"}
                    cancelButtonText={"Close"}
                    cancelAlertDialog={() => this.showAlertDialogDelete(false)}
                    closeAlertDialog={() => this.showAlertDialogDelete(false)}
                    applyAlertDialog={() => this.deleteProfileHandler()}
                />
            }

                <div className={classes.mainrapper}>
                    <div className={classes.fieldswrapper}>
                    <div>
                            <NavLink
                                to={'/user/create-language'}
                                className={classes.createlink}
                            >
                                Add Language
                            </NavLink>
                        </div>

                        <p>View all Languages</p>
                        <MUIDataTable
                            title={"Languages"}
                            data={langauges}
                            columns={columns}
                            options={options}
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default withStyles(styles)(AllLanguages);
