import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import ReactSelectValidator from "../../../../../common/ReduxBased/CustomReactSelectValidator/ReactSelectValidator";
class AddNewPermissions extends Component {
    
    componentDidMount = () => {
        this.props.fetchDataIfNeeded()
    }

    SubmitHandler = () => {
        this.props.SubmitHandler();
    }
    render() {

        const {
            classes,
            selectFieldChangeHandler,
            fields,
        } = this.props;
        return (
            <>
                <div className={classes.mainrapper}>
                <div className={classes.fieldswrapper}>
                    <p>Add New Permissions</p>

                    <ValidatorForm
                        onSubmit={this.SubmitHandler}
                        autoComplete="off"
                    >
                       <Grid container>

                            <Grid item xs={12} md={4}>
                               <div className="fieldwrapper">
                                <span>{fields.AccountType.label}</span>
                                <ReactSelectValidator
                                    id={fields.AccountType.name}
                                    name={fields.AccountType.name}
                                    value={fields.AccountType.value}
                                    placeholder={fields.AccountType.placeholder}
                                    validations={true}
                                    fullWidth={true}
                                    onChange={value => {
                                        selectFieldChangeHandler(
                                            fields.AccountType.name,
                                            value
                                        );
                                    }}
                                    validators={["required"]}
                                    errorMessages={["Required"]}
                                    isSearchable={true}
                                    options={fields.AccountType.options}
                                    className="selectfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={4}>
                               <div className="fieldwrapper">
                                <span>{fields.AppName.label}</span>
                                <ReactSelectValidator
                                    id={fields.AppName.name}
                                    name={fields.AppName.name}
                                    value={fields.AppName.value}
                                    placeholder={fields.AppName.placeholder}
                                    validations={true}
                                    fullWidth={true}
                                    onChange={value => {
                                        selectFieldChangeHandler(
                                            fields.AppName.name,
                                            value
                                        );
                                    }}
                                    validators={["required"]}
                                    errorMessages={["Required"]}
                                    isSearchable={true}
                                    options={fields.AppName.options}
                                    className="selectfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" className="submitbtn" type="submit">Submit</Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>

                </div>
            </div>
            </>

            
        )
    }


    
}
export default withStyles(styles)(AddNewPermissions);