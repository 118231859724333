export const styles = (theme) => ({
  mainrapper: {
    width: "100%",
    "& table": `{
      td:has(span[class*="badge"]) {
        display: 'none'
      }
    }`,
  },
  fieldswrapper: {
    background: "white",
    padding: 38,
    margin: "0px 38px",
    "& p": {
      color: "#707070",
    },
    "& td": {
      wordBreak: "break-all",
    },
  },
  badge: {
    padding: "3px 7px",
    borderRadius: "4px",
    backgroundColor: "#d4edda",
    color: "#155724",
    fontSize: "14px",
    fontWeight: 600,
  },
  createlink: {
    background: "#074880",
    color: "white",
    textDecoration: "none",
    padding: 8,
    marginBottom: 13,
    display: "block",
    width: 178,
    textAlign: "center",
  },
});
