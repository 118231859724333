import {connect} from "react-redux";
import Header from "./component";
// import {reverseString} from "../../../../common/Helpers";
// import ls from 'local-storage';
// import instance, {BASE_URL, BASE_URL_USER} from "../../../../../api/config";


const mapStateToProps = state => ({
});

const mapDispatchToProps = (dispatch, props) => ({
    logOut: (historyObject) => {
        localStorage.clear()
        window.location.replace("/login");
        /*
        instance.post(BASE_URL_USER+'user-management/logout')
        .then(res => {
            if(res) {
            localStorage.removeItem(reverseString("app_store"));
            ls.clear();
            window.location.replace("/login");
            }
        })
         */
    },
    fetchDataIfNeeded: async () => {
    }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(Header);
