import { connect } from "react-redux";
import CreateBuyer from "./component";

import {
    projectTypeCreateTextFieldChangeHandler,
} from "../actions";

import instance, { BASE_URL } from "../../../../../api/config";
import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import {
    checkIfUrlIsAllowed
} from "../../Permissions/actions";

const mapStateToProps = state => ({
    fields: state.PROJECT_TYPE.forms.projectTypeForm.fields,
    isAllowed: state.PERMISSIONS.UISettings.isAllowed
})


const mapDispatchToProps = (dispatch, props) => ({
    textFieldChangeHandler: (name, e) => {
        if (typeof e.value !== "string") {
            e.value = "";
        }
        dispatch(projectTypeCreateTextFieldChangeHandler(name, e.value));
    },

    submitProjectType: (ProjectTypeName, ProjectTypeCode) => {

        let data = {
            name: ProjectTypeName ? ProjectTypeName.value : "",
            code: ProjectTypeCode ? ProjectTypeCode.value : "",
            isDeleted: false
          }

        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Saving Project Type", "small"));
        //todo STAGING, PROTOTYPE, LIVE

        instance.post(BASE_URL+'/project/project-type/create', data)
        .then(res => {
            window.location.replace('/user/project/project-type/index')
            dispatch(handleLoadingSpinnerClose());
        })
        .catch(error => {
            console.log('error add seller',error)
            dispatch(handleLoadingSpinnerClose());
        })
    },
    checkIfUrlIsAllowed: async () => {
        await dispatch(checkIfUrlIsAllowed('projectService'))
    }
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    submitProjectType: () => {
        dispatchProps.submitProjectType(
            stateProps.fields.ProjectTypeName,
            stateProps.fields.ProjectTypeCode
        )
    },
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(CreateBuyer);
