import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";
import ReactSelectValidator from "../../../../common/ReduxBased/CustomReactSelectValidator/ReactSelectValidator";
import { ValidatorForm } from "react-material-ui-form-validator";
import ErrorMessage from "../../common/ErrorMessage/component";
import ls from 'local-storage';
import CreateAttribute from "../Attributes/CreateAttribute/container"
import AlertDialog from "../../../../common/ReduxBased/confirmationDialog/container";
class CreateProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreateAttributePop : false
        };
    }

    componentDidMount = () => {
        this.props.checkIfUrlIsAllowed();
        this.props.fetchJsonFields();
    }

    SubmitHandler = () => {
        this.props.SubmitHandler();
    }
    createNewAttribute = () =>{
        this.setState({showCreateAttributePop : !this.state.showCreateAttributePop})
    }

    closePopup = () =>{
        console.log("sadsadas")
        this.setState({showCreateAttributePop : !this.state.showCreateAttributePop})
    }

    render() {
        const {
            classes,
            attributes,
            mainAttributes,
            addSellerResponseMsg,
            sellerAddStatus,
            textMainFieldChangeHandler,
            textAttrFieldChangeHandler,
            isAllowed
        } = this.props;

        return (
            isAllowed === 'allowed' ? <>
                {
                    <div className={classes.mainrapper}>
                        <div className={classes.fieldswrapper}>
                            <p>Add a New Project</p>

                            <ValidatorForm
                                onSubmit={this.SubmitHandler}
                                autoComplete="off"
                            >
                                <Grid container>

                                    <p className={classes.wrapperTitle}>Main</p>
                                    {
                                        mainAttributes.map((item, index) => {
                                            return (
                                                <Grid item xs={12} md={6} key={index}>
                                                    <div className="fieldwrapper">
                                                        <span>{item.fieldName}</span>
                                                        <CustomTextValidator
                                                            id={item.fieldName}
                                                            name={item.fieldName}
                                                            value={item.value}
                                                            validations={true}
                                                            fullWidth={true}
                                                            onChange={value => {
                                                                textMainFieldChangeHandler(
                                                                    item.fieldName,
                                                                    value
                                                                );
                                                            }}
                                                            type={item.fieldType}
                                                            className="selectfield"
                                                        />
                                                    </div>
                                                </Grid>
                                            )
                                        })
                                    }

                                    <p className={classes.wrapperTitle}>Attributes</p>
                                    {
                                        attributes.map((item, index) => {
                                            return (
                                                <Grid item xs={12} md={6} key={index}>
                                                    <div className="fieldwrapper">
                                                        <span>{item.attributeName}</span>
                                                        <CustomTextValidator
                                                            id={item.attributeName}
                                                            name={item.attributeName}
                                                            value={item.value}
                                                            validations={true}
                                                            fullWidth={true}
                                                            onChange={value => {
                                                                textAttrFieldChangeHandler(
                                                                    item.attributeName,
                                                                    value
                                                                );
                                                            }}
                                                            type={item.attributeType}
                                                            className="selectfield"
                                                        />
                                                    </div>
                                                </Grid>
                                            )
                                        })
                                    }
                                    <Grid item xs={12}>
                                        <Button variant="contained" color="primary" className="submitbtn" type="Button"
                                                onClick={() => this.createNewAttribute()}>Create Attribute</Button>
                                        {addSellerResponseMsg ?
                                            <Button variant="contained" color="primary" className="submitbtn"
                                                    type="submit" disabled>Add</Button>
                                            :
                                            <Button variant="contained" color="primary" className="submitbtn"
                                                    type="submit">Add</Button>
                                        }

                                    </Grid>
                                </Grid>
                                {addSellerResponseMsg ? (
                                        <p className={classes.emailsentseller}>{sellerAddStatus}</p>
                                    )
                                    :
                                    null
                                }
                            </ValidatorForm>
                        </div>

                        {
                            this.state.showCreateAttributePop &&
                            <AlertDialog
                                maxWidth={"sm"}
                                open={this.state.showCreateAttributePop}
                                showTitle={true}
                                title={"Edit Account"}
                                message={<CreateAttribute/>}
                                cancelButtonText={"Close"}
                                hideApplyButton={true}
                                cancelAlertDialog={() => this.closePopup()}
                            />
                        }
                    </div>
                }
                    </>: <> {
                    isAllowed === 'loading' ? <ErrorMessage message='loading' /> : <ErrorMessage message='You are not allowed to see this page' />
                }</>
        )
    }
}

export default withStyles(styles)(CreateProject);
