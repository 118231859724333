import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
// import ViewAllIncrements from "./AllIncrements/container";
import AlertDialog from "../../../../common/ReduxBased/confirmationDialog/container";
import ls from 'local-storage';

class ViewSeller extends Component {

    state = {
        show: false,
        showDelete: false,
        currentId: "",
        anchorEl: null
    }

    showAlertDialog = (value, id) => {
        this.setState({
            show: value,
            currentId: id
        })
    }

    handleClick = (event,dataValue,rowData) => {
        this.setState({
            anchorEl: event.currentTarget,
            currentId:dataValue,
            editData:rowData
        })
    };

    handleClose = () => {
        this.setState({
            anchorEl: null
        })
    }

    showAlertDialogDelete = (value, id) => {
        this.setState({
            showDelete: value,
            currentId: id
        })
    }

    deleteProfileHandler = () => {
        this.setState({
            showDelete: false
        })
        this.props.deleteSellerHandler(this.state.currentId)
    }

    componentDidMount = () => {
        {
            // let queryParams = this.props.location.search
            const username = new URLSearchParams(this.props.location.search).get("username")

            console.log(username, "username")
            ls.set('username', username)
        }
        this.props.fetchToken();
    }

    render() {

        const {
            classes,
            fetchTokenStatus
        } = this.props;

        return (
            <>

            {
                this.state.showDelete &&
                <AlertDialog
                    maxWidth={"sm"}
                    open={this.state.showDelete}
                    showTitle={true}
                    title={"Delete Seller"}
                    message={"Are you sure want to delete this seller?"}
                    cancelButtonText={"Close"}
                    cancelAlertDialog={() => this.showAlertDialogDelete(false)}
                    closeAlertDialog={() => this.showAlertDialogDelete(false)}
                    applyAlertDialog={() => this.deleteProfileHandler()}
                />
            }

                <div className={classes.mainrapper}>
                    <div className={classes.fieldswrapperseller}>
                        <p >{fetchTokenStatus}</p>
                        <Button color="default" variant="contained" onClick={() => window.location.href="/user/view-allseller"}>Go to View All Seller List</Button>
                    </div>
                </div>
            </>
        )
    }
}
export default withStyles(styles)(ViewSeller);
