import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import instance, {BASE_URL_PROJECT} from "../../../../../../api/config";
import { NavLink } from "react-router-dom";
import { Button, Popover } from "@material-ui/core";
import AlertDialog from "../../../../../common/ReduxBased/confirmationDialog/container";
import { Grid } from "@material-ui/core";

import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../../common/ReduxBased/LoadingSpinner/actions";

class ViewProjectDetails extends Component {

    state = {
        allDetails: [],
        show: false,
        showDelete: false,
        currentId: "",
        anchorEl: null,
        editData:"",
        creditSaleDetail: [],
        impactDimension: [],
        legalDocuments: [],
        mediaFiles: [],
        projectLocation: [],
        sustainableDevelopmentGoal: [],
    }

    handleClick = (event,dataValue,rowData) => {
        this.setState({
            anchorEl: event.currentTarget,
            currentID:dataValue,
            editData:rowData,
        })
    };

    handleClose = () => {
        this.setState({
            anchorEl: null
        })
    }
    
    showAlertDialog = (value, id) => {
        this.setState({
            show: value,
            currentId: id
        })
    }
    showAlertDialogClose = (value, id) => {
        this.setState({
            showEdit: value,
        })
    }

    showAlertDialogDelete = (value, id) => {
        this.setState({
            showDelete: value,
            currentId: id
        })
    }

    editHandler = (show,id,current_id,editData) => {
        // this.props.editViewBiddingChangeHandler(id,current_id,editData)
        this.setState({
            showEdit:show
        })
    }
    

    deleteProfileHandler = (id) => {
        this.setState({
            showDelete: false
        })

        const stateCurrentId = this.state.currentId;
        const propsCurrentId = this.props.currentId;
    }

    componentDidMount = () => {
        let data = {
            params: {
                projectId: this.props.currentId
        }}
        instance.get(BASE_URL_PROJECT+"/project/onboarding/findProjectById", data)
        .then(res => {
            let creditSaleDetail = res.data.creditSaleDetail.map((item) => {return [item.vcsSerialNumber, item.pricePerCredit, item.quantity, item.vintage]})
            let impactDimension = res.data.impactDimension.map((item) => {return [item.imageUrl, item.detail, item.score]})
            let legalDocuments = res.data.legalDocuments.map((item) => {return [item.fileUrl]})
            let mediaFiles = res.data.mediaFiles.map((item) => {return [item.fileUrl]})
            let projectLocation = [res.data.projectLocation].map((item) => {return [item.addressLine1, item.addressLine2, item.city, item.country, item.zipCode]})
            let sustainableDevelopmentGoal = res.data.sustainableDevelopmentGoal.map((item) => {return [item.cobenefit, item.moreDetail]})
            this.setState({
                creditSaleDetail: [...creditSaleDetail],
                impactDimension: [...impactDimension],
                legalDocuments: [...legalDocuments],
                mediaFiles: [...mediaFiles],
                projectLocation: [...projectLocation],
                sustainableDevelopmentGoal: [...sustainableDevelopmentGoal],
                allDetails: res.data,
            })
        })
        .catch(err => {

        })
    }

    viewTimelines = (value) => {
        
    }

    showEditViewBiddingDialog = (value) => {
        this.setState({
            showEdit: value
        })
    }

    render() {

        const {
            classes,
            currentId,
            currentEmail
        } = this.props;
        const { editData}= this.state;

        const open = Boolean(this.state.anchorEl);
        const id = open ? 'simple-popover' : undefined;
        
        // const columns = ["ID","Created By", "Main Measures","Target", "Target Year", "Date"];

        const saleDetailsColumns = ["Serial Number","Price Per Credit", "quantity","vintage"];
        const impactDimensionColumns = ["Image Url","Detail", "Score"];
        const legalDocumentsColumns = ["File Url"];
        const mediaFilesColumns = ["File Url"];
        const projectLocationColumns = ["Address 1", "Address 2", "City", "Country", "Zip Code"];
        const sustainableDevelopmentGoalColumns = ["Cobene Fit", "More Detail"];


        const options = {
        };

        return (
            <>

            {
                this.state.showDelete &&
                <AlertDialog
                    maxWidth={"sm"}
                    open={this.state.showDelete}
                    showTitle={true}
                    title={"Delete Bidding Increment"}
                    message={"Are you sure want to delete this bidding Increment?"}
                    cancelButtonText={"Close"}
                    cancelAlertDialog={() => this.showAlertDialogDelete(false)}
                    closeAlertDialog={() => this.showAlertDialogDelete(false)}
                    applyAlertDialog={() => this.deleteProfileHandler()}
                />
            }
            {/* {
                this.state.showEdit &&
                <AlertDialog
                    maxWidth={"sm"}
                    open={this.state.showEdit}
                    showTitle={true}
                    title={"Edit Profile"}
                    message={<EditViewBidding />}
                    cancelButtonText={"Close"}
                    hideApplyButton={true}
                    cancelAlertDialog={() => this.showEditViewBiddingDialog(false)}
                    closeAlertDialog={() => this.showEditViewBiddingDialog(false)}
                    applyAlertDialog={() => this.showEditViewBiddingDialog(false)}

                />
            } */}
                <div className={classes.mainrapper}>
                    <div className={classes.fieldswrapper}>
                        <p>View all Project Details  id: {currentId}</p>

                        <Grid container>
                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{ "Project ID" }</span>
                                <div className={classes.fieldsdata}>{this.state.allDetails.id}</div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span> { "Project Name" }</span>
                                <div className={classes.fieldsdata}>{this.state.allDetails.projectName}</div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span> { "Project Type" }</span>
                                <div className={classes.fieldsdata}>{this.state.allDetails.projecType}</div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{ "Registration Number" }</span>
                                <div className={classes.fieldsdata}>{this.state.allDetails.registrationNumber}</div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{ "Seller ID" }</span>
                                <div className={classes.fieldsdata}>{this.state.allDetails.sellerId}</div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span> { "Eco System" }</span>
                                <div className={classes.fieldsdata}>{this.state.allDetails.ecosystem}</div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{ "Estimated Emission" }</span>
                                <div className={classes.fieldsdata}>{this.state.allDetails.estimatedEmission}</div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span> { "ghg" }</span>
                                <div className={classes.fieldsdata}>{this.state.allDetails.ghg}</div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{ "Onboarding Status" }</span>
                                <div className={classes.fieldsdata}>{this.state.allDetails.onboardingStatus}</div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{ "Project Biography" }</span>
                                <div className={classes.fieldsdata}>{this.state.allDetails.projectBiography}</div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span> { "Title Of Methodology" }</span>
                                <div className={classes.fieldsdata}>{this.state.allDetails.titleOfMethodology}</div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span> { "Project Standard" }</span>
                                <div className={classes.fieldsdata}>{this.state.allDetails.projectStandard}</div>
                                </div>
                            </Grid>
                        </Grid>
                        <br /><br />

                        <MUIDataTable 
                            title={"Credit Sale Detail"} 
                            data={this.state.creditSaleDetail} 
                            columns={saleDetailsColumns} 
                            options={options}
                        />
                        <br /><br />

                        <MUIDataTable 
                            title={"Impact Dimension"} 
                            data={this.state.impactDimension} 
                            columns={impactDimensionColumns} 
                            options={options}
                        />
                        <br /><br />

                        <MUIDataTable 
                            title={"Legal Documents"} 
                            data={this.state.legalDocuments} 
                            columns={legalDocumentsColumns} 
                            options={options}
                        />
                        <br /><br />

                        <MUIDataTable 
                            title={"Media Files"} 
                            data={this.state.mediaFiles} 
                            columns={mediaFilesColumns} 
                            options={options}
                        />
                        <br /><br />

                        <MUIDataTable 
                            title={"Project Location"} 
                            data={this.state.projectLocation} 
                            columns={projectLocationColumns} 
                            options={options}
                        />
                        <br /><br />

                        <MUIDataTable 
                            title={"Sustainable Development Goal"} 
                            data={this.state.sustainableDevelopmentGoal} 
                            columns={sustainableDevelopmentGoalColumns} 
                            options={options}
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default withStyles(styles)(ViewProjectDetails);