export const BUYER = {
    data: {
        allSellers: [],
        sellerTokenRes:"",
        addSellerResponseMsg:'',
        sellerAddStatus:"",
        fetchTokenStatus:"",

    },
    editData: {
        editDataId: '',
        editDataFirstname:"",
        editDataLastname:"",
        editDataUserId:"",
        editDataEmail:"",
    },
    forms: {
        buyerForm: {
            fields: {
                SellerUsernameCreate: {
                    label: "User Name",
                    name: "SellerUsernameCreate",
                    placeholder: "Add Username",
                    value: ""
                },
                SellerEmailCreate: {
                    label: "Email",
                    name: "SellerEmailCreate",
                    placeholder: "Add Email",
                    value: ""
                },
                SellerPasswordCreate: {
                    label: "Password",
                    name: "SellerPasswordCreate",
                    placeholder: "Add Password",
                    value: ""
                },
                SellerCompanyCreate: {
                    label: "Company",
                    name: "SellerCompanyCreate",
                    placeholder: "Add Company",
                    value: ""
                },
                SellerCountryCreate: {
                    label: "Country",
                    name: "SellerCountryCreate",
                    placeholder: "Add Country",
                    value: ""
                },
                SellerCityCreate: {
                    label: "City",
                    name: "SellerCityCreate",
                    placeholder: "Add City",
                    value: ""
                },
                SellerAddress1Create: {
                    label: "Address Line 1",
                    name: "SellerAddress1Create",
                    placeholder: "Address Line 1",
                    value: ""
                },
                SellerAddress2Create: {
                    label: "Address Line 2",
                    name: "SellerAddress2Create",
                    placeholder: "Address Line 2",
                    value: ""
                },
                SellerPhoneCreate: {
                    label: "Phone Number",
                    name: "SellerPhoneCreate",
                    placeholder: "Phone Number",
                    value: ""
                },
                SellerPinCodeCreate: {
                    label: "Pin Code",
                    name: "SellerPinCodeCreate",
                    placeholder: "PinCode",
                    value: ""
                },
                SellerVatNoCreate: {
                    label: "Vat No",
                    name: "SellerVatNoCreate",
                    placeholder: "Vat No",
                    value: ""
                },
            }
        },

        buyerFormEdit: {
            fields: {
                SellerUsernameCreate: {
                    label: "User Name",
                    name: "SellerUsernameCreate",
                    placeholder: "Add Username",
                    value: ""
                },
                SellerEmailCreate: {
                    label: "Email",
                    name: "SellerEmailCreate",
                    placeholder: "Add Email",
                    value: ""
                },
                SellerPasswordCreate: {
                    label: "Password",
                    name: "SellerPasswordCreate",
                    placeholder: "Add Password",
                    value: ""
                },
                SellerCompanyCreate: {
                    label: "Company",
                    name: "SellerCompanyCreate",
                    placeholder: "Add Company",
                    value: ""
                },
                SellerCountryCreate: {
                    label: "Country",
                    name: "SellerCountryCreate",
                    placeholder: "Add Country",
                    value: ""
                },
                SellerCityCreate: {
                    label: "City",
                    name: "SellerCityCreate",
                    placeholder: "Add City",
                    value: ""
                },
                SellerAddress1Create: {
                    label: "Address Line 1",
                    name: "SellerAddress1Create",
                    placeholder: "Address Line 1",
                    value: ""
                },
                SellerAddress2Create: {
                    label: "Address Line 2",
                    name: "SellerAddress2Create",
                    placeholder: "Address Line 2",
                    value: ""
                },
                SellerPhoneCreate: {
                    label: "Phone Number",
                    name: "SellerPhoneCreate",
                    placeholder: "Phone Number",
                    value: ""
                },
                SellerPinCodeCreate: {
                    label: "Pin Code",
                    name: "SellerPinCodeCreate",
                    placeholder: "PinCode",
                    value: ""
                },
                SellerVatNoCreate: {
                    label: "Vat No",
                    name: "SellerVatNoCreate",
                    placeholder: "Vat No",
                    value: ""
                },
            }
        },
    },
    UISettings: {

    }
}
export default BUYER;
