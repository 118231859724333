import BG from "../../../../../resources/images/landingbg.svg";

export const styles = (theme) => ({
  mainrapper: {
    background: `url(${BG})`,
    backgroundSize: "cover",
    padding: "32px 13px",
    minHeight: "calc(100vh - 115px)",
    width: "100%",
    "& *": {
      margin: 0,
    },
    "& .submitbtn": {
      height: 42
    }
  },
  detailHeader: {
    display: "flex",
    flexDirection: "column",
    padding: "22px 0px",
    "& h1": {
      marginBottom: 13,
    },
    "& h3": {
      marginBottom: 7,
    },
  },
  specs: {
    borderTop: "1px solid #d8d8d8",
    padding: "25px 10px",
    "& .title": {
      width: "fit-content",
      display: "inline-block",
      background: "#1b1b1d",
      color: "white",
      padding: 10,
      borderRadius: 32,
      marginBottom: 20,
    },
    "& .specs-details": {
      display: "flex",
      "& .specs": {
        display: "flex",
        flexDirection: "column",
        "& .specs-list-item": {
          padding: "16px 0px",
          borderTop: "1px solid #d6d7db",
          maxWidth: 304,
        },
      },
      "& .biography": {
        paddingLeft: 110,
      },
    },
  },
  documents: {
    "& .title": {
      width: "fit-content",
      display: "inline-block",
      background: "#1b1b1d",
      color: "white",
      padding: 10,
      borderRadius: 32,
      marginBottom: 20,
    },
    "& .documentList": {
      display: "flex",
      justifyContent: "space-between",
    },
    borderTop: "1px solid #d8d8d8",
    padding: "25px 10px",
  },
});
