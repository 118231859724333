import React, { Component } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import {
  Button,
  Grid,
  Popover,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import AlertDialog from "../../../../common/ReduxBased/confirmationDialog/container";
import EditProject from "../EditProject/container";
import Alert from "@material-ui/lab/Alert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorMessage from "../../common/ErrorMessage/component";
import ls from "local-storage";
import ReactSelectValidator from "../../../../common/ReduxBased/CustomReactSelectValidator/ReactSelectValidator";
import instance, { BASE_URL_BUYER_SELLER } from "../../../../../api/config";
import { handleLoadingSpinnerClose } from "../../../../common/ReduxBased/LoadingSpinner/actions";
import ViewClosedBiddingByProjectID from "../ViewClosedBiddingByProjectID/container";
toast.configure();

class ViewClosedBidding extends Component {
  state = {
    show: false,
    changeProjectStatus: false,
    showDelete: false,
    currentId: "",
    anchorEl: null,
    resendebayUsername: "",
    resendebayEmail: "",
    selectedTableRows: [],
    viewAllowed: false,
  };

  showAlertDialog = (value, id) => {
    this.setState({
      show: value,
      currentId: id,
      showEdit: value,
    });
  };

  handleClick = (event, dataValue, rowData) => {
    this.setState({
      anchorEl: event.currentTarget,
      currentId: dataValue,
      editData: rowData,
      resendebayUsername: rowData[3],
      resendebayEmail: rowData[4],
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  showAlertDialogDelete = (value, id) => {
    this.setState({
      showDelete: value,
      currentId: id,
    });
  };

  deleteProfileHandler = () => {
    this.setState({
      showDelete: false,
    });
    this.props.deleteSellerHandler(this.state.currentId);
  };

  editHandler = (show, id, editData) => {
    this.props.editSellerChangeHandler(id, editData);
    this.setState({
      showEdit: show,
    });
  };

  showEditSellerDialog = (value) => {
    this.setState({
      showEdit: value,
    });
  };

  resendInvitationHandler = (username, email) => {
    this.props.resendInvitation(username, email);
  };

  componentDidMount = () => {
    this.props.checkIfUrlIsAllowed();
    this.props.fetchDataIfNeeded();
  };

  selectedIds = [];
  customToolbarSelect = (selectedRows, displayData) => {
    let ids = [];
    selectedRows.data.map((value, index) => {
      let selectedValue = displayData.filter(
        (_, dataIndex) => dataIndex === value.dataIndex
      );
      ids.push(selectedValue[0].data[0]);
    });
    this.selectedIds = ids;
    console.log(this.selectedIds);
  };

  options = {
    filterType: "checkbox",
    customToolbarSelect: this.customToolbarSelect,
  };

  redirectToBidding = (value, currentId, data) => {
    ls.set("viewClosedBidByID", currentId);
    window.location.replace(`/user/project/closed-bidding/index/` + currentId);
  };

  showEditProjectAttributeDialog = (value) => {
    this.setState({
      viewAllowed: value,
    });
  };

  render() {
    const {
      classes,
      allSellers,
      sellerAddStatus,
      addSellerResponseMsg,
      isAllowed,
    } = this.props;
    const { currentId, editData, resendebayUsername, resendebayEmail } =
      this.state;
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;
    // const columns = ["Project Id","Buyer Id","Amount of Pot","Bid Price Per Pot","Created By", "Created Date"];
    const columns = [
      "Id",
      "Project Name",
      "Bidding Start Date",
      "Bidding End Date",
      "Status",
      {
        name: "Action",
        label: "Action",
        options: {
          customBodyRender: (props, data) => {
            let rowDataValue = data.rowData[0];
            let rowData = data.rowData;
            return (
              <div>
                <Button
                  aria-describedby={id}
                  variant="contained"
                  color="primary"
                  onClick={(e) => this.handleClick(e, rowDataValue, rowData)}
                >
                  Actions
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={this.state.anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div className={classes.actions}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        this.redirectToBidding(true, currentId, editData)
                      }
                    >
                      View
                    </Button>
                  </div>
                </Popover>
              </div>
            );
          },
        },
      },
    ];

    return (
      <>
        {
          <div className={classes.mainrapper}>
            <div className={classes.fieldswrapper}>
              <p>View all closed bidding</p>
              <MUIDataTable
                title={"All Closed Biddings"}
                data={allSellers}
                columns={columns}
                options={this.options}
              />
            </div>
          </div>
        }

        {this.state.viewAllowed && (
          <AlertDialog
            maxWidth={"lg"}
            open={this.state.viewAllowed}
            showTitle={true}
            title={"Closed BID BY ProjectID"}
            message={
              <ViewClosedBiddingByProjectID
                currentId={currentId}
                // projectTypeId={this.props.match.params.projectTypeId}
              />
            }
            hideApplyButton={true}
            cancelButtonText={"Close"}
            cancelAlertDialog={() => this.showEditProjectAttributeDialog(false)}
            closeAlertDialog={() => this.showEditProjectAttributeDialog(false)}
            applyAlertDialog={() => this.showEditProjectAttributeDialog(false)}
          />
        )}
      </>
    );
  }
}
export default withStyles(styles)(ViewClosedBidding);
