import { connect } from "react-redux";
import EditProject from "./component";
import {
    sellerEditTextFieldChangeHandler,
    selectFieldChangeHandler,
    selectFieldChangeHandlerEdit
} from "../actions";
import ls from 'local-storage';
import instance, {BASE_URL_BUYER_SELLER,BASE_URL} from "../../../../../api/config";
import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../common/ReduxBased/LoadingSpinner/actions";

const mapStateToProps = state => ({
    editFields: state.SELLER.editData,
    sellerFields: state.PROJECT.forms.projectFormEdit.fields
})


const mapDispatchToProps = (dispatch, props) => ({
    textFieldChangeHandler: (name, e) => {
        if (typeof e.value !== "string") {
            e.value = "";
        }
        dispatch(sellerEditTextFieldChangeHandler(name, e.value));
    },

    selectFieldChangeHandlerEdit: (name, e) => {
        dispatch(selectFieldChangeHandlerEdit(name, e));
    },

    submitProjectEdit1: (ProjectNameCreate,ProjectProjectDescriptionCreate,ProjectPublishedStatusCreate,
                         ProjectSellerIdCreate,ProjectStatusCreate,ProjectAmountOfCarbonCreate,
                         ProjectApprovalStatusCreate,ProjectAreaInSquareFeetCreate,ProjectBookBuildingStatusCreate,ProjectCancelledBySellerCreate,
                         ProjectCutGeneratedAsPredictedCreate, ProjectGoogleMapCoordinatesCreate, ProjectLocationCreate, ProjectImgUrlCreate, ProjectPutSoldStatusCreate ) => {
        let EditsellerId= ls.get('EditSellerID');
        console.log(ProjectCancelledBySellerCreate.value)
        let data = {
            id: EditsellerId,
            amountOfCarbon: ProjectAmountOfCarbonCreate ? ProjectAmountOfCarbonCreate.value : "",
            approvalStatus: ProjectApprovalStatusCreate ? ProjectApprovalStatusCreate.value : "",
            areaInSquareFeet: ProjectAreaInSquareFeetCreate ? ProjectAreaInSquareFeetCreate.value : "",
            bookBuildingStatus: ProjectBookBuildingStatusCreate ? ProjectBookBuildingStatusCreate.value : "",
            cancelledBySeller: ProjectCancelledBySellerCreate ? Boolean(ProjectCancelledBySellerCreate.value) : "",
            cutGeneratedAsPredicted: ProjectCutGeneratedAsPredictedCreate ? Boolean(ProjectCutGeneratedAsPredictedCreate.value) : "",
            googleMapCoordinates: ProjectGoogleMapCoordinatesCreate ? ProjectGoogleMapCoordinatesCreate.value : "",
            imageUrl: ProjectImgUrlCreate ? ProjectImgUrlCreate.value : "",
            location: ProjectLocationCreate ? ProjectLocationCreate.value: "",
            putSoldStatus: ProjectPutSoldStatusCreate ? Boolean(ProjectPutSoldStatusCreate.value) : "",
            projectDescription: ProjectProjectDescriptionCreate ? ProjectProjectDescriptionCreate.value : "",
            projectName: ProjectNameCreate ? ProjectNameCreate.value : "",
            publishedStatus: ProjectPublishedStatusCreate ? ProjectPublishedStatusCreate.value : "",
            sellerId: ProjectSellerIdCreate ? Number(ProjectSellerIdCreate.value) : null,
            status: ProjectStatusCreate ? ProjectStatusCreate.value : ""
        }
        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Update Seller", "small"));

        console.log(data)

        instance.post(BASE_URL+"/project/update", data)
        .then(res => {

            dispatch(handleLoadingSpinnerClose());
        })
        .catch(error => {
            dispatch(handleLoadingSpinnerClose());
        })
    },

})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    submitProjectEdit1: () => {
        dispatchProps.submitProjectEdit1(
            stateProps.sellerFields.ProjectNameCreate,
            stateProps.sellerFields.ProjectProjectDescriptionCreate,
            stateProps.sellerFields.ProjectPublishedStatusCreate,
            stateProps.sellerFields.ProjectSellerIdCreate,
            stateProps.sellerFields.ProjectStatusCreate,
            stateProps.sellerFields.ProjectAmountOfCarbonCreate,
            stateProps.sellerFields.ProjectApprovalStatusCreate,
            stateProps.sellerFields.ProjectAreaInSquareFeetCreate,
            stateProps.sellerFields.ProjectBookBuildingStatusCreate,
            stateProps.sellerFields.ProjectCancelledBySellerCreate,
            stateProps.sellerFields.ProjectCutGeneratedAsPredictedCreate,
            stateProps.sellerFields.ProjectGoogleMapCoordinatesCreate,
            stateProps.sellerFields.ProjectLocationCreate,
            stateProps.sellerFields.ProjectImgUrlCreate,
            stateProps.sellerFields.ProjectPutSoldStatusCreate,
            )
    },
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(EditProject);
