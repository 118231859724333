import { connect } from "react-redux";
import VintageDetails from "./component";

import {
  getCarbonAddress,
  getWhiteListAddress,
} from "../../../BlockChainManagement/ViewAllContracts/container";

const mapStateToProps = (state) => ({
  whiteListContractAddress: getWhiteListAddress(
    state.CONTRACTS.data?.list ?? []
  ),
  carbonContractAddress: getCarbonAddress(state.CONTRACTS.data?.list ?? []),
});

const mapDispatchToProps = (dispatch, props) => ({
  editViewBiddingChangeHandler: (value, current_id, name) => {
    // dispatch(editViewBiddingChangeHandler(value,current_id,name));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(VintageDetails);
