import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";
import { ValidatorForm } from "react-material-ui-form-validator";
import ErrorMessage from "../../common/ErrorMessage/component";

class CreateLanguage extends Component {

    componentDidMount = () => {
        this.props.checkIfUrlIsAllowed();
    }

    SubmitHandler = () => {
        this.props.submitLanguageProfile();
    }
    render() {

        const {
            classes,
            textFieldChangeHandler,
            fields,
            isAllowed
        } = this.props;

        return (
            isAllowed === 'allowed' ? <>
                <div className={classes.mainrapper}>
                <div className={classes.fieldswrapper}>
                    <p>Create a New Language</p>

                    <ValidatorForm
                        onSubmit={this.SubmitHandler}
                        autoComplete="off"
                    >
                       <Grid container>
                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.Language.label}</span>
                                <CustomTextValidator
                                    id={fields.Language.name}
                                    fullWidth={true}
                                    validations={true}
                                    type="text"
                                    placeholder={fields.Language.placeholder}
                                    name={fields.Language.name}
                                    value={fields.Language.value}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            fields.Language.name,
                                            value
                                        );
                                    }}
                                    validators={["required"]}
                                    errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.LanguageCode.label}</span>
                                <CustomTextValidator
                                    id={fields.LanguageCode.name}
                                    fullWidth={true}
                                    validations={true}
                                    type="text"
                                    placeholder={fields.LanguageCode.placeholder}
                                    name={fields.LanguageCode.name}
                                    value={fields.LanguageCode.value}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            fields.LanguageCode.name,
                                            value
                                        );
                                    }}
                                    validators={["required"]}
                                    errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" className="submitbtn" type="submit">Add</Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </div>
            </div>
            </> : <> {
                isAllowed === 'loading' ? <ErrorMessage message='loading' /> : <ErrorMessage message='You are not allowed to see this page' />
            }</>
        )
    }
}

export default withStyles(styles)(CreateLanguage);
