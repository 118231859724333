import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../common/ReduxBased/LoadingSpinner/actions";
import ls from "local-storage";

import instance, { BASE_URL_USER, BASE_LOGIN_URL } from "../../api/config";
import { reverseString } from "./../common/Helpers";
export const LOGIN_PAGE_TEXT_CHANGE_HANDLER = "LOGIN_PAGE_TEXT_CHANGE_HANDLER";
export const LOGIN_SUBMIT_FORM_HANDLER = "LOGIN_SUBMIT_FORM_HANDLER";
export const LOGIN_PAGE_ERROR_HANDLER = "LOGIN_PAGE_ERROR_HANDLER";

export const textFieldChangeHandler = (name, value) => (dispatch) => {
  dispatch({
    type: LOGIN_PAGE_TEXT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const submitFormHandler = (value) => (dispatch) => {
  let resObj = {};
  let keys_needed = ["sessionToken", "firstName", "user_id"];

  dispatch(handleLoadingSpinnerOpenWithOptions(true, "Loading...", "large"));
  instance
    .post(BASE_LOGIN_URL + "login-dashboard", value)
    .then((res) => {
      if (!res.data.data) {
        alert(res.data.description);
        dispatch(handleLoadingSpinnerClose());
        return false;
      }
      dispatch(handleLoadingSpinnerClose());
      keys_needed.map((item) => {
        resObj[reverseString(item)] = res.data.data[item];
        return item;
      });
      resObj[reverseString("user_id")] = res.data.data.id;
      resObj[reverseString("sessionToken")] = res.data.data.userToken
        .sessionToken
        ? res.data.data.userToken.sessionToken
        : "";
      ls.set("user_id", res.data.data.id);
      ls.set("LoginStatus", res.status);
      ls.set("SessionTokenCreatedTime", res.data.data.userToken.createdDate);
      localStorage.setItem(
        "sessionToken",
        res.data.data.userToken.sessionToken
      );
      ls.set("SessionTokenExpiry", res.data.data.userToken.sessionTokenExpiry);
      window.location.href = "/user/dashboard";
      ls.set("AfterLoginAccountTypeid", res.data.data.userAccountTypeId);

      //getting urls

      // let perData = {
      //     params: {
      //     accountTypeId: res.data.data ? res.data.data.userAccountTypeId : "",
      //     }
      //   }
      // instance.post(BASE_URL_USER+'apps/getAppActions', {}, perData)
      // .then(res => {
      //     ls.set('sidebarAllowedURLs', res.data.data ? res.data.data : [])
      //     window.location.replace("/user");
      // })
      // .catch(error => {
      //     dispatch(handleLoadingSpinnerClose());
      // })
    })
    .catch((error) => {
      dispatch(handleLoadingSpinnerClose());
    });
};
