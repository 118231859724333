import React, {Component} from 'react';
import {styles} from "./styles";
import {withStyles} from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorMessage from "../../common/ErrorMessage/component";
import {Button, Popover} from '@material-ui/core';
import AlertDialog from '../../../../common/ReduxBased/confirmationDialog/container';
import EditProjectType from '../EditProjectType/container';

toast.configure();

class IndexProjectType extends Component {
    state = {
        showEdit: false,
        show: false,
        showDelete: false,
        currentId: "",
        anchorEl: null,
        selectedTableRows: []
    };

    showAlertDialog = (value, id) => {
        this.setState({
            show: value,
            currentId: id,
            showEdit: value
        });
    };

    handleClick = (event, dataValue, rowData) => {
        this.setState({
            anchorEl: event.currentTarget,
            currentId: dataValue,
            editData: rowData
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null
        });
    };

    showAlertDialogDelete = (value, id) => {
        this.setState({
            showDelete: value,
            currentId: id
        });
    };

    deleteProjectTypeHandler = () => {
        this.setState({
            showDelete: false
        });
        this.props.deleteProjectTypeHandler(this.state.currentId);
    };

    editProjectType = (show, id, editData) => {
        this.props.editProjectTypeChangeHandler(id, editData);
        this.setState({
            showEdit: show
        });
    };

    showEditProjectTypeDialog = (value) => {
        this.setState({
            showEdit: value
        });
    };

    componentDidMount = () => {
        this.props.checkIfUrlIsAllowed();
        this.props.fetchDataIfNeeded();
    };


    selectedIds = [];
    customToolbarSelect = (selectedRows, displayData) => {
        let ids = [];
        selectedRows.data.map((value, index) => {
            let selectedValue = displayData.filter((_, dataIndex) => dataIndex === value.dataIndex);
            ids.push(selectedValue[0].data[0]);
        });
        this.selectedIds = ids;
        console.log(this.selectedIds);
    };

    options = {
        filterType: 'checkbox',
        customToolbarSelect: this.customToolbarSelect
    };

    render() {
        const {
            classes,
            projectTypes,
            isAllowed
        } = this.props;
        const {currentId, editData} = this.state;
        const open = Boolean(this.state.anchorEl);

        const id = open ? 'simple-popover' : undefined;
        const columns = [
            "Id",
            "Name",
            "Code",
            {
                name: "Action",
                label: "Action",
                options: {
                    customBodyRender: (props, data) => {
                        let rowDataValue = data.rowData[0];
                        let rowData = data.rowData;

                        return (
                            <div>
                                <Button aria-describedby={id} variant="contained" color="primary"
                                        onClick={(e) => this.handleClick(e, rowDataValue, rowData)}>
                                    Actions
                                </Button>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={this.state.anchorEl}
                                    onClose={this.handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center'
                                    }}
                                >
                                    <div className={classes.actions}>
                                        <Button color="primary" variant="outlined"
                                                onClick={() => this.editProjectType(true, currentId, editData)}>Edit</Button>
                                        <Button color="primary" variant="outlined"
                                                onClick={() => this.showAlertDialogDelete(true, currentId)}>Delete</Button>
                                        <Button color="primary" variant="outlined"
                                                onClick={() => window.location.replace('/user/project/project-attribute/create/' + currentId)}>Create Attribute</Button>
                                        <Button color="primary" variant="outlined"
                                                onClick={() => window.location.replace('/user/project/project-attribute/index/' + currentId)}>Manage Attributes</Button>
                                    </div>
                                </Popover>
                            </div>
                        );
                    }
                }
            }
        ];
        const preparedProjectTypes = projectTypes.map((projectType) => ([projectType.id, projectType.name, projectType.code]));
        return (
            isAllowed === 'allowed' ? <>
                {
                    this.state.showEdit &&
                    <AlertDialog
                        maxWidth={"sm"}
                        open={this.state.showEdit}
                        showTitle={true}
                        title={"Edit Buyer"}
                        message={<EditProjectType currentId={currentId}/>}
                        hideApplyButton={true}
                        cancelButtonText={"Close"}
                        cancelAlertDialog={() => this.showEditProjectTypeDialog(false)}
                        closeAlertDialog={() => this.showEditProjectTypeDialog(false)}
                        applyAlertDialog={() => this.showEditProjectTypeDialog(false)}

                    />
                }
                {
                    this.state.showDelete &&
                    <AlertDialog
                        maxWidth={"sm"}
                        open={this.state.showDelete}
                        showTitle={true}
                        title={"Delete Project Type"}
                        message={"Are you sure want to delete this project type?"}
                        cancelButtonText={"Close"}
                        cancelAlertDialog={() => this.showAlertDialogDelete(false)}
                        closeAlertDialog={() => this.showAlertDialogDelete(false)}
                        applyAlertDialog={() => this.deleteProjectTypeHandler()}
                    />
                }
                {
                    <div className={classes.mainrapper}>
                        <div className={classes.fieldswrapper}>
                            <p>View all Project Types</p>
                            <MUIDataTable
                                title={"Project types"}
                                data={preparedProjectTypes}
                                columns={columns}
                                options={this.options}
                            />
                        </div>
                    </div>
                }
            </> : <> {
                isAllowed === 'loading' ? <ErrorMessage message="loading"/> :
                    <ErrorMessage message="You are not allowed to see this page"/>
            }</>
        );
    }
}

export default withStyles(styles)(IndexProjectType);
