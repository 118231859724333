import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { Button, Popover } from "@material-ui/core";
import ViewAllIncrements from "./AllIncrements/container";
import AlertDialog from "../../../../common/ReduxBased/confirmationDialog/container";
import { NavLink } from "react-router-dom";
import EditAccountUser from "../EditUserAccount/container"
import ErrorMessage from "../../common/ErrorMessage/component";
import ls from 'local-storage';

class ViewAllUserAccount extends Component {

    state = {
        show: false,
        showDelete: false,
        currentId: "",
        anchorEl: null,
        editData:'',
        secretUrl: '/account-management/getAllAccounts'
    }

    showAlertDialog = (value, id) => {
        this.setState({
            show: value,
            currentId: id
        })
    }

    handleClick = (event,dataValue,rowData) => {
        this.setState({
            anchorEl: event.currentTarget,
            currentId:dataValue,
            editData:rowData
        })
    };

    handleClose = () => {
        this.setState({
            anchorEl: null
        })
    }

    showAlertDialogDelete = (value, id) => {
        this.setState({
            showDelete: value,
            currentId: id
        })
    }

    editHandler = (show,id,editData) => {
        this.props.editAccountUserChangeHandler(id,editData)
        this.setState({
            showEdit:show
        })
    }

    deleteProfileHandler = () => {
        this.setState({
            showDelete: false
        })
        this.props.deleteProfileHandler(this.state.currentId)
    }

    componentDidMount = async () => {
        this.props.checkIfUrlIsAllowed();
        this.props.fetchDataIfNeeded();
    }

    showEditAccountUserDialog = (value) => {
        this.setState({
            showEdit: value
        })
    }

    render() {

        const {
            classes,
            allProfiles,
            isAllowed
        } = this.props;

        const {currentId,editData}= this.state;

        const open = Boolean(this.state.anchorEl);
        const id = open ? 'simple-popover' : undefined;
        
        const columns = ["ID", "First Name", "LastName", "Email", "Created Date", "Status(Active)",
        {
            name: "Action",
            label: "Action",
            options: {
                customBodyRender: (props, data) => {
                    let rowDataValue = data.rowData[0];
                    let rowData = data.rowData
                    return (
                        <div>
                            
                            <Button aria-describedby={id} variant="contained" color="primary" onClick={(e)=>this.handleClick(e,rowDataValue,rowData)}>
                                Actions
                            </Button>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={this.state.anchorEl}
                                onClose={this.handleClose}
                                anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                                }}
                                transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                                }}
                            >
                                <div className={classes.actions}>
                                    {/* <Button color="primary" variant="outlined" onClick={() => this.showAlertDialog(true, data.rowData[0])}>View Increment</Button> */}
                                    <Button color="primary" variant="outlined" onClick={()=>this.editHandler(true, currentId, editData)}>Edit</Button>
                                    <Button color="primary" variant="outlined" onClick={() => this.showAlertDialogDelete(true, currentId)}>Delete</Button>
                                </div>
                            </Popover>
                        </div>
                    )
                },
            }
        }
        ];

        const options = {
        // filterType: 'checkbox',
        };

        return (
            ls.get('AclGetUserAlloweddata') === true &&
            isAllowed === 'allowed' ? <>
            
            {
                this.state.show &&
                <AlertDialog
                    maxWidth={"lg"}
                    open={this.state.show}
                    showTitle={false}
                    hideApplyButton={true}
                    message={<ViewAllIncrements currentId={this.state.currentId} showAlertDialog={this.showAlertDialog} />}
                    cancelButtonText={"Close"}
                    cancelAlertDialog={() => this.showAlertDialog(false)}
                    closeAlertDialog={() => this.showAlertDialog(false)}
                    applyAlertDialog={() => this.showAlertDialog(false)}
                />
            }

            {
                this.state.showDelete &&
                <AlertDialog
                    maxWidth={"sm"}
                    open={this.state.showDelete}
                    showTitle={true}
                    title={"Delete Account"}
                    message={"Are you sure want to delete this account?"}
                    cancelButtonText={"Close"}
                    cancelAlertDialog={() => this.showAlertDialogDelete(false)}
                    closeAlertDialog={() => this.showAlertDialogDelete(false)}
                    applyAlertDialog={() => this.deleteProfileHandler()}
                />
            }
            {
                this.state.showEdit &&
                <AlertDialog
                    maxWidth={"sm"}
                    open={this.state.showEdit}
                    showTitle={true}
                    title={"Edit User Account"}
                    message={<EditAccountUser />}
                    cancelButtonText={"Close"}
                    hideApplyButton={true}
                    cancelAlertDialog={() => this.showEditAccountUserDialog(false)}
                    closeAlertDialog={() => this.showEditAccountUserDialog(false)}
                    applyAlertDialog={() => this.showEditAccountUserDialog(false)}

                />
            }

                <div className={classes.mainrapper}>
                    <div className={classes.fieldswrapper}>

                        <NavLink
                            to={'/user/create-useraccount'}
                            className={classes.createlink}
                        >
                            Add New User
                        </NavLink>

                        <p>View all User Accounts</p>
                        <MUIDataTable 
                            title={"Users"} 
                            data={allProfiles} 
                            columns={columns} 
                            options={options}
                        />
                    </div>
                </div>
            </> : <> {
                isAllowed === 'loading' ? <ErrorMessage message='loading' /> : <ErrorMessage message='You are not allowed to see this page' />
            }</>
        )
    }
}
export default withStyles(styles)(ViewAllUserAccount);