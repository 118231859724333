import { connect } from "react-redux";
import AddNewRoles from "./component";
import instance, {BASE_URL} from "../../../../../../api/config";
import {
    textFieldChangeHandler_Roles,
} from "../../actions";

import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../../common/ReduxBased/LoadingSpinner/actions";

const mapStateToProps = state => ({
    fields: state.PERMISSIONS.forms.Roles.fields,
})

const mapDispatchToProps = (dispatch, props) => ({
    textFieldChangeHandler: (name, e) => {
        if (typeof e.value !== "string") {
            e.value = "";
        }
        dispatch(textFieldChangeHandler_Roles(name, e));
    },
    SubmitHandler: (AccountGroup, Name) => {

        let data = {
            accountGroup: AccountGroup.value,
            name: Name.value
          }
        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Adding Role...", "small"));

        instance.post(BASE_URL+'/user/core-account-types/addAccountType', data)
        .then(res => {
            window.location.replace('/user/all-permission')
            dispatch(handleLoadingSpinnerClose());
        })
        .catch(error => {
            dispatch(handleLoadingSpinnerClose());
        })
    }
})


const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...ownProps,
    ...dispatchProps,
    SubmitHandler: () => {
        dispatchProps.SubmitHandler(
            stateProps.fields.AccountGroup,
            stateProps.fields.Name,
            )
    },
    ...ownProps,
})
export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(AddNewRoles);
