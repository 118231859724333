import { connect } from "react-redux";
import ViewRequestLogs from "./component";
import instance, { BASE_URL_BUYER_SELLER } from "../../../../../api/config";
import {
    saveALLSellerProfiles,
    editSellerChangeHandler,
    saveAddSellerResponse
} from "../actions";
import ls from "local-storage"

import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import {
    checkIfUrlIsAllowed
} from "../../Permissions/actions";

const mapStateToProps = state => ({
    allSellers: state.SELLER.data.allSellers,
    sellerAddStatus:state.SELLER.data.sellerAddStatus,
    addSellerResponseMsg: state.SELLER.data.addSellerResponseMsg,
    isAllowed: state.PERMISSIONS.UISettings.isAllowed

})

const mapDispatchToProps = (dispatch, props) => ({
    fetchDataIfNeeded: () => {
        instance.get(BASE_URL_BUYER_SELLER+"/project/request-log")
        .then(res => {
            dispatch(saveALLSellerProfiles(res.data))
        })
        .catch(err => {

        })
    },
    deleteSellerHandler: (id) => {
        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Deleting Seller Profile", "small"));
        instance.delete(BASE_URL_BUYER_SELLER+"/seller/api/seller/"+id)
        .then(res => {
            dispatch(handleLoadingSpinnerClose());
            window.location.reload();
        })
        .catch(err => {
            dispatch(handleLoadingSpinnerClose());
        })
    },
    editSellerChangeHandler: (value,name) => {
        dispatch(editSellerChangeHandler(value,name));
    },


    resendInvitation: (username,email) => {

        let data = {
            eBayUserName: username ,
            email: email
          }

        dispatch(handleLoadingSpinnerOpenWithOptions(true, "Resend Invitation", "small"));
        //todo STAGING, DEV, PROTOTYPE, LIVE

        instance.post(BASE_URL_BUYER_SELLER+'auction-seller/addSeller?env=STAGING', data)
        .then(res => {
            ls.set('addsellerToken', res.data.data.sessionId)
            ls.set('addsellerEmail', res.data.data.userId)
            dispatch(saveAddSellerResponse(res.data,res.data.description))
            // window.location.replace('/user/view-seller')
            dispatch(handleLoadingSpinnerClose());
        })
        .catch(error => {
            dispatch(handleLoadingSpinnerClose());
        })
    },
    checkIfUrlIsAllowed: async () => {
        await dispatch(checkIfUrlIsAllowed('/auction-seller/auction-sellers'))
    }

})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ViewRequestLogs);
