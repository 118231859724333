export const PROJECT_MAIN_ATTRIBUTE = {
    data: {
        projectMainAttributes: [],
        dataTypes: []
    },
    forms: {
        projectMainAttributeForm: {
            fields: {
                dataType: {
                    label: "Data Type",
                    name: "dataType",
                    placeholder: "Data Type",
                    value: {}
                },
                value: {
                    label: "Value",
                    name: "value",
                    placeholder: "Value",
                    value: ""
                },
                isRequired: {
                    label: "Is Required",
                    name: "isRequired",
                    placeholder: "Is Required",
                    value: [{value: true, label: "true"}],
                    options: [
                        {value: true, label: "true"},
                        {value: false, label: "false"}
                    ]
                },
                sortingOrder: {
                    label: "Sorting Order",
                    name: "sortingOrder",
                    placeholder: "Sorting Order",
                    value: 0
                }
            }
        }
    },
    UISettings: {}
};
export default PROJECT_MAIN_ATTRIBUTE;
