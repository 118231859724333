import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";
import { ValidatorForm } from "react-material-ui-form-validator";

class EditProjectType extends Component {
    submitHandler = (id) => {
        this.props.submitProjectTypeEdit(id);
    }

    render() {

        const {
            classes,
            textFieldChangeHandler,
            projectTypeFields,
            currentId
        } = this.props;

        return (
            <>
                <div className={classes.mainrapper}>
                <div className={classes.fieldswrapper}>
                    <p>Edit Projecttype</p>

                    <ValidatorForm
                        onSubmit={() => this.submitHandler(currentId)}
                        autoComplete="off"
                    >
                       <Grid container>
                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{projectTypeFields.ProjectTypeName.label}</span>
                                   <CustomTextValidator
                                       id={projectTypeFields.ProjectTypeName.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={projectTypeFields.ProjectTypeName.placeholder}
                                       name={projectTypeFields.ProjectTypeName.name}
                                       value={projectTypeFields.ProjectTypeName.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               projectTypeFields.ProjectTypeName.name,
                                               value
                                           );
                                       }}
                                       validators={["required"]}
                                       errorMessages={["Required"]}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>
                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{projectTypeFields.ProjectTypeCode.label}</span>
                                   <CustomTextValidator
                                       id={projectTypeFields.ProjectTypeCode.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={projectTypeFields.ProjectTypeCode.placeholder}
                                       name={projectTypeFields.ProjectTypeCode.name}
                                       value={projectTypeFields.ProjectTypeCode.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               projectTypeFields.ProjectTypeCode.name,
                                               value
                                           );
                                       }}
                                       validators={["required"]}
                                       errorMessages={["Required"]}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" className="submitbtn" type="submit">Update</Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </div>
            </div>
            </>
        )
    }
}

export default withStyles(styles)(EditProjectType);
