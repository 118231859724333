import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";
import { ValidatorForm } from "react-material-ui-form-validator";
import ErrorMessage from "../../common/ErrorMessage/component";
import ReactSelectValidator from '../../../../common/ReduxBased/CustomReactSelectValidator/ReactSelectValidator';

class CreateBuyer extends Component {

    componentDidMount = () => {
        this.props.checkIfUrlIsAllowed();
        this.props.fetchDataIfNeeded(this.props.match.params.projectTypeId);
    }

    submitHandler = () => {
        this.props.submitProjectAttribute(this.props.match.params.projectTypeId);
    }
    render() {

        const {
            classes,
            textFieldChangeHandler,
            selectFieldChangeHandler,
            dataTypes,
            fields,
            addSellerResponseMsg,
            isAllowed
        } = this.props;
        return (
            isAllowed === 'allowed' ? <>
                <div className={classes.mainrapper}>
                <div className={classes.fieldswrapper}>
                    <p>Add a New Buyer</p>

                    <ValidatorForm
                        onSubmit={this.submitHandler}
                        autoComplete="off"
                    >
                       <Grid container>
                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{fields.dataType.label}</span>
                                   <ReactSelectValidator
                                       id={fields.dataType.name}
                                       name={fields.dataType.name}
                                       value={fields.dataType.value}
                                       placeholder={fields.dataType.placeholder}
                                       validations={true}
                                       fullWidth={true}
                                       onChange={value => {
                                           selectFieldChangeHandler(
                                               fields.dataType.name,
                                               value
                                           );
                                       }}
                                       options={dataTypes.map((dataType) => ({value: dataType, label: dataType}))}
                                       className="selectfield"
                                   />
                               </div>
                           </Grid>
                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{fields.name.label}</span>
                                   <CustomTextValidator
                                       id={fields.name.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={fields.name.placeholder}
                                       name={fields.name.name}
                                       value={fields.name.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               fields.name.name,
                                               value
                                           );
                                       }}
                                       validators={["required"]}
                                       errorMessages={["Required"]}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>
                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{fields.code.label}</span>
                                   <CustomTextValidator
                                       id={fields.code.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="text"
                                       placeholder={fields.code.placeholder}
                                       name={fields.code.name}
                                       value={fields.code.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               fields.code.name,
                                               value
                                           );
                                       }}
                                       validators={["required"]}
                                       errorMessages={["Required"]}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>
                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{fields.isRequired.label}</span>
                                   <ReactSelectValidator
                                       id={fields.isRequired.name}
                                       name={fields.isRequired.name}
                                       value={fields.isRequired.value}
                                       placeholder={fields.isRequired.placeholder}
                                       validations={true}
                                       fullWidth={true}
                                       onChange={value => {
                                           selectFieldChangeHandler(
                                               fields.isRequired.name,
                                               value
                                           );
                                       }}
                                       options={fields.isRequired.options}
                                       className="selectfield"
                                   />
                               </div>
                           </Grid>
                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                   <span>{fields.sortingOrder.label}</span>
                                   <CustomTextValidator
                                       id={fields.sortingOrder.name}
                                       fullWidth={true}
                                       validations={true}
                                       type="number"
                                       placeholder={fields.sortingOrder.placeholder}
                                       name={fields.sortingOrder.name}
                                       value={fields.sortingOrder.value}
                                       onChange={value => {
                                           textFieldChangeHandler(
                                               fields.sortingOrder.name,
                                               value
                                           );
                                       }}
                                       validators={["required"]}
                                       errorMessages={["Required"]}
                                       className="textfield"
                                   />
                               </div>
                           </Grid>

                            <Grid item xs={12}>
                                {addSellerResponseMsg?
                                <Button variant="contained" color="primary" className="submitbtn" type="submit"  disabled>Add</Button>
                                :
                                <Button variant="contained" color="primary" className="submitbtn" type="submit"  >Add</Button>
                                 }

                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </div>
            </div>
            </>: <> {
                isAllowed === 'loading' ? <ErrorMessage message='loading' /> : <ErrorMessage message='You are not allowed to see this page' />
            }</>
        )
    }
}

export default withStyles(styles)(CreateBuyer);
